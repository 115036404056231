import React from 'react';
import { useSelector } from 'react-redux';
import CompanyAnnouncement from '../../../containers/Home/Announcements/CompanyAnnouncement';
import CompanyReminders from '../../../containers/Home/Reminders/CompanyReminders';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import WelcomeCard from '../../../components/Cards/WelcomeCard';
import AdminDashboard from '../../../containers/Home/AdminDashboard';
import { AccountType } from '../../../helpers/enum/accounts';
import useWindowSize from '../../../hooks/useWindowSize';

function HomePage() {
  const currentUser = useSelector(state => state && state.auth.currentUser);
  const size = useWindowSize();
  return (
    <PageWrapperV2 tabTitle="Home">
      {currentUser.account_type_id == AccountType.ADMIN || currentUser.account_type_id == AccountType.STAFF ? (
        // Admin UI
        <div className="grid grid-cols-6 gap-x-6 gap-y-6">
          <div className="col-span-6 lg:col-span-4">
            <WelcomeCard user={currentUser} />
          </div>
          <div className=" col-span-6 lg:col-span-2">
            <AdminDashboard />
          </div>
          <div className=" col-span-6 lg:col-span-4 grow lg:-mt-20" style={{ minHeight: '450px' }}>
            <CompanyAnnouncement />
          </div>
          <div
            className=" col-span-6 lg:col-span-2 row-start-3 lg:row-start-auto "
            style={{ minHeight: size.width > size.tailwind.lg ? '450px' : '' }}
          >
            <CompanyReminders />
          </div>
        </div>
      ) : (
        // User UI
        <div className="grid grid-cols-6 gap-x-6 gap-y-6">
          <div className="col-span-6 lg:col-span-6">
            <WelcomeCard user={currentUser} />
          </div>
          <div className=" col-span-6 lg:col-span-4 " style={{ minHeight: '450px' }}>
            <CompanyAnnouncement />
          </div>
          <div
            className=" col-span-6 lg:col-span-2 row-start-2 lg:row-start-auto "
            style={{ minHeight: size.width > size.tailwind.lg ? '450px' : '' }}
          >
            <CompanyReminders />
          </div>
        </div>
      )}
    </PageWrapperV2>
  );
}

export default HomePage;
