import { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import * as countryID from '../../helpers/enum/CountryIDs';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';
import Loader from '../../components/Loading/Loader';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import formatCurrency from '../../utils/formatCurrency';
import allocationTypes from '../../helpers/enum/allocationTypes';
import roundNumber from '../../utils/roundNumber';

const InvoicePreviewAndTable = ({ timesheetId, optionalComments }) => {
  const [lineItems, setLineItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isHandover, setIsHandover] = useState(false);
  const [totals, setTotals] = useState({
    totalDue: '0',
    totalVat: 'n/a',
    totalDuePlusVat: '0',
  });
  const {
    accountsPayable: { getAccountsPayableByTimesheet },
    timesheets: { getTimesheetTotals },
  } = useApi();

  useEffect(() => {
    if (!timesheetId) return;
    getAccountsPayableByTimesheet(timesheetId).then(res => {
      let _lineItems = [];
      if (res?.lineItems?.length) {
        _lineItems = res.lineItems.map(li => li);
      }

      setTotals({
        totalDue: res?.cost,
        totalVat: res?.vat,
        totalDuePlusVat: res?.cost_and_vat,
      });
      getTimesheetTotals(timesheetId).then(res => {
        res.forEach(r => {
          let totalHandoverHours = 0;
          if (r?.entries?.length) {
            r?.entries.forEach(e => {
              if (e?.type === allocationTypes.offboarding) totalHandoverHours += e?.hours;
            });
          }
          if (totalHandoverHours > 0) {
            _lineItems.push({
              staffOrder: {
                order_ref: r.staffOrder.order_ref + '*',
              },
              units: (Number(totalHandoverHours) / 8).toFixed(2).replace(/[.,]00$|0$/, ''),
              uom: 'days',
              price: 0,
              total: 0,
              vat: 0,
              vat_rate: r.staffOrder.staffContract.country_id === countryID.belgium ? '21' : '0',
              total_and_vat: 0,
              type: lineItemTypesV2.accountsPayable.numbers.projectAllocations,
            });
            setIsHandover(true);
          }
        });
      });
      setIsLoading(false);
      setLineItems(_lineItems);
    });
  }, [timesheetId]);

  return (
    <Loader isLoading={isLoading} className="my-4">
      <div className="mt-8 mb-4 flex flex-col h-full">
        <div className="w-full flex justify-between">
          <span className="mb-4 py-2 text-lg font-medium leading-6 text-gray-900">Invoice lines</span>
        </div>
        <div className={`${isHandover ? '' : 'mb-4'} px-2 border border-gray-300 rounded-md`}>
          <table className="table-auto w-full h-max mt-2 px-4">
            <thead className="w-full">
              <tr className="text-md ">
                <th className="text-center">Purchase order</th>
                <th className="text-center">Type</th>
                <th className="text-center">Units</th>
                <th className="text-center">UoM</th>
                <th className="text-center">Price</th>
                <th className="text-center">VAT rate</th>
                <th className="text-center">Total Excl VAT</th>
                <th className="text-center">VAT</th>
                <th className="text-center">Total Incl VAT</th>
              </tr>
            </thead>
            <tbody className="p-2 h-max w-full text-sm">
              {lineItems?.length ? (
                lineItems.map((li, i) => {
                  return (
                    <tr className="border-dashed my-1 h-8" key={li?.id || li?.uuid}>
                      {li.ts_entry_adjustment ? (
                        <td className="text-center">
                          <div className="flex items-center justify-center">
                            {li.staffOrder.order_ref}
                            <InfoTooltip size="sm">{li.description}</InfoTooltip>
                          </div>
                        </td>
                      ) : (
                        <td className="text-center">{li?.staffOrder?.order_ref || ''}</td>
                      )}
                      <td className="text-center">{lineItemTypesV2.accountsPayable.strings[li.type]}</td>
                      <td className="text-center">{roundNumber(li.units, true, 6)}</td>
                      <td className="text-center">{li.uom}</td>
                      <td className="text-center">{formatCurrency(li.price)}</td>
                      <td className="text-center">
                        {Number(li.vat_rate)
                          .toFixed(2)
                          .replace(/[.,]00$|0$/, '') + '%'}
                      </td>
                      <td className="text-center">{formatCurrency(li.total)}</td>
                      <td className="text-center">{formatCurrency(li.vat)}</td>
                      <td className="text-center relative">{formatCurrency(li.total_and_vat)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>No allocations found</tr>
              )}
            </tbody>
            <tfoot className="border-t border-gray-300 pt-2 h-8 text-sm w-full">
              <tr>
                <td className="font-semibold text-center text-base">Grand total</td>
                <td className=""></td>
                <td className=""></td>
                <td className=""></td>
                <td className=""></td>
                <td className="font-bold text-thaleria-blue-500 text-center "></td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalDue)}</td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalVat)}</td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalDuePlusVat)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        {isHandover ? <span className="mx-3 text-sm mb-4">* Offboarding handover (not billable)</span> : ''}
        {optionalComments && (
          <div className="flex mb-4 flex-col">
            <span className="text-lg font-medium leading-6 text-gray-900">Comments from the user</span>
            <span className="w-full rounded-md px-2 py-2">{optionalComments}</span>
          </div>
        )}
      </div>
    </Loader>
  );
};

export default InvoicePreviewAndTable;
