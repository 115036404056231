import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputSimple from '../../../components/Inputs/InputSimple';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import RichTextEditor from '../../../components/Inputs/RichTextEditor/RichTextEditor';

const AddTitleBodyAnnouncement = props => {  
  return (
    <div className="space-y-4">
      <InputSimple
        placeholder="Enter announcement title"
        onChange={props.onTitleChange}
        value={props.title}
        errorMessage={props.inputErrorMessage}
      />

      <RichTextEditor editorState={props.body} setEditorState={props.onBodyChange} placeholder="Enter announcement text" />

      {props.textAreaErrorMessage && <span className="text-xs text-red-500 ml-4">{props.textAreaErrorMessage}</span>}

      <div className="mt-10 flex flex-row-reverse justify-start">
        <ButtonPrimary text="Next" onClick={props.onSuccessHandler} />
        <ButtonWhite text="Cancel" style="mr-2" onClick={props.onCancelHandler} />
      </div>
    </div>
  );
};

export default AddTitleBodyAnnouncement;

AddTitleBodyAnnouncement.propTypes = {
  onSuccessHandler: PropTypes.func,
  onCancelHandler: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  onTitleChange: PropTypes.func,
  onBodyChange: PropTypes.func,
  inputErrorMessage: PropTypes.string,
  textAreaErrorMessage: PropTypes.string,
};
