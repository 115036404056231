import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import InputRadio from '../../components/Inputs/InputRadio';
import TextArea from '../../components/Inputs/TextArea';
import Loader from '../../components/Loading/Loader';
import Modal from '../../components/Modals/Modal';
import countryID from '../../helpers/enum/CountryIDs';
import useApi from '../../hooks/useApi';
import formatCurrency from '../../utils/formatCurrency';
import allocationTypes from '../../helpers/enum/allocationTypes';
import InfoText from '../../components/Warnings/InfoText';
import roundNumber from '../../utils/roundNumber';

const CheckInvoiceModal = ({ show, hide, timesheetId, handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalsPerStaffOrder, setTotalsPerStaffOrder] = useState();
  const [totals, setTotals] = useState();
  const [message, setMessage] = useState('');
  const [isAMatch, setIsAMatch] = useState(undefined);
  const [isHandover, setIsHandover] = useState(false);
  const [isAdjusted, setIsAdjusted] = useState(false);
  const {
    timesheets: { getTimesheetTotals },
  } = useApi();

  useEffect(() => {
    if (!timesheetId) return;
    if (!show) {
      setMessage('');
      setIsAMatch(undefined);
      return;
    }
    let totalPerStaffOrder = [];
    let totalDue = 0;
    let totalVat = 0;
    let totalDuePlusVat = 0;
    let _isAdjusted = false;
    getTimesheetTotals(timesheetId).then(res => {
      res.forEach(r => {
        let totalPayableHours = 0;
        let totalHandoverHours = 0;
        let totalAdjustedHours = 0;
        if (r?.entries?.length) {
          r?.entries.forEach(e => {
            if (e?.adjusted_hours !== null) {
              _isAdjusted = true;
              totalAdjustedHours += e?.hours ? e?.adjusted_hours * 100 - e?.hours * 100 : e?.adjusted_hours * 100;
            }
            //Multiplying and dividing result by 100 because of Javascript bugs when adding 2 decimal numbers
            if (e?.type !== allocationTypes.offboarding) totalPayableHours += e?.hours * 100;
            if (e?.type === allocationTypes.offboarding) totalHandoverHours += e?.hours * 100;
          });
          totalPayableHours = totalPayableHours / 100;
          totalHandoverHours = totalHandoverHours / 100;
          totalAdjustedHours = totalAdjustedHours / 100;
        }
        const amountDue = roundNumber(Number(r.staffOrder.daily_rate) * (totalPayableHours / 8));
        const vat = r.staffOrder.staffContract.country_id === countryID.belgium ? amountDue * 0.21 : 0;
        const amountDuePlusVat = vat ? amountDue + vat : amountDue;
        totalPerStaffOrder.push({
          order_ref: r.staffOrder.order_ref,
          days_worked: totalPayableHours / 8,
          daily_rate: formatCurrency(r.staffOrder.daily_rate),
          total: formatCurrency(amountDue),
          vat: formatCurrency(vat),
          vatRate: r.staffOrder.staffContract.country_id === countryID.belgium ? '21%' : '0%',
          totalPlusVat: formatCurrency(amountDuePlusVat),
        });
        totalDue += amountDue;
        totalVat += vat ? vat : 0;
        totalDuePlusVat += amountDuePlusVat;
        if (totalHandoverHours > 0) {
          totalPerStaffOrder.push({
            order_ref: r.staffOrder.order_ref + '*',
            days_worked: totalHandoverHours / 8,
            daily_rate: formatCurrency(0),
            total: formatCurrency(0),
            vat: formatCurrency(0),
            vatRate: r.staffOrder.staffContract.country_id === countryID.belgium ? '21%' : '0%',
            totalPlusVat: formatCurrency(0),
          });
          setIsHandover(true);
        }
        if (totalAdjustedHours != 0) {
          const amountAdjusted = roundNumber(Number(r.staffOrder.daily_rate) * (totalAdjustedHours / 8));
          const vatAdjusted = r.staffOrder.staffContract.country_id === countryID.belgium ? amountAdjusted * 0.21 : 0;
          const amountAdjustedPlusVat = amountAdjusted + vatAdjusted;
          totalDue += amountAdjusted;
          totalVat += vatAdjusted;
          totalDuePlusVat += amountAdjustedPlusVat;
          totalPerStaffOrder.push({
            order_ref: r.staffOrder.order_ref + '**',
            days_worked: totalAdjustedHours / 8,
            daily_rate: formatCurrency(r.staffOrder.daily_rate),
            total: formatCurrency(amountAdjusted),
            vat: formatCurrency(vatAdjusted),
            vatRate: r.staffOrder.staffContract.country_id === countryID.belgium ? '21%' : '0%',
            totalPlusVat: formatCurrency(amountAdjustedPlusVat),
          });
        }
      });
      setIsAdjusted(_isAdjusted);
      setTotalsPerStaffOrder(totalPerStaffOrder);
      setTotals({
        totalDue: formatCurrency(totalDue),
        totalVat: formatCurrency(totalVat),
        totalDuePlusVat: formatCurrency(totalDuePlusVat),
      });
      setIsLoading(false);
    });
  }, [show]);

  const onUploadClick = () => {
    handleSubmit(message, isAMatch);
  };

  useEffect(() => {
    if (isAMatch) {
      setMessage('');
    }
  }, [isAMatch]);

  return (
    <Modal title="Upload invoice" show={show} hide={hide} size="lg">
      <Loader isLoading={isLoading}>
        <div className="px-2 text-gray-700">
          <InfoText
            text="Please make sure to aggregate all your invoicing items in a single document. If you have more than one
            mission on this timesheet, such as when changing from one PO to another, please add an invoicing line per mission on
            the same invoice, instead of creating separate invoices."
          />
          <p className="py-4  text-sm ">1. Please check that the information on the invoice matches your allocations.</p>
          <div className=" border border-gray-300 rounded-md overflow-x-auto ">
            <table className="table-auto w-full h-max mt-2">
              <thead className="w-full">
                <tr className="text-md ">
                  <th className="text-center">Project</th>
                  <th className="text-center">Days worked</th>
                  <th className="text-center">Daily rate</th>
                  <th className="text-center">Amount due</th>
                  <th className="text-center">VAT rate</th>
                  <th className="text-center">VAT</th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              <tbody className="p-6 h-max w-full text-sm">
                {totalsPerStaffOrder?.length ? (
                  totalsPerStaffOrder.map(t => {
                    return (
                      <tr className="border-dashed my-1 h-10">
                        <td className="text-center">{t.order_ref}</td>
                        <td className="text-center">{t.days_worked}</td>
                        <td className="text-center">{t.daily_rate}</td>
                        <td className="text-center">{t.total}</td>
                        <td className="text-center">{t.vatRate}</td>
                        <td className="text-center">{t.vat}</td>
                        <td className="text-center">{t.totalPlusVat}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>No allocations found</tr>
                )}
              </tbody>
              <tfoot className="border-t pt-2 h-8 text-sm w-full">
                <tr>
                  <td className="font-semibold text-center">Grand total</td>
                  <td className=""></td>
                  <td className=""></td>
                  <td className="font-bold text-thaleria-blue-500 text-center ">{totals?.totalDue}</td>
                  <td className="font-bold text-thaleria-blue-500 text-center "></td>
                  <td className="font-bold text-thaleria-blue-500 text-center ">{totals?.totalVat}</td>
                  <td className="font-bold text-thaleria-blue-500 text-center ">{totals?.totalDuePlusVat}</td>
                </tr>
              </tfoot>
            </table>
          </div>
          {isHandover ? <span className="mx-3 text-xs mb-4">* Offboarding handover (not billable)</span> : ''}
          {isAdjusted ? <span className="mx-3 text-xs mb-4">** Post-approval adjustments</span> : ''}

          <span className="py-4 flex justify-between items-center ">
            <InputRadio
              label="2. Does the amount due match the one in your invoice?"
              options={['Yes', 'No']}
              selectedValue={isAMatch === true ? 'Yes' : isAMatch === false ? 'No' : null}
              onChange={e => setIsAMatch(e.target.value === 'Yes' ? true : false)}
            />
          </span>
          <div className="space-y-4 ">
            {isAMatch === false && (
              <div className="my-4 space-y-2">
                <p className="text-sm">
                  3. Before uploading a file, please provide more information to help us review your invoice.
                </p>
                <TextArea value={message} onChange={e => setMessage(e.target.value)} />
              </div>
            )}
          </div>
          <div className="w-full flex justify-end mt-4">
            <ButtonPrimary
              text="Upload"
              onClick={() => onUploadClick()}
              disable={isAMatch == undefined || (!isAMatch && message == '')}
            />
          </div>
        </div>
      </Loader>
    </Modal>
  );
};

export default CheckInvoiceModal;
