/**
 *
 * @param {*} number - Number. The value to be rounded
 * @param {*} toString - Boolean (optional). If true will return string. False by default
 * @param {*} decimalPlaces - Number (optional). Maximum possible decimal cases the number will return. 2 by default
 * @returns
 */

const roundNumber = (number, toString = false, decimalPlaces = 2) => {
  // Round the number to the specified decimal places
  const roundedNumber = Math.round(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

  // Convert the rounded number to a string if toString is true
  if (toString) {
    return roundedNumber.toFixed(decimalPlaces).replace(/(?:\.0+|(\.\d+?)0+)$/, '$1');
  }

  return roundedNumber;
};

export default roundNumber;
