import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ExclamationCircleIcon as ExclamationCircleIconOutline } from '@heroicons/react/outline';

export default function Error({ message, className, outline }) {
  if (!message) return <></>;
  return (
    <div className={`flex items-center ${className}`}>
      {outline ? (
        <ExclamationCircleIconOutline className="w-4 h-4 text-red-500 mr-1" />
      ) : (
        <ExclamationCircleIcon className="w-4 h-4 text-red-500 mr-1" />
      )}
      <span className="text-sm text-red-600">{message}</span>
    </div>
  );
}
