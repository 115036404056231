import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../../../containers/App/PageWrapper';
import Attachments from '../../../../containers/Attachments/TimesheetAttachments';
import RemainingDays from '../../../../containers/Timesheets/RemainingDays';
import TimesheetSummary from '../../../../containers/Timesheets/TimesheetSummary';
import Chat from '../../../../containers/Chat/Chat';
import TimesheetCalendar from '../../../../containers/Timesheets/TimesheetCalendar';
import TimesheetCalendarGuide from '../../../../containers/Timesheets/TimesheetCalendarGuide';
import { downloadBase64File } from '../../../../helpers';
import { TimeSheetStatus, TimeSheetStatusLabels, TimeSheetStatusList } from '../../../../helpers/enum/timeSheet';
import useApi from '../../../../hooks/useApi';
import { MONTH_NAMES } from '../../../../helpers/date';
import { showSuccessNotification, setNotification } from '../../../../store/app/actions';
import TimesheetDayAllocationModal from '../../../../containers/Timesheets/TimesheetDayAllocationModal';
import dayjs from 'dayjs';
import notifications from '../../../../helpers/enum/notifications';
import UserDetails from '../../../../containers/LeaveRequests/UserDetails';
import ReactSelect from '../../../../components/Inputs/ReactSelect';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import contractTypes from '../../../../helpers/enum/contractTypeIDs';
import AccountsPayableLineItemTable from '../../../../containers/Timesheets/AccountsPayableLineItemsTable.js';
import PreviousAndNext from '../../../../containers/Timesheets/PreviousAndNext';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Loader from '../../../../components/Loading/Loader';
import countDecimalPlaces from '../../../../utils/countDecimalPlaces';
import FinancialDataFormat from '../../../../components/Modals/FinancialDataFormat';
import roundNumber from '../../../../utils/roundNumber';

const TimesheetAdminDetail = () => {
  const {
    holidays: { getHolidaysForTimesheets },
    leaveRequests: { getAllUserActiveLeaveRequests },
    purchaseOrders: { getOrdersForTimesheet },
    files: { deleteTimesheetFile, downloadTimesheetFile, getFilesOnFolderForTimesheets },
    timesheets: {
      getTimesheetEntries,
      getTimesheet,
      updateTimesheetStatus,
      addComment,
      uploadTimesheetFile,
      calculateDaysRemainingUntilDate,
    },
  } = useApi();

  const dispatch = useDispatch();
  const { id } = useParams();

  const [showAddLeaveRequestModal, setShowAddLeaveRequestModal] = useState(false);
  const [isLoadingStaffOrders, setIsLoadingStaffOrders] = useState(true);
  const [isLoadingHolidays, setIsLoadingHolidays] = useState(true);
  const [isLoadingLeaveRequests, setIsLoadingLeaveRequests] = useState(true);

  const isLoading = useMemo(() => {
    let _loading = true;
    if (!isLoadingEntries && !loadingFolder && !isLoadingHolidays && !isLoadingLeaveRequests && !isLoadingStaffOrders)
      _loading = false;
    return _loading;
  }, [isLoadingEntries, loadingFolder, isLoadingHolidays, isLoadingLeaveRequests, isLoadingStaffOrders]);

  const [isLoadingEntries, setIsLoadingEntries] = useState(true);
  const [draftTimesheets, setDraftTimesheets] = useState([]);
  const [signedTimesheets, setSignedTimesheets] = useState([]);
  const [invoice, setInvoice] = useState();
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [daysWorkedMultipleDecimalPlaces, setDaysWorkedMultipleDecimalPlaces] = useState(false);
  const [showFormatDaysWorkedModal, setShowFormatDaysWorkedModal] = useState(false);
  const [defaultInvoicingFormat, setDefaultInvoicingFormat] = useState(true);
  const [timeSheet, setTimeSheet] = useState({
    id: null,
    year: null,
    month: null,
    uuid: null,
    status: null,
  });
  const [selectedStatus, setSelectedStatus] = useState({
    value: 0,
    label: 'Select Status',
  });
  const [message, setMessage] = useState('');
  const [documents, setDocuments] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDayAllocationModal, setShowDayAllocationModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [userStaffOrders, setUserStaffOrders] = useState([]);
  const [activeStaffOrders, setActiveStaffOrders] = useState([]);
  const [notifyUser, setNotifyUser] = useState(true);
  const [isUserActive, setIsUserActive] = useState(true);
  const [showMultiplePOAlert, setShowMultiplePOAlert] = useState(false);

  const dateMemo = useMemo(() => {
    if (timeSheet?.id) {
      const tsMonth = timeSheet.month < 10 ? '0' + timeSheet.month : timeSheet.month;
      const date = `${timeSheet.year}-${tsMonth}-01`;
      const formatted = new Date(date);
      return formatted;
    } else return new Date();
  }, [timeSheet]);

  useEffect(() => {
    setLoadingFolder(true);
    setIsLoadingStaffOrders(true);
    setIsLoadingHolidays(true);
    setIsLoadingLeaveRequests(true);
    getTimesheet(id).then(async res => {
      setTimeSheet({
        id: res?.id,
        month: res?.month,
        year: res?.year,
        uuid: res?.uuid,
        status: res?.status,
        user_id: res?.user?.id,
        user_name: res?.user?.first_names,
        invoice_approved: res?.accountsPayable?.approved,
        invoice_uploaded: res?.invoice_uploaded,
        invoice_paid: res?.accountsPayable?.paid,
        invoice_comment: res?.invoice_comment,
      });

      setIsUserActive(res?.user?.active);
      setNotifyUser(res?.user?.active);
      const startDate = moment(new Date(res.year, res.month - 1, 1)).format('YYYY-MM-DD');
      const endDate = format(endOfMonth(new Date(res.year, res.month - 1)), 'yyyy-MM-dd');
      const userId = res?.user_id;

      getOrdersForTimesheet(userId)
        .then(async d => {
          const timesheetStart = startOfMonth(new Date(res?.year, res?.month - 1));
          const timesheetEnd = endOfMonth(new Date(res?.year, res?.month - 1));
          setUserStaffOrders(d);
          let activeStaffOrders = d.filter(so => {
            return (
              new Date(so.order_start) <= timesheetEnd && (so.order_end === null || new Date(so.order_end) >= timesheetStart)
            );
          });
          for (let [index, so] of activeStaffOrders.entries()) {
            const calculatedDays = await calculateDaysRemainingUntilDate(so.id, new Date(`${res.year}/${res.month}/01`));
            if (activeStaffOrders[index].days_ordered) {
              activeStaffOrders[index].daysRemainingUntilThisMonth = calculatedDays.daysRemainingUntilThisDate;
            } else {
              activeStaffOrders[index].daysConsumedUntilThisMonth = calculatedDays.daysConsumedUntilThisDate;
            }
          }
          setActiveStaffOrders(activeStaffOrders);
          setIsLoadingStaffOrders(false);
        })
        .catch(err => {
          setIsLoadingStaffOrders(false);
          return err;
        });

      getAllUserActiveLeaveRequests(userId, startDate, endDate)
        .then(res => {
          const leaveRequests = res.map(leaveRequest => ({
            id: leaveRequest?.id,
            status: leaveRequest?.status,
            startDate: leaveRequest?.start_date,
            endDate: leaveRequest?.end_date,
            endHalfDay: leaveRequest?.end_half_day,
            startHalfDay: leaveRequest?.start_half_day,
            type: leaveRequest.request_type,
          }));
          setLeaveRequests(leaveRequests);
          setIsLoadingLeaveRequests(false);
        })
        .catch(err => {
          setIsLoadingLeaveRequests(false);
          return err;
        });
      getHolidaysForTimesheets(startDate, endDate, userId)
        .then(res => {
          setHolidays(res.data);
          setIsLoadingHolidays(false);
        })
        .catch(err => {
          setIsLoadingHolidays(false);
          return err;
        });
      setSelectedStatus({
        value: res.status,
        label: TimeSheetStatusLabels[res.status],
      });

      getFilesOnFolderForTimesheets(`timesheet/${res.uuid}`)
        .then(res => {
          const data = res.data.map(res => ({ ...res, id: res.key, resource: res.key }));
          setSignedTimesheets(data.filter(e => e?.isSignedTimesheet));
          setDraftTimesheets(data.filter(e => e?.isDraftTimesheet));
          setInvoice(data.find(e => e?.isInvoice));
          setDocuments(data.filter(e => e?.isOther));
          setLoadingFolder(false);
        })
        .catch(err => {
          setLoadingFolder(false);
        });
    });
  }, [id, showAddLeaveRequestModal]);

  useEffect(() => {
    if (!isLoadingEntries) setIsLoadingEntries(true);
    getTimesheetEntries(id)
      .then(res => {
        setTimesheetEntries(res.data);

        setIsLoadingEntries(false);
      })
      .catch(err => {
        setIsLoadingEntries(false);
        return err;
      });
  }, [id, showDayAllocationModal]);

  useEffect(() => {
    let hoursWorkedPerPo = [];
    timesheetEntries.forEach(entry => {
      if (hoursWorkedPerPo.find(e => e.staffOrderId === entry.staff_order_id)) {
        const index = hoursWorkedPerPo.findIndex(e => e.staffOrderId === entry.staff_order_id);
        hoursWorkedPerPo[index].hours += entry.hours;
      } else {
        hoursWorkedPerPo.push({
          staffOrderId: entry.staff_order_id,
          hours: entry.hours,
        });
      }
    });
    if (hoursWorkedPerPo.length && hoursWorkedPerPo.find(e => countDecimalPlaces(roundNumber(e.hours) / 8) > 2)) {
      setDaysWorkedMultipleDecimalPlaces(true);
      setDefaultInvoicingFormat(false);
    } else {
      setDaysWorkedMultipleDecimalPlaces(false);
      setDefaultInvoicingFormat(true);
    }
  }, [timesheetEntries]);

  const handleSelection = (option, dataForLineCreation) => {
    updateTimesheetStatus({
      timesheetID: id,
      status: option.value,
      notifyUser: notifyUser,
      dataForLineCreation: dataForLineCreation,
    }).then(res => {
      setShowMultiplePOAlert(false);
      dispatch(showSuccessNotification('Status updated successfully!'));
      setSelectedStatus(option);
      setTimeSheet(prev => ({ ...prev, status: option.value }));
      if (
        ((timeSheet.status === TimeSheetStatus.APPROVED || timeSheet.status === TimeSheetStatus.PREAPPROVE) &&
          option.value !== TimeSheetStatus.APPROVED &&
          option.value !== TimeSheetStatus.PREAPPROVE) ||
        ((option.value === TimeSheetStatus.APPROVED || option.value === TimeSheetStatus.PREAPPROVE) &&
          timeSheet.status !== TimeSheetStatus.APPROVED &&
          timeSheet.status !== TimeSheetStatus.PREAPPROVE)
      ) {
        getOrdersForTimesheet(timeSheet.user_id).then(async d => {
          const timesheetStart = startOfMonth(new Date(timeSheet?.year, timeSheet?.month - 1));
          const timesheetEnd = endOfMonth(new Date(timeSheet?.year, timeSheet?.month - 1));
          setUserStaffOrders(d);
          const activeStaffOrders = d.filter(so => {
            return (
              new Date(so.order_start) <= timesheetEnd && (so.order_end === null || new Date(so.order_end) >= timesheetStart)
            );
          });
          for (let [index, so] of activeStaffOrders.entries()) {
            const calculatedDays = await calculateDaysRemainingUntilDate(
              so.id,
              new Date(`${timeSheet.year}/${timeSheet.month}/01`),
            );
            if (activeStaffOrders[index].days_ordered) {
              activeStaffOrders[index].daysRemainingUntilThisMonth = calculatedDays.daysRemainingUntilThisDate;
            } else {
              activeStaffOrders[index].daysConsumedUntilThisMonth = calculatedDays.daysConsumedUntilThisDate;
            }
          }
          setActiveStaffOrders(activeStaffOrders);
        });
      }
    });
  };
  const onMessageChange = e => setMessage(e.target.value);

  const sendMessageHandler = () => addComment({ timesheet_id: id, comment: message }).then(res => setMessage(''));

  const prepareChatData = res => {
    const messages = res.data.map(message => {
      const momentOfPublish = new Date(message.created_at);
      return {
        id: message.id,
        ownerName: message.user.first_names,
        ownerImg: message.user.profilepic_url,
        ownerId: message.user_id,
        text: message.comment,
        time: momentOfPublish.toLocaleString('en-us'),
      };
    });

    return messages;
  };

  const downloadFileHandler = id => {
    let file;
    file = [...draftTimesheets, ...signedTimesheets, invoice].find(doc => doc?.id === id);
    if (!file) file = documents.find(document => document.id == id);

    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadTimesheetFile(file.resource)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const removeFileHandler = id => {
    deleteTimesheetFile(id)
      .then(response => {
        if (documents.find(document => document.id === id)) {
          setDocuments(preDocuments => [...preDocuments.filter(document => document.id !== id)]);
        }
        if (signedTimesheets.find(signedTimesheet => signedTimesheet.id === id)) {
          setSignedTimesheets(prev => [...prev.filter(st => st.id !== id)]);
        }
        if (draftTimesheets.find(draftTimesheet => draftTimesheet.id === id)) {
          setDraftTimesheets(prev => [...prev.filter(dt => dt.id !== id)]);
        }
        if (invoice?.id === id) {
          setTimeSheet(prev => ({
            ...prev,
            invoice_approved: false,
            invoice_uploaded: false,
          }));
          setInvoice();
        }

        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  const uploadFileHandler = async (fileInfo, type, invoiceIsAMatch, optionalComment) => {
    switch (type) {
      case 'signedTimesheets':
        if (signedTimesheets.find(st => st.name === fileInfo?.name)) {
          setUploading(false);
          return setShowDuplicateAlert(fileInfo?.name);
        }
        break;
      case 'draftTimesheets':
        if (draftTimesheets.find(dt => dt.name === fileInfo?.name)) {
          setUploading(false);
          return setShowDuplicateAlert(fileInfo?.name);
        }
        break;
      case 'invoice':
        break;
      default:
        if (documents.find(doc => doc.name === fileInfo?.name)) {
          setUploading(false);
          return setShowDuplicateAlert(fileInfo?.name);
        }
        break;
    }
    const tags = {};
    if (type === 'invoice') {
      tags.invoiceMatches = invoiceIsAMatch;
      if (optionalComment !== '') tags.comment = optionalComment;
    }
    uploadTimesheetFile(fileInfo, timeSheet.uuid, type, tags)
      .then(response => {
        const newFile = {
          ...response.data,
          id: response.key,
          resource: response.key,
        };
        switch (type) {
          case 'signedTimesheets':
            setSignedTimesheets(prev => [...prev, newFile]);
            break;
          case 'draftTimesheets':
            setDraftTimesheets(prev => [...prev, newFile]);
            break;
          case 'invoice':
            //Invoice matches needs to be turned to string to replicate the way it comes from S3
            newFile.invoiceMatches = tags.invoiceMatches.toString();
            if (tags?.comment) setTimeSheet(prev => ({ ...prev, invoice_comment: tags?.comment }));
            setInvoice(newFile);
            break;
          default:
            setDocuments(prev => [...prev, newFile]);
            break;
        }
        setUploading(false);
      })
      .catch(err => {
        setUploading(false);
      });
  };

  const previewHandler = (id, reviewInvoice) => {
    let file;
    file = [...draftTimesheets, ...signedTimesheets, invoice].find(doc => doc?.id === id);
    if (!file) file = documents.find(document => document.id == id);

    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];

    downloadTimesheetFile(file.resource)
      .then(response => {
        setPreparingPreview(false);
        if (reviewInvoice) {
          setShowPreview({
            url: response.data,
            show: true,
            fileType: file.resource.split('.')[file.resource.split('.').length - 1],
            title: fileName.split('.')[fileName.split('.').length - 2],
            underTitle: (
              <AccountsPayableLineItemTable
                timesheetId={timeSheet?.id}
                timesheetStatus={timeSheet?.status}
                optionalComments={timeSheet?.invoice_comment}
                activeStaffOrders={activeStaffOrders}
                userId={timeSheet.user_id}
                _isApproved={timeSheet.invoice_approved}
                setTimesheet={setTimeSheet}
                setShowPreview={setShowPreview}
              />
            ),
          });
        } else {
          setShowPreview({
            url: response.data,
            show: true,
            fileType: file.resource.split('.')[file.resource.split('.').length - 1],
            title: fileName.split('.')[fileName.split('.').length - 2],
          });
        }
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const _onDayClick = (days, entries, month, year, e) => {
    if (timeSheet.status !== TimeSheetStatus.APPROVED) {
      let date = days.map(d => year + '-' + month + '-' + d);
      let allocationsEntries = [];
      entries &&
        entries.map(e => {
          if (days.some(day => day === dayjs(e.date).get('date'))) {
            allocationsEntries.push(e);
          }
        });

      setAllocations(allocationsEntries);
      setSelectedDays(date);
      setShowDayAllocationModal(true);
    } else {
      setNotification(
        notifications.WARNING_YELLOW,
        'Unable to add entries to approved timesheets.',
        'In order to add/edit entries, please change the status of the timesheet',
      );
    }
  };

  const closeModal = () => {
    setShowDayAllocationModal(false);
  };

  const pages = [
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
    { name: 'Manage Timesheets', href: '/admin-panel/hr/timesheets', current: false },
    {
      name: `${timeSheet.user_name} - ${MONTH_NAMES[timeSheet.month - 1]} ${timeSheet.year}`,
      href: `/admin-panel/hr/timesheets/${id}`,
      current: true,
    },
  ];

  const onStatusChange = option => {
    if (
      selectedStatus.value !== TimeSheetStatus.APPROVED &&
      selectedStatus.value !== TimeSheetStatus.PREAPPROVE &&
      (option.value === TimeSheetStatus.APPROVED || option.value === TimeSheetStatus.PREAPPROVE)
    ) {
      if (activeStaffOrders.length > 1) {
        setShowMultiplePOAlert(option);
      } else if (!defaultInvoicingFormat) {
        setShowFormatDaysWorkedModal(option);
      } else {
        handleSelection(option);
      }
    } else {
      handleSelection(option);
    }
  };

  const onMultiplePOAcceptClick = () => {
    const selectedStatus = showMultiplePOAlert;
    if (!defaultInvoicingFormat) {
      setShowMultiplePOAlert(false);
      setShowFormatDaysWorkedModal(selectedStatus);
    } else {
      handleSelection(selectedStatus);
    }
  };

  return (
    <PageWrapper pages={pages}>
      <SimpleAlert
        errorTitle={'Unable to upload file'}
        errorMsg={`${showDuplicateAlert && showDuplicateAlert} file name already exists on this folder.`}
        onAcceptClick={() => setShowDuplicateAlert(false)}
        onAcceptText={'Close'}
        show={showDuplicateAlert != false}
        hide={() => setShowDuplicateAlert(false)}
      />
      <SimpleAlert
        errorTitle={'User has multiple Purchase orders'}
        errorMsg={
          "There are multiple active PO's on this timesheet. Make sure consultant allocated work days to the correct one before approving Timesheet or consultant Invoice."
        }
        onAcceptClick={() => onMultiplePOAcceptClick()}
        onAcceptText={'Update status'}
        onDeclineClick={() => setShowMultiplePOAlert(false)}
        onDeclineText={'Cancel'}
        show={showMultiplePOAlert ? true : false}
        hide={() => setShowMultiplePOAlert(false)}
      />
      <FinancialDataFormat
        show={showFormatDaysWorkedModal ? true : false}
        setShow={setShowFormatDaysWorkedModal}
        selectedStatus={showFormatDaysWorkedModal}
        activeStaffOrders={activeStaffOrders}
        entries={timesheetEntries}
        onAcceptClick={handleSelection}
      />
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
        underTitle={showPreview?.underTitle}
      />
      <TimesheetDayAllocationModal
        timesheetID={id}
        selectedDays={selectedDays}
        staffOrders={userStaffOrders}
        allocations={allocations}
        onClose={closeModal}
        show={showDayAllocationModal}
      />
      <Loader isLoading={isLoading}>
        <div className="flex flex-col space-y-4">
          <div>
            <div className="flex flex-col items-start justify-between ">
              <div className="flex flex-row items-center justify-center sm:justify-start w-full">
                <span className="text-lg font-bold text-gray-800 mr-4">Status</span>
                <div className="w-56 flex flex-col">
                  <ReactSelect
                    options={TimeSheetStatusList}
                    selectedOptions={selectedStatus}
                    onChange={option => onStatusChange(option)}
                  />
                </div>
                <span className="ml-4">
                  <Checkbox
                    title="Notify user"
                    disabled={!isUserActive}
                    horizontal
                    value={notifyUser}
                    onChange={() => setNotifyUser(!notifyUser)}
                  />
                </span>
                {timeSheet.status !== TimeSheetStatus.APPROVED && timeSheet.status !== TimeSheetStatus.PREAPPROVE && (
                  <span className="ml-4">
                    <Checkbox
                      title="Default invoicing format"
                      disabled={daysWorkedMultipleDecimalPlaces}
                      horizontal
                      value={defaultInvoicingFormat}
                      onChange={() => setDefaultInvoicingFormat(!defaultInvoicingFormat)}
                    />
                  </span>
                )}
              </div>
              <div className="flex flex-col sm:flex-row w-full space-y-4 mb-2 justify-between items-center mt-2 sm:mt-0">
                <div className="mr-8 mt-2 flex space-x-2 items-center justify-center sm:justify-start">
                  <div className="flex space-x-2">
                    <span className="text-lg font-bold text-gray-800">{MONTH_NAMES[timeSheet.month - 1]}</span>
                    <span className="ml-1 text-lg text-gray-600 font-normal">{timeSheet.year}</span>
                  </div>
                  <PreviousAndNext userId={timeSheet.user_id} month={timeSheet.month} year={timeSheet.year} />
                </div>
                <div>
                  <TimesheetCalendarGuide />
                </div>
              </div>
            </div>
            <TimesheetCalendar
              isApprovedOrPreApproved={
                timeSheet.status === TimeSheetStatus.APPROVED || timeSheet.status === TimeSheetStatus.PREAPPROVE ? true : false
              }
              holidays={holidays}
              leaveRequests={leaveRequests}
              timesheetEntries={timesheetEntries}
              setTimesheetEntries={setTimesheetEntries}
              timesheetID={id}
              month={timeSheet.month}
              year={timeSheet.year}
              onDayClick={_onDayClick}
              activeStaffOrders={activeStaffOrders}
            />
          </div>
          <RemainingDays timesheetEntries={timesheetEntries} staffOrders={activeStaffOrders} isAdmin={true} />
          <div className="flex flex-col lg:flex-row lg:space-x-4">
            <TimesheetSummary
              timesheetEntries={timesheetEntries}
              leaveRequests={leaveRequests}
              isAdmin={true}
              userId={timeSheet.user_id}
              showAddLeaveRequestModal={showAddLeaveRequestModal}
              setShowAddLeaveRequestModal={setShowAddLeaveRequestModal}
            />
            <UserDetails userId={timeSheet.user_id} timesheetDate={dateMemo} />
          </div>
          <div className="flex flex-col lg:grid lg:grid-cols-2 gap-y-4 lg:gap-y-0 lg:gap-x-4">
            <Attachments
              uploadHandler={uploadFileHandler}
              documents={documents}
              maxFileSizeInMb={10}
              downloadHandler={downloadFileHandler}
              removeHandler={removeFileHandler}
              deleting={deleting}
              setDeleting={setDeleting}
              downloading={downloading}
              setDownloading={setDownloading}
              loadingFolder={loadingFolder}
              uploading={uploading}
              setUploading={setUploading}
              preparingPreview={preparingPreview}
              setPreparingPreview={setPreparingPreview}
              previewHandler={previewHandler}
              enableAdd={timeSheet.status === TimeSheetStatus.APPROVED ? false : true}
              enableRemove={timeSheet.status === TimeSheetStatus.APPROVED ? false : true}
              draftTimesheets={draftTimesheets}
              signedTimesheets={signedTimesheets}
              invoice={invoice}
              isInvoiceApproved={timeSheet?.invoice_approved}
              isInvoicePaid={timeSheet?.invoice_paid}
              isFreelancerOrCompany={
                activeStaffOrders.filter(
                  so =>
                    so?.staffContract?.contract_type_id === contractTypes.freelancer ||
                    so?.staffContract?.contract_type_id === contractTypes.company,
                ).length
              }
              timesheetId={timeSheet?.id}
              timesheetStatus={timeSheet?.status}
              enableAddInvoice={true}
              enableRemoveInvoice={timeSheet?.invoice_approved ? false : true}
              invoiceComment={timeSheet?.invoice_comment}
              activeStaffOrders={activeStaffOrders.map(so => {
                return {
                  id: so?.id,
                };
              })}
            />
            <Chat
              url={`/timesheet-comments/${id}`}
              messageText={message}
              prepare={prepareChatData}
              messageOnChange={onMessageChange}
              sendMessage={sendMessageHandler}
            />
          </div>
        </div>
      </Loader>
    </PageWrapper>
  );
};

export default TimesheetAdminDetail;
