import React, { useRef, useState, useMemo, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import formatCurrency from '../../../utils/formatCurrency';
import format from 'date-fns/format';
import CreditNoteTemplate from '../../../helpers/creditNoteTemplate';
import SlideOver from '../../../components/SlideOvers/SlideOver';
import { DownloadIcon } from '@heroicons/react/outline';
import useApi from '../../../hooks/useApi';
import FileNameSettings from '../../Documents/FilenameSettings';

function CreditNotePDFPreview({ creditNoteData, lineItems, showPreview, setShowPreview, canDownload = true, isEditing = false }) {
  const {
    files: { getCreditNotePDFByID },
    creditNotes: { getCreditNoteFileName },
  } = useApi();
  const iframeRef = useRef(null); // Reference to the iframe element

  const [pdfDataUrl, setPdfDataUrl] = useState(null); // State to hold the PDF data URL
  const [timeoutIdState, setTimeoutIdState] = useState();
  const [showFileNameSettings, setShowFileNameSettings] = useState(false);
  const [loading, setLoading] = useState(false);

  // Register fonts
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = {
    // Font definitions
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
  };

  const debounceDelay = 1000; // Delay in milliseconds for debouncing

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutIdState) {
        clearTimeout(timeoutIdState);
      }
      timeoutId = setTimeout(() => {
        func();
      }, delay);
      setTimeoutIdState(timeoutId);
    };
  };

  const generatePDF = () => {
    if (creditNoteData?.id && !isEditing) {
      // setIsLoading(true);
      getCreditNotePDFByID(creditNoteData.id)
        .then(res => {
          const base64Data = res.data;
          const contentType = 'application/pdf';
          const url = `data: ${contentType};base64:${base64Data}`;
          const iframe = iframeRef.current;
          if (iframe) iframe.src = url + '#toolbar=0';
          setPdfDataUrl(url);
        })
        .catch(err => {
          //if credit note not available in S3, re-generate invoice based on data provided
          console.log('could not find credit note');
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.getDataUrl(dataUrl => {
            setPdfDataUrl(dataUrl); // Save the PDF data URL to state
            const iframe = iframeRef.current;
            if (iframe) iframe.src = dataUrl + '#toolbar=0';
          });
        });
    } else {
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
      pdfDocGenerator.getDataUrl(dataUrl => {
        setPdfDataUrl(dataUrl); // Save the PDF data URL to state
        const iframe = iframeRef.current;
        if (iframe) iframe.src = dataUrl + '#toolbar=0';
      });
    }
  };

  const debounceGeneratePDF = debounce(generatePDF, debounceDelay);

  const onDownloadClick = () => {
    setShowFileNameSettings(true);
  };

  const downloadPDF = fileNameStructure => {
    if (pdfDataUrl) {
      getCreditNoteFileName(creditNoteData.credit_note_number, fileNameStructure)
        .then(res => {
          const link = document.createElement('a');
          link.href = pdfDataUrl;
          link.download = res.data.fileName;
          link.click();
          setLoading(false);
          setShowFileNameSettings(false);
        })
        .catch(err => {
          setLoading(false);
          setShowFileNameSettings(false);
        });
    }
  };

  function replaceCommentVariables(text) {
    const replacements = {
      issue_date: creditNoteData.issue_date ? format(creditNoteData.issue_date, 'MMM do, yyyy') : '{{issue_date}}',
      total_incl_vat: creditNoteData?.amount_and_vat
        ? formatCurrency(creditNoteData.amount_and_vat, true, true)
        : '{{total_incl_vat}}',
      receivables_iban: creditNoteData.metadata.footer_data.iban || '{{receivables_iban}}',
      due_date: creditNoteData.due_date ? format(creditNoteData.due_date, 'MMM do, yyyy') : '{{due_date}}',
    };
    const regex = /\{\{([^{}]+)\}\}/g;
    const replacedText = text.replace(regex, (match, placeholder) => {
      const replacement = replacements[placeholder.trim()];
      return replacement !== undefined ? replacement : match;
    });
    return replacedText;
  }

  const documentDefinition = useMemo(() => {
    // Define the document definition object
    let _invoiceData = {
      ...creditNoteData,
      metadata: {
        ...creditNoteData.metadata,
        header_comment: replaceCommentVariables(creditNoteData.metadata.header_comment),
        footer_comment: replaceCommentVariables(creditNoteData.metadata.footer_comment),
      },
      issue_date: format(creditNoteData.issue_date, 'MMM do, yyyy'),
      due_date: creditNoteData.due_date ? format(creditNoteData.due_date, 'MMM do, yyyy') : '',
    };

    const _documentDefinition = CreditNoteTemplate({ creditNoteData: _invoiceData, lineItems });
    return _documentDefinition;
  }, [lineItems, creditNoteData, showPreview]);

  useEffect(() => {
    if (!showPreview) return;
    if (!pdfDataUrl) {
      generatePDF();
    } else {
      debounceGeneratePDF(); // Generate the PDF when the component mounts
    }
  }, [creditNoteData, lineItems, showPreview]); // Regenerate PDF when inputs change

  return (
    <SlideOver
      show={showPreview}
      onHide={() => setShowPreview(false)}
      title={
        <div className="flex items-center">
          Credit note preview{' '}
          {canDownload ? (
            <DownloadIcon
              className="h-6 w-6 ml-2 text-gray-400 hover:text-thaleria-orange-700 hover:cursor-pointer"
              onClick={onDownloadClick}
            />
          ) : (
            ''
          )}
        </div>
      }
    >
      <FileNameSettings
        show={showFileNameSettings}
        setShow={setShowFileNameSettings}
        fileType="creditNotes"
        loading={loading}
        onCancel={() => setShowFileNameSettings(false)}
        onAccept={downloadPDF}
      />
      <div className="flex-1 h-full overflow-auto">
        <iframe ref={iframeRef} title="PDF Preview" className="w-full h-full" />
      </div>
    </SlideOver>
  );
}

export default CreditNotePDFPreview;
