import React, { useEffect, useState, useMemo } from 'react';
import { CalendarIcon, HashtagIcon, UserIcon, ClockIcon } from '@heroicons/react/solid';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/useQuery';
import { ArrowDownIcon } from '@heroicons/react/outline';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { LeaveRequestTypeLabel } from '../../helpers/enum/leaveRequest';
import RefreshButton from '../../components/Buttons/RefreshButton';
import Loader from '../../components/Loading/Loader';

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function TaskLog({
  logs,
  fetchNextPage,
  scrollSetting,
  userOptions,
  onTaskClick,
  activeTaskId,
  refreshLogAndDetails,
  isLoading,
  isLoadingNextPage,
}) {
  const history = useHistory();

  const query = useQuery();

  const [filterChanged, setFilterChanged] = useState(true);
  const [filteredTags, setFilteredTags] = useState([]);
  const [filteredModule, setFilteredModule] = useState([]);
  const [filteredClosed, setFilteredClosed] = useState([]);

  useEffect(() => {
    //update selected fields if url is changed
    if (query.get('tags')) {
      setFilteredTags(query.get('tags').split(','));
    } else {
      setFilteredTags([]);
    }
    if (query.get('module')) {
      setFilteredModule([query.get('module')]);
    } else {
      setFilteredModule([]);
    }
    if (query.get('closed')) {
      setFilteredClosed([query.get('closed')]);
    } else {
      setFilteredClosed([]);
    }
  }, [query.get('tags'), query.get('module'), query.get('closed')]);

  useEffect(() => {
    //update url if fields change
    let allQueries = [];
    let fullQuery = undefined;
    setFilterChanged(false);

    if (filteredTags?.length) allQueries.push(`tags=${filteredTags.toString()}`);
    if (filteredModule?.length) allQueries.push(`module=${filteredModule.toString()}`);
    if (filteredClosed?.length) allQueries.push(`closed=${filteredClosed.toString()}`);

    fullQuery = `${history.location.pathname}?${allQueries.join('&')}`;
    history.push(fullQuery);
  }, [filterChanged]);

  const filterByTag = e => {
    setFilterChanged(true);
    let users;
    !e
      ? setFilteredTags([])
      : (users = e.map((e, index) => {
          return e.value;
        }));
    setFilteredTags(users);
    return;
  };

  const filterByModule = e => {
    setFilterChanged(true);
    !e ? setFilteredModule([]) : setFilteredModule([e.value]);
  };

  const filterByClosed = e => {
    setFilterChanged(true);
    !e ? setFilteredClosed([]) : setFilteredClosed([e.value]);
  };

  const moduleOptions = [
    {
      value: 'timesheets',
      label: 'Timesheets',
    },
    {
      value: 'leaveRequests',
      label: 'Leave requests',
    },
  ];

  const closedOptions = [
    {
      value: 'true',
      label: 'Closed',
    },
    {
      value: 'false',
      label: 'Open',
    },
  ];

  const formatTaskClosed = closed => {
    if (closed || closed === null) {
      return <></>;
    } else if (closed === false) return <span className="bg-orange-400 text-orange-100 py-1 px-2 rounded-xl text-xs">Open</span>;
  };

  const clearFilters = () => {
    setFilteredTags([]);
    setFilteredModule([]);
    setFilteredClosed([]);
    setFilterChanged(true);
  };

  const footerHeight = document.getElementById('footer')?.offsetHeight;
  const headerHeight = document.getElementById('header')?.offsetHeight;
  const filterHeight = document.getElementById('filter-container')?.offsetHeight;

  const STYLES = {
    height: `calc(100vh - ${footerHeight + headerHeight + filterHeight}px)`,
  };

  const allLogs = useMemo(() => {
    if (logs?.data?.length) {
      return logs.data.map(task => (
        <div
          key={task.id}
          className={`${activeTaskId === task.id ? 'bg-gray-100' : 'bg-white'} w-full py-3 px-6 hover:bg-blue-50 `}
          onClick={() => onTaskClick(task.id)}
        >
          <div className="flex items-center justify-between">
            <div className="min-w-0 flex items-start ">
              <div className="min-w-0 items-center flex flex-col">
                <div className="flex space-x-2 mr-auto">
                  <p className="text-sm font-medium text-gray-700 truncate mr-auto">{task.events[0].message}</p>
                  <ExternalLinkIcon
                    className="h-5 w-5 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer"
                    onClick={() => window.open(task.events[0].href, '_blank')}
                  />
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-400 mr-auto space-x-4">
                  <div className="flex w-32">
                    <HashtagIcon className="flex-shrink-0 h-5 w-5 text-gray-400 " aria-hidden="true" />
                    <span className="truncate ml-1">{task.events[0].module}</span>
                  </div>
                  {task.events[0].module === 'Timesheets' && (
                    <>
                      <div className="flex w-32">
                        <UserIcon className="flex-shrink-0 h-5 w-5 text-gray-400 " aria-hidden="true" />
                        <span className="truncate ml-1">{`${task.events[0]?.timesheet?.user?.first_names} ${task.events[0]?.timesheet?.user?.last_names}`}</span>
                      </div>
                      <div className="flex w-32">
                        <CalendarIcon className="flex-shrink-0 h-5 w-5 text-gray-400 " aria-hidden="true" />
                        <span className="truncate ml-1">{`${months[task.events[0]?.timesheet?.month - 1]} ${
                          task.events[0]?.timesheet?.year
                        }`}</span>
                      </div>
                    </>
                  )}
                  {task.events[0].module === 'Leave requests' && (
                    <>
                      <div className="flex w-32">
                        <UserIcon className="flex-shrink-0 h-5 w-5 text-gray-400 " aria-hidden="true" />
                        <span className="truncate ml-1">{`${task.events[0].leaveRequest.user.first_names} ${task.events[0].leaveRequest.user.last_names}`}</span>
                      </div>
                      <div className="flex w-32">
                        <CalendarIcon className="flex-shrink-0 h-5 w-5 text-gray-400 " aria-hidden="true" />
                        <span className="truncate ml-1">{`${
                          LeaveRequestTypeLabel[task.events[0].leaveRequest.request_type]
                        }`}</span>
                      </div>
                    </>
                  )}
                  <div className="flex w-32">
                    <ClockIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="truncate ml-1">{dayjs(task.last_event).format('MMM D, HH:mm')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>{formatTaskClosed(task.closed)}</div>
          </div>
        </div>
      ));
    } else return [];
  }, [logs, activeTaskId]);

  return (
    <div className="grow bg-banner-pattern relative">
      <div className="inset-x-0 top-0 w-full bg-banner-pattern p-5 border-b border-gray-200" id="filter-container">
        <div className="flex w-full justify-between">
          <div className="mt-[-2px] mr-2 text-sm font-medium text-gray-500">Filter results</div>
          <div className="flex space-x-4 items-center">
            <div className="flex text-sm cursor-pointer" onClick={() => clearFilters()}>
              <p className="text-blue-400">Clear all</p>
            </div>
            <RefreshButton onClick={refreshLogAndDetails} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between space-y-2 md:space-y-0 md:space-x-6 mt-2">
          <ReactSelect
            isSearchable
            label="Tags"
            onChange={filterByTag}
            isMulti
            options={userOptions}
            selectedOptionsIds={filteredTags}
          />
          <ReactSelect label="Module" onChange={filterByModule} options={moduleOptions} selectedOptionsIds={filteredModule} />
          <ReactSelect label="Closed" onChange={filterByClosed} options={closedOptions} selectedOptionsIds={filteredClosed} />
        </div>
      </div>
      <div className="w-full divide-y rounded overflow-y-auto" style={STYLES}>
        <Loader isLoading={isLoading}>
          {allLogs.length ? (
            allLogs
          ) : history.location.search == '' ? (
            <p className="w-full text-center p-6">There are no tasks yet.</p>
          ) : (
            <p className="w-full text-center p-6">No tasks match.</p>
          )}
        </Loader>
        <Loader isLoadingNextPage={isLoadingNextPage}>
          {scrollSetting.hasMore && !isLoading ? (
            <div className="w-full flex justify-around my-4">
              <ButtonPrimary text="View more" icon={<ArrowDownIcon className="h-4 w-4 mr-3 p-auto" />} onClick={fetchNextPage} />
            </div>
          ) : (
            ''
          )}
        </Loader>
      </div>
    </div>
  );
}

export default TaskLog;
