import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputSimple from '../../components/Inputs/InputSimple';
import { setNotification } from '../../store/app/actions';
import notifications from '../../helpers/enum/notifications';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/solid';
import { startOfDay, endOfDay, isBefore, isAfter } from 'date-fns';
import useApi from '../../hooks/useApi';
import ReactSelect from '../../components/Inputs/ReactSelect';
import { useSelector } from 'react-redux';
import { AccountType } from '../../helpers/enum/accounts';
import allocationTypesToString from '../../helpers/enum/allocationTypesToString';
import allocationTypes from '../../helpers/enum/allocationTypes';
import { useMediaQuery } from '@react-hook/media-query';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import roundNumber from '../../utils/roundNumber';

const INITIAL_STATE = {
  mission: null,
  hours: 0,
  type: allocationTypes.billable,
};

const isBetween = (dates, min, max) => {
  let valid = true;

  dates.forEach(date => {
    date = new Date(date);
    //Check if all selected dates are within the purchase orders start and end dates
    if (isBefore(startOfDay(date), startOfDay(min))) valid = false;
    if (max && isAfter(endOfDay(date), endOfDay(max))) valid = false;
    if (!valid) return valid;
  });
  return valid;
};

function AllocationsTable({ staffOrders, allocations, selectedDays, onClose }) {
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  const { id } = useParams();

  const {
    timesheets: { saveTimesheetEntries, deleteTimesheetEntry },
  } = useApi();

  let missionListBoxData = [];
  let allocationTypesListBoxData = [
    {
      value: allocationTypes.billable,
      label: allocationTypesToString[allocationTypes.billable],
    },
    {
      value: allocationTypes.onboarding,
      label: allocationTypesToString[allocationTypes.onboarding],
    },
    {
      value: allocationTypes.offboarding,
      label: allocationTypesToString[allocationTypes.offboarding],
    },
  ];

  const dispatch = useDispatch();

  const [entries, setEntries] = useState([]);
  const [addNew, setAddNew] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState([]);

  const [allocationIds, setAllocationIds] = useState([]);

  if (staffOrders)
    staffOrders.map(e => {
      if (isBetween(selectedDays, new Date(e.order_start), e.order_end ? new Date(e.order_end) : null))
        missionListBoxData.push({ value: e.id, label: e.order_ref });
    });

  useEffect(() => {
    if (allocations?.length) {
      allocations.map(allocation => {
        allocation.mission = {
          value: allocation.staffOrder.id,
          label: allocation.staffOrder.order_ref,
        };
      });
      allocations.sort((objA, objB) => Number(new Date(objA.date)) - Number(new Date(objB.date)));
      setEntries(allocations);
      setAllocationIds(allocations.map(alloc => alloc.id));
    }
  }, [allocations]);

  const addEntry = () => {
    if (addNew.mission?.value) {
      if (addNew.hours >= 0.01 && addNew.hours <= 16) {
        if (entries.some(entry => entry.mission?.value === addNew?.mission?.value)) {
          return setErrors([{ field: 'mission', msg: 'Only one entry per mission is allowed' }]);
        } else {
          setErrors(prev => prev.filter(el => el.field !== 'mission'));
        }

        let data = {
          date: selectedDays,
          hours: roundNumber(new Number(addNew.hours)),
          staff_order_id: addNew.mission.value,
          timesheet_id: id,
          type: addNew.type,
        };
        saveTimesheetEntries(data).then(res => {
          const newAllocations = res.data.map(alloc => {
            alloc.mission = {
              label: addNew?.mission?.label,
              value: alloc.staffOrderId,
            };
            return alloc;
          });
          setEntries([...entries, ...newAllocations]);
          setAddNew(INITIAL_STATE);
          if (res.data && res.data.length > 1) {
            allocationIds.push([res.data.map(allocation => allocation.id)]);
            setAllocationIds(allocationIds);
          }
          if (isTouchDevice) onClose();
        });
      } else {
        dispatch(
          setNotification(notifications.WARNING_YELLOW, 'Error', 'Allocations only allow number of hours from [0.01 - 16]'),
        );
      }
    }
  };

  const removeEntry = entry => {
    deleteTimesheetEntry(entry.id).then(res => {
      setEntries(entries.filter(e => e.id != entry.id));
    });
  };

  const removeAll = idArray => {
    idArray.map(alloc => {
      deleteTimesheetEntry(alloc).then(res => {});
    });
    setEntries([]);
    setAllocationIds([]);
  };

  const handleHoursChange = e => {
    let selectedHours = e.target.value;
    setAddNew({ ...addNew, hours: selectedHours });
  };

  if (!isTouchDevice)
    return (
      <div className="overflow-x-auto md:overflow-x-visible">
        <table className="bg-white min-w-[600px] md:min-w-0 w-full mb-5">
          <tr>
            <th className="text-left px-2 md:px-4 py-4 ">Mission</th>
            <th className="text-left px-2 md:px-4 py-4 ">Date</th>
            <th className={`text-left px-2 md:px-4 py-4 w-24`}>Hours</th>
            <th className={`text-left px-2 md:px-4 py-4`}>Type</th>

            {entries.length ? (
              <th
                className="text-sm text-blue-400 hover:text-blue-500 hover:underline font-normal text-center px-2 md:px-4 py-4 cursor-pointer"
                onClick={() => removeAll(allocationIds)}
              >
                Clear all
              </th>
            ) : (
              ''
            )}
          </tr>

          {entries.length ? (
            entries.map((entry, index) => (
              <tr key={entry.id}>
                <td className="px-2 md:px-4 py-2 text-sm text-gray-500">{entry.mission?.label}</td>
                <td className="px-2 md:px-4 py-2 text-sm text-gray-500">{entry?.date}</td>
                <td className="px-2 md:px-4 py-2 text-sm text-gray-500">{entry.hours}</td>
                <td className="px-2 md:px-4 py-2 text-sm text-gray-500">{allocationTypesToString[entry?.type]}</td>
                <td className="flex justify-center px-2 md:px-4">
                  <TrashIcon
                    className="h-5 w-5 mt-2 cursor-pointer"
                    style={{ color: 'gray' }}
                    onClick={() => removeEntry(entry)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr className="">
              <td className="text-sm text-gray-500 px-4 py-2" colspan="2">
                No allocations added
              </td>
            </tr>
          )}
          <tr className="border-t py-5 ">
            <td className="px-2 md:px-4 py-2" colSpan="2">
              <ReactSelect
                error={errors.find(e => e.field === 'mission')?.msg}
                placeholder="Select mission"
                isSearchable={false}
                options={missionListBoxData}
                selectedOptions={addNew.mission}
                onChange={o => setAddNew({ ...addNew, mission: o })}
              />
            </td>
            <td className="px-2 md:px-4 py-2">
              <InputSimple
                type="number"
                value={addNew.hours}
                error={errors.find(e => e.field === 'hours')?.msg}
                onChange={handleHoursChange}
                min={0}
                step={1}
                max={16}
              />
            </td>
            <td className="px-2 md:px-4 py-2">
              <ReactSelect
                error={errors.find(e => e.field === 'type')?.msg}
                placeholder="Select Type"
                isSearchable={false}
                options={allocationTypesListBoxData}
                selectedOptionsIds={[addNew.type]}
                onChange={o => setAddNew({ ...addNew, type: o.value })}
              />
            </td>
            <td className="px-2 md:px-4 py-2 flex justify-around pt-5">
              {!addNew.mission || addNew.hours < 0.01 ? (
                <PlusCircleIcon className="h-6 w-6 text-gray-400" />
              ) : (
                <PlusCircleIcon onClick={addEntry} className="h-6 w-6 text-indigo-700 cursor-pointer" />
              )}
            </td>
          </tr>
        </table>
      </div>
    );

  if (isTouchDevice)
    return (
      <>
        <div className="flex flex-col space-y-4">
          <ReactSelect
            error={errors.find(e => e.field === 'mission')?.msg}
            placeholder="Select mission"
            isSearchable={false}
            options={missionListBoxData}
            selectedOptions={addNew.mission}
            onChange={o => setAddNew({ ...addNew, mission: o })}
            label="Mission"
          />
          <InputSimple
            type="number"
            value={addNew.hours}
            error={errors.find(e => e.field === 'hours')?.msg}
            onChange={handleHoursChange}
            min={0}
            step={0.5}
            max={16}
            label="Hours worked"
          />
          <ReactSelect
            error={errors.find(e => e.field === 'type')?.msg}
            placeholder="Select Type"
            isSearchable={false}
            options={allocationTypesListBoxData}
            selectedOptionsIds={[addNew.type]}
            onChange={o => setAddNew({ ...addNew, type: o.value })}
            label="Type"
          />
          <div className="flex justify-end space-x-2">
            <ButtonWhite text="Cancel" onClick={onClose} />
            {!addNew.mission || addNew.hours < 0.25 ? (
              <ButtonPrimary text="Add" />
            ) : (
              <ButtonPrimary onClick={addEntry} text="Add" />
            )}
          </div>
        </div>
      </>
    );
}

export default AllocationsTable;
