import React, { useState, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AddTitleBodyAnnouncement from './AddTitleBodyAnnouncement';
import AddViewerAnnouncement from './AddViewerAnnouncement';
import { useHistory } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import useApi from '../../../hooks/useApi';
import { AccountType } from '../../../helpers/enum/accounts';
import contractTypes from '../../../helpers/enum/contractTypeIDs';

const initialState = {
  title: '',
  body: EditorState.createEmpty(),
  countries: [],
  accountTypes: [AccountType.ADMIN, AccountType.STAFF, AccountType.USER],
  contractTypes: [contractTypes.company, contractTypes.employee, contractTypes.freelancer],
  titleErrorMessage: '',
  bodyErrorMessage: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'title':
      const resultTitle = { ...state, title: action.title };
      if (action.title.length > 3) return { ...resultTitle, titleErrorMessage: '' };
      return resultTitle;
    case 'body':
      const resultBody = { ...state, body: action.body };
      if (action.body.length > 3) return { ...resultBody, bodyErrorMessage: '' };
      return resultBody;
    case 'countries':
      return { ...state, countries: action.countries };
    case 'accountTypes':
      return { ...state, accountTypes: action.accountTypes };
    case 'contractTypes':
      return { ...state, contractTypes: action.contractTypes };
    case 'updateAll':
      return { ...state, ...action.payload };
    case 'showTitleError':
      return { ...state, titleErrorMessage: action.payload };
    case 'showTextAreaError':
      return { ...state, bodyErrorMessage: action.payload };
    default:
      throw new Error();
  }
}

const CreateOrEditAnnouncement = props => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const history = useHistory();
  const {
    announcements: { createAnnouncement, getAnnouncement, updateAnnouncement },
  } = useApi();

  const [step, setStep] = useState(1);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);

  const onTitleHandler = e => dispatch({ type: 'title', title: e.target.value });

  const onBodyHandler = value => dispatch({ type: 'body', body: value });

  useEffect(() => {
    if (props.id)
      getAnnouncement(props.id).then(res => {
        if (res.body) {
          var convertedJSON = JSON.parse(res.body);
        }
        dispatch({
          type: 'updateAll',
          payload: {
            title: res.title,
            body: EditorState.createWithContent(convertFromRaw(convertedJSON)),
            accountTypes: res.account_types,
            contractTypes: res.contract_types,
            countries: res.countries,
          },
        });
      });
  }, [props.id]);

  const onCountriesChange = selectedItems => {
    let countriesIds;
    if (!selectedItems) countriesIds = [];
    else countriesIds = selectedItems.map(item => item.value);
    dispatch({ type: 'countries', countries: countriesIds });
  };

  const onContractTypesChange = event => {
    let acceptedContractTypes = state.contractTypes;
    if (event.target.id == 'employee') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.employee)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.employee));
    }
    if (event.target.id == 'freelancer') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.freelancer)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.freelancer));
    }
    if (event.target.id == 'company') {
      event.target.checked
        ? acceptedContractTypes.push(contractTypes.company)
        : (acceptedContractTypes = acceptedContractTypes.filter(id => id != contractTypes.company));
    }
    dispatch({ type: 'contractTypes', contractTypes: acceptedContractTypes });
  };

  const onAccountTypesChange = event => {
    let acceptedAccountTypes = state.accountTypes;
    if (event.target.id == 'user') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.USER)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.USER));
    }
    if (event.target.id == 'staff') {
      event.target.checked
        ? acceptedAccountTypes.push(AccountType.STAFF)
        : (acceptedAccountTypes = acceptedAccountTypes.filter(id => id != AccountType.STAFF));
    }
    dispatch({ type: 'accountTypes', accountTypes: acceptedAccountTypes });
  };

  const onNextHandler = () => {
    const bodyJSON = state.body.getCurrentContent().getPlainText(' ');

    if (state.title.length < 3 || state.title.length > 150)
      dispatch({ type: 'showTitleError', payload: 'Title must be Greater than 3 characters and Less thant 150 characters' });
    else if (bodyJSON && bodyJSON.length < 10)
      dispatch({ type: 'showTextAreaError', payload: 'Text must be Greater than 10 characters' });
    else setStep(2);
  };

  const onSuccessHandler = () => {
    if (props.id) onUpdateAnnouncement();
    else onCreateAnnouncement();
  };

  const onCreateAnnouncement = () => {
    var rawContentState = convertToRaw(state.body.getCurrentContent());
    var bodyJSON = JSON.stringify(rawContentState);

    const sendData = {
      title: state.title,
      body: bodyJSON,
      countries: state.countries,
      account_types: state.accountTypes,
      contract_types: state.contractTypes,
    };

    createAnnouncement(sendData).then(res => history.goBack());
  };

  const onUpdateAnnouncement = () => {
    var rawContentState = convertToRaw(state.body.getCurrentContent());
    var bodyJSON = JSON.stringify(rawContentState);
    const sendData = {
      title: state.title,
      body: bodyJSON,
      countries: state.countries,
      account_types: state.accountTypes,
      contract_types: state.contractTypes,
    };

    updateAnnouncement(props.id, sendData).then(res => history.goBack());
  };

  const onCancelHandler = () => {
    history.goBack();
  };

  const successButtonName = props.id ? 'Update' : 'Create';

  const announcementElement =
    step == 1 ? (
      <AddTitleBodyAnnouncement
        onCancelHandler={onCancelHandler}
        onSuccessHandler={onNextHandler}
        title={state.title}
        body={state.body}
        onTitleChange={onTitleHandler}
        onBodyChange={onBodyHandler}
        inputErrorMessage={state.titleErrorMessage}
        textAreaErrorMessage={state.bodyErrorMessage}
      />
    ) : (
      <AddViewerAnnouncement
        successButtonName={successButtonName}
        onCancelHandler={onCancelHandler}
        acceptedContractTypes={state.contractTypes}
        acceptedAccountTypes={state.accountTypes}
        countriesDefaultId={state.countries}
        onSuccessHandler={onSuccessHandler}
        onCountriesChange={onCountriesChange}
        onContractTypesChange={onContractTypesChange}
        onAccountTypesChange={onAccountTypesChange}
        countriesPlaceholder="All countries"
        isAdmin={currentUser.account_type_id == AccountType.ADMIN}
        disabledCheckboxes={disabledCheckboxes}
      />
    );

  return announcementElement;
};

export default CreateOrEditAnnouncement;

CreateOrEditAnnouncement.prototype = {
  id: PropTypes.number,
};
