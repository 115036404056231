import React, { useState, useEffect, useMemo } from 'react';
import Modal from '../../../components/Modals/Modal';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';
import useApi from '../../../hooks/useApi';
import Loader from '../../../components/Loading/Loader';
import formatCurrency from '../../../utils/formatCurrency';
import { TimeSheetStatusLabels } from '../../../helpers/enum/timeSheet';
import InputSimple from '../../../components/Inputs/InputSimple';
import Error from '../../../components/Error/Error';
import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import PaginatedTimesheetEntries from './PaginatedTimesheetEntries';

dayjs.extend(advancedFormat);

function AddLineItemModal({
  setLineItems,
  showAddLineItemModal,
  setShowAddLineItemModal,
  clientId,
  selectedLineItemIndex,
  lineItems,
  customCreditNote,
}) {
  const {
    accountsReceivables: { getContractAssociations },
    frameworkContracts: { getClientFrameworkContracts },
  } = useApi();

  const [isLoadingContractAssociations, setIsLoadingContractAssociations] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [showCustomReferenceInput, setShowCustomReferenceInput] = useState(false);
  const [timesheetEntries, setTimesheetEntries] = useState([]);

  //return true even if index is 0;
  const _selectedLineItemIndex = selectedLineItemIndex === 0 || selectedLineItemIndex;

  const [selectOptions, setSelectOptions] = useState({
    users: [],
    staffOrders: [],
    salesOrders: [],
    specificContracts: [],
    partnerContracts: [],
    frameworkContracts: [],
    salesContracts: [],
  });

  const [newLine, setNewLine] = useState({
    description: null,
    type: null,
    staff_order_id: null,
    framework_contract_id: null,
    partner_contract_id: null,
    specific_contract_id: null,
    sales_order_id: null,
    timesheet_id: null,
    units: null,
    uom: null,
    price: null,
    vat_rate: null,
    line_item_id: null,
    user_id: null,
    metadata: {
      fwc_ref: null,
      partner_ref: null,
      specific_contract_ref: null,
      sales_order_ref: null,
      custom_reference: null,
      period: null,
    },
  });

  const totals = useMemo(() => {
    let creditedDays = 0;
    let revenue = 0;
    let vatRate = 0;
    let vatOwed = 0;

    if (newLine?.type === lineItemTypesV2.creditNotes.numbers.projectAllocations) {
      let item = newLine;
      let sumOfCreditedHours = timesheetEntries.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.creditedHours;
      }, 0);

      const roundNumber = nb => Math.round(nb * 100) / 100;

      creditedDays = roundNumber(sumOfCreditedHours / 8);

      revenue = creditedDays * Number(item.price);
      vatRate = item.vat_rate;
      vatOwed = revenue * Number(vatRate);
    }

    return {
      creditedDays,
      revenue,
      vatRate,
      vatOwed,
    };
  }, [timesheetEntries, newLine]);

  const fetchContractAssociations = async updatedNewLine => {
    if (!clientId) return;
    setIsLoadingContractAssociations(true);
    setFormErrors([]);
    const query = {
      frameworkContractId: updatedNewLine.framework_contract_id,
      partnerContractId: updatedNewLine.partner_contract_id,
      specificContractId: updatedNewLine.specific_contract_id,
      salesOrderId: updatedNewLine.sales_order_id,
      userId: updatedNewLine.user_id,
      staffOrderId: updatedNewLine.staff_order_id,
      clientId,
    };

    getContractAssociations(query)
      .then(data => {
        let _formatted = {};
        const labelKeys = {
          frameworkContracts: 'contract_ref',
          salesOrders: 'contract_ref',
          specificContracts: 'contract_ref',
          staffOrders: 'order_ref',
          users: 'full_name',
        };

        Object.keys(data).forEach(key => {
          //format array for ReactSelect component
          const arrayBeingChecked = data[key];
          _formatted[key] = arrayBeingChecked.map(el => ({ label: el[labelKeys[key]], value: el.id, ...el }));
        });
        let _salesContracts = [];
        if (!selectOptions?.salesContracts.length) {
          //fetching initial selection of sales contracts, after this, they dont change
          getClientFrameworkContracts(clientId).then(data => {
            data.forEach(el => {
              let _contract = { ...el, value: el.id, label: el.contract_ref };
              return _salesContracts.push(_contract);
            });
          });
        } else {
          _salesContracts = selectOptions.salesContracts;
        }
        _formatted['salesContracts'] = _salesContracts;
        setSelectOptions(_formatted);
        if (updatedNewLine.type === lineItemTypesV2.creditNotes.numbers.projectAllocations) {
          if (updatedNewLine.timesheetEntries) {
            setTimesheetEntries(updatedNewLine.timesheetEntries);
          }
        }

        setTimeout(() => setIsLoadingContractAssociations(false), 500);
      })

      .catch(() => setTimeout(() => setIsLoadingContractAssociations(false), 500));
    //any change in references resets the selected invoicing item
    let updatedBody = { ...updatedNewLine };
    if (updatedBody.metadata.custom_reference) setShowCustomReferenceInput(true);
    setNewLine(updatedBody);
  };

  useEffect(() => {
    if (showAddLineItemModal) {
      let foundLine = lineItems.find((l, i) => i === selectedLineItemIndex);
      if (foundLine) fetchContractAssociations(foundLine);
      else resetForm();
    }
  }, [selectedLineItemIndex, showAddLineItemModal]);

  const addLine = () => {
    let errors = [];

    if (!newLine.type) {
      errors.push({ field: 'type', msg: 'Required' });
    } else if (!newLine.partner_contract_id) {
      //partner contract is minimal requirement
      errors.push({ field: 'partnerContract', msg: 'Required' });
    }

    if (timesheetEntries.length) {
      newLine.timesheetEntries = timesheetEntries;
      newLine.totals = totals;
    }
    if (errors.length) return setFormErrors(errors);
    //select missing references on mission placement if there was only one option possible
    let dataToAdd = { ...newLine };
    if (selectOptions.users.length === 1) dataToAdd.user_id = selectOptions.users[0].value;
    if (selectOptions.staffOrders.length === 1) dataToAdd.staff_order_id = selectOptions.staffOrders[0].value;
    if (selectOptions.salesOrders.length === 1) {
      dataToAdd.sales_order_id = selectOptions.salesOrders[0].value;
      dataToAdd.metadata.sales_order_ref = selectOptions.salesOrders[0].label;
    }
    if (selectOptions.specificContracts.length === 1) {
      dataToAdd.specific_contract_id = selectOptions.specificContracts[0].value;
      dataToAdd.metadata.specific_contract_ref = selectOptions.salesOrders[0].label;
    }
    if (selectOptions.frameworkContracts.length === 1) {
      dataToAdd.framework_contract_id = selectOptions.frameworkContracts[0].value;
      dataToAdd.metadata.fwc_ref = selectOptions.frameworkContracts[0].label;
    }

    setFormErrors([]);
    _selectedLineItemIndex
      ? setLineItems(prev => prev.map((el, i) => (i === selectedLineItemIndex ? dataToAdd : el)))
      : setLineItems(prev => [...prev, dataToAdd]);
    handleHide();
  };

  const handleHide = () => {
    setShowAddLineItemModal(false);
    setTimeout(resetForm, 500);
  };

  const resetForm = () => {
    setNewLine({
      description: null,
      type: null,
      staff_order_id: null,
      framework_contract_id: null,
      partner_contract_id: null,
      specific_contract_id: null,
      sales_order_id: null,
      timesheet_id: null,
      units: null,
      uom: null,
      price: null,
      vat_rate: null,
      line_item_id: null,
      user_id: null,
      metadata: {
        fwc_ref: null,
        partner_ref: null,
        specific_contract_ref: null,
        sales_order_ref: null,
        custom_reference: null,
        period: null,
      },
    });
    setShowCustomReferenceInput(false);
    setFormErrors([]);
  };

  const handleLineItemTypeChange = async el => {
    let updated = {
      description: null,
      units: null,
      uom: null,
      price: null,
      vat_rate: null,
      staff_order_id: null,
      framework_contract_id: null,
      partner_contract_id: null,
      specific_contract_id: null,
      sales_order_id: null,
      timesheet_id: null,
      line_item_id: null,
      user_id: null,
      type: el.value,
      metadata: {
        sales_order_ref: null,
        fwc_ref: null,
        specific_contract_ref: null,
        partner_ref: null,
        period: null,
        custom_reference: null,
      },
    };
    if (el.value !== lineItemTypesV2.creditNotes.numbers.projectAllocations) {
      updated.description = lineItemTypesV2.creditNotes.strings[el.value];
    }

    await fetchContractAssociations(updated);
  };

  const handleToggleCustomReference = () => {
    setNewLine(prev => ({ ...prev, metadata: { ...prev.metadata, custom_reference: null } }));
    setShowCustomReferenceInput(!showCustomReferenceInput);
  };

  const handleSelectSalesContract = async el => {
    //logic here is selecting a final sales contract, and it can be either an endClientFWC (in case of a direct placement) or a partnerContract (in case of a placement through a subco)
    //either way, the option selected (either endClientContract or partnerContract) would be the sales contract of the mission/placement, so it would be considered a partner_contract_id
    //since we are storing in the staffOrder/purchaseOrder model the sales contract as partner_contract_id. It would be perhaps worth it to review the nomenclature of the contract references
    //to reduce ambiguity
    let updated = {
      description: newLine.description,
      units: null,
      uom: null,
      price: null,
      vat_rate: null,
      staff_order_id: null,
      framework_contract_id: null,
      partner_contract_id: el?.value || null,
      bank_account_id: el?.bank_account_id || null,
      specific_contract_id: null,
      sales_order_id: null,
      timesheet_id: null,
      user_id: null,
      type: newLine.type,
      metadata: {
        sales_order_ref: null,
        fwc_ref: null,
        specific_contract_ref: null,
        partner_ref: el?.label || null,
        period: null,
        custom_reference: null,
      },
    };
    await fetchContractAssociations(updated);
  };

  return (
    <Modal size="lg" show={showAddLineItemModal} hide={handleHide} title="Add line item">
      <label className="block text-md font-medium text-gray-700 mr-2 mt-4 mb-2">Select line item type</label>
      <ReactSelect
        options={lineItemTypesV2.creditNotes.reactSelectOptions.filter(
          o => o.value !== lineItemTypesV2.creditNotes.numbers.projectAllocations,
        )}
        orderOptions={false}
        disabled={customCreditNote ? false : true}
        error={formErrors.find(e => e.field === 'type')?.msg}
        selectedOptions={[lineItemTypesV2.creditNotes.reactSelectOptions.find(el => el.value === newLine.type)]}
        onChange={handleLineItemTypeChange}
      />
      <div className={newLine.type ? 'block' : 'hidden'}>
        <label className="block text-md font-medium text-gray-700 mr-2 mt-4 mb-2">Select sales contract</label>
        <ReactSelect
          isSearchable
          isClearable
          error={formErrors.find(e => e.field === 'partnerContract')?.msg}
          options={selectOptions.salesContracts}
          disabled={customCreditNote ? false : true}
          selectedOptionsIds={[newLine.partner_contract_id]}
          onChange={handleSelectSalesContract}
        />
      </div>
      <div className={newLine.partner_contract_id ? 'block' : 'hidden'}>
        <div className="flex mt-4 mb-2 items-center justify-between">
          <div className="flex items-center">
            <label className="block text-md font-medium text-gray-700">Select mission references</label>
          </div>
        </div>
        <Loader className={'flex justify-around p-11 rounded-lg'} isLoading={isLoadingContractAssociations}>
          <div className="w-full mb-2">
            <div className="grid grid-cols-3 gap-3 relative">
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'user')?.msg}
                  label="User"
                  options={selectOptions.users}
                  selectedOptions={[selectOptions.users.find(u => u.value === newLine.user_id)]}
                  disabled={customCreditNote ? false : true}
                  onChange={async el => {
                    let updatedNewLine = { ...newLine, user_id: el?.value || null };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                />
              </div>
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  disabled={customCreditNote ? false : true}
                  error={formErrors.find(e => e.field === 'staffOrder')?.msg}
                  label={
                    newLine.type === lineItemTypesV2.creditNotes.numbers.projectAllocations ? 'Purchase order*' : 'Purchase order'
                  }
                  options={selectOptions.staffOrders}
                  selectedOptions={[selectOptions.staffOrders.find(u => u.value === newLine.staff_order_id)]}
                  onChange={async el => {
                    let updatedNewLine = { ...newLine, staff_order_id: el?.value || null };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                />
              </div>
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  error={formErrors.find(e => e.field === 'salesOrder')?.msg}
                  disabled={customCreditNote ? false : true}
                  label={'Sales order'}
                  options={selectOptions.salesOrders}
                  selectedOptions={[selectOptions.salesOrders.find(u => u.value === newLine.sales_order_id)]}
                  onChange={async el => {
                    let updatedNewLine = {
                      ...newLine,
                      sales_order_id: el?.value || null,
                      metadata: {
                        ...newLine.metadata,
                        sales_order_ref: el?.label || null,
                      },
                    };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                />
              </div>
              <div className="col-span-1">
                <ReactSelect
                  isSearchable
                  isClearable
                  disabled={customCreditNote ? false : true}
                  error={formErrors.find(e => e.field === 'specificContract')?.msg}
                  label={'Specific contract'}
                  options={selectOptions.specificContracts}
                  selectedOptions={[selectOptions.specificContracts.find(u => u.value === newLine.specific_contract_id)]}
                  onChange={async el => {
                    let updatedNewLine = {
                      ...newLine,
                      specific_contract_id: el?.value || null,
                      metadata: {
                        ...newLine.metadata,
                        specific_contract_ref: el?.label || null,
                      },
                    };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                />
              </div>
              <div className={`col-span-1`}>
                <ReactSelect
                  isSearchable
                  isClearable
                  disabled={customCreditNote ? false : true}
                  error={formErrors.find(e => e.field === 'frameworkContract')?.msg}
                  label={'End client FWC'}
                  options={selectOptions.frameworkContracts}
                  selectedOptions={[selectOptions.frameworkContracts.find(u => u.value === newLine.framework_contract_id)]}
                  onChange={async el => {
                    let updatedNewLine = {
                      ...newLine,
                      framework_contract_id: el?.value || null,
                      metadata: { ...newLine.metadata, fwc_ref: el?.label || null },
                    };
                    await fetchContractAssociations(updatedNewLine);
                  }}
                />
              </div>
              {showCustomReferenceInput ? (
                <div className={`col-span-2 col-start-1`}>
                  <InputSimple
                    error={formErrors.find(e => e.field === 'customReference')?.msg}
                    label={
                      <div className="w-full flex justify-between">
                        <span>Custom reference</span>
                        <span
                          className="underline  text-gray-500 ml-2 cursor-pointer"
                          onClick={handleToggleCustomReference}
                          style={{ fontSize: '0.7rem' }}
                        >
                          remove custom reference
                        </span>
                      </div>
                    }
                    value={newLine.metadata.custom_reference}
                    onChange={e => {
                      setNewLine(prev => ({ ...prev, metadata: { ...prev.metadata, custom_reference: e.target.value } }));
                    }}
                  />
                </div>
              ) : (
                <span
                  className="underline  text-gray-500 ml-2 cursor-pointer col-start-1"
                  onClick={handleToggleCustomReference}
                  style={{ fontSize: '0.7rem' }}
                >
                  add custom reference
                </span>
              )}
            </div>
          </div>
        </Loader>
      </div>
      {newLine.type === lineItemTypesV2.creditNotes.numbers.projectAllocations && (
        <>
          <div>
            <div className="flex">
              <label className="block text-md font-medium text-gray-700 mr-2 mt-4 mb-2">Adjusted invoicing item</label>
              {!!formErrors.find(e => e.field === 'invoicingLine') && (
                <Error message={formErrors.find(e => e.field === 'invoicingLine').msg} />
              )}
            </div>
            <Loader className={'flex justify-around p-11 rounded-lg'}>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Period
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Credited days
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Daily rate
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Credited revenue
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          VAT rate
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          VAT owed
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      <tr className={'bg-blue-50'}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{newLine.user.full_name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{newLine.metadata.period}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {TimeSheetStatusLabels[newLine?.timesheet?.status]}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {totals.creditedDays}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {formatCurrency(newLine.price)}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {formatCurrency(totals.revenue)}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {totals.vatRate || '-'}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {formatCurrency(totals.vatOwed) || '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <PaginatedTimesheetEntries setTimesheetEntries={setTimesheetEntries} timesheetEntries={timesheetEntries} />
                </div>
              </div>
            </Loader>
          </div>
        </>
      )}

      <div className="mt-6 text-right">
        <ButtonPrimary
          text={_selectedLineItemIndex ? 'Update line' : 'Add line'}
          disable={
            newLine.type === lineItemTypesV2.creditNotes.numbers.projectAllocations ? !totals.creditedDays : !customCreditNote
          }
          onClick={addLine}
        />
      </div>
    </Modal>
  );
}

export default AddLineItemModal;
