import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import EditSquare from '../../components/Icons/EditSquareIcon';
import 'react-datepicker/dist/react-datepicker.css';
import { ACCOUNT_TYPE } from '../../helpers/enum/accounts';
import ButtonSimple from '../../components/Buttons/ButtonWhite';
import UserImagePlaceholder from '../../components/UserImagePlaceholder/UserImagePlaceholder';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import TabItem from '../../components/Navigation/TabItem';
import UserProfile from './UserInfoSections/UserProfile';
import UserStaffContracts from './UserInfoSections/UserStaffContracts';
import UserMissions from './UserInfoSections/UserMissions';
import UserLeaveBalance from './UserInfoSections/UserLeaveBalance';
import UserDocuments from './UserInfoSections/UserDocuments';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import './UserInfo.css';
import ReactSelect from '../../components/Inputs/ReactSelect';
import DividerSimple from '../../components/Dividers/DividerSimple';
import contractTypes from '../../helpers/enum/contractTypeIDs';
function ProfileSection({ userContracts, userStaffOrders, user, isActive, activateUser, deleteUser, undoDeleteUser }) {
  const [updateLeaveBalance, setUpdateLeaveBalance] = useState(false);
  const [refreshLeaveBalance, setRefreshLeaveBalance] = useState(0);

  const location = useLocation();
  const today = new Date();

  const initialUrl = `/admin-panel/hr/user-directory/${user.id}`;

  const [selectedTab, _setSelectedTab] = useState({
    profile: true,
    staffContracts: false,
    missions: false,
    leaveBalance: false,
    documents: false,
  });

  const [selectedContract, setSelectedContract] = useState();

  const setSelectedTab = newVal =>
    _setSelectedTab({
      profile: false,
      staffContracts: false,
      missions: false,
      leaveBalance: false,
      documents: false,
      ...newVal,
    });

  const state = useSelector(state => state);

  const history = useHistory();

  const userCanEdit =
    ACCOUNT_TYPE.ADMIN.id === state.auth.currentUser.account_type_id ||
    ACCOUNT_TYPE.ROOT.id === state.auth.currentUser.account_type_id;

  const handleEditProfileClick = () => {
    history.push(`/admin-panel/hr/user-directory/update/${user.id}/`);
  };

  const handleUpdateLeaveBalanceClick = () => {
    setUpdateLeaveBalance(true);
  };

  const handelUndoDeleteUser = () => undoDeleteUser(user.id);

  const handleDeleteClick = () => deleteUser(user.id);

  const handleActivateClick = () => activateUser(user.id);

  const shouldAppearProfileEditButton = selectedTab.profile && !user.deleted_at && user.active;
  const shouldAppearUndoDeleteButton = selectedTab.profile && user.deleted_at;
  const shouldAppearActiveButton = selectedTab.profile && !user.active && !user.deleted_at;
  const shouldAppearDeleteButton = selectedTab.profile && !user.active && !user.deleted_at;
  const shouldAppearUpdateLeaveBalance = selectedTab.leaveBalance && selectedContract?.contract_type_id == contractTypes.employee;

  const footerHeight = document.getElementById('footer')?.offsetHeight;
  const headerHeight = document.getElementById('header')?.offsetHeight;
  const profileHeaderHeight = document.getElementById('profile-header')?.offsetHeight;

  const profileClickHandler = () => {
    history.push(`${initialUrl}/profile`);
  };
  const staffContractsClickHandler = () => {
    history.push(`${initialUrl}/staff-contracts`);
  };
  const missionsClickHandler = () => {
    history.push(`${initialUrl}/missions`);
  };
  const leaveBalanceClickHandler = () => {
    history.push(`${initialUrl}/leave-balance`);
  };
  const documentsClickHandler = () => {
    history.push(`${initialUrl}/documents`);
  };

  useEffect(() => {
    const pathNameArray = location.pathname.split('/');
    const userIsSelected = pathNameArray.length > 4;
    const tabSelected = pathNameArray[5];
    if (userIsSelected) {
      switch (tabSelected) {
        case 'staff-contracts':
          setSelectedTab({ staffContracts: true });
          break;
        case 'missions':
          setSelectedTab({ missions: true });
          break;
        case 'leave-balance':
          setSelectedTab({ leaveBalance: true });
          break;
        case 'documents':
          setSelectedTab({ documents: true });
          break;
        case undefined:
          setSelectedTab({ profile: true });
          break;
        default:
          setSelectedTab({ profile: true });
          break;
      }
    }
  }, [location.pathname]);

  let _100vh = Math.round(window.innerHeight);
  let minWindowHeight = 850;
  let fullScreenHeight = _100vh > minWindowHeight ? _100vh : minWindowHeight;
  const STYLES = {
    height: `calc(${fullScreenHeight}px - ${footerHeight + headerHeight + profileHeaderHeight}px)`,
  };

  const contractListBoxData = useMemo(() => {
    let _contractListBoxData = [];
    if (!userContracts.length) return;
    const activeContract = userContracts.find(contract => {
      return (
        new Date(contract.contract_start) <= today && (contract.contract_end == null || new Date(contract.contract_end) >= today)
      );
    });
    if (!activeContract) {
      setSelectedContract({ label: 'No active contract', value: undefined });
    } else {
      setSelectedContract({ label: `${activeContract.contract_ref} (active)`, value: activeContract.id, ...activeContract });
    }

    userContracts.forEach(e => {
      const label = e.id == activeContract?.id ? `${e.contract_ref} (active)` : `${e.contract_ref} (not active)`;
      _contractListBoxData.push({ ...e, label: label, value: e.id });
    });
    return _contractListBoxData;
  }, [userContracts]);

  let isNotEmployeeContract;

  if (userContracts.length && selectedContract && selectedContract?.value) {
    isNotEmployeeContract =
      userContracts?.find(contract => {
        return contract.id == selectedContract?.value;
      })?.contract_type_id != contractTypes.employee;
  }
  const userLeaveBalance = userContracts.length ? (
    <div className="px-8">
      <div className="sticky top-0 w-full bg-white pt-4">
        <div className="w-56">
          <ReactSelect
            label={'Select contract'}
            labelClass="text-gray-500"
            selectedOptions={selectedContract}
            onChange={setSelectedContract}
            options={contractListBoxData}
          />
        </div>
        <DividerSimple />
      </div>
      {isNotEmployeeContract ? (
        <div className="text-sm text-gray-500 mt-6">Freelance/company contracts are not eligible for paid leave.</div>
      ) : selectedContract && selectedContract.value ? (
        <UserLeaveBalance
          userId={user.id}
          setUpdateLeaveBalance={setUpdateLeaveBalance}
          updateLeaveBalance={updateLeaveBalance}
          refresh={refreshLeaveBalance}
          setRefresh={setRefreshLeaveBalance}
          selectedStaffContract={selectedContract}
        />
      ) : (
        <div className="text-sm text-gray-500 mt-6 px-8">
          Please select a contract to display corresponding leave day balance.
        </div>
      )}
    </div>
  ) : (
    <div className="text-sm text-gray-500 mt-6 px-8">There are currently no staff contracts for this user.</div>
  );

  return (
    <>
      {/* <!-- Profile header --> */}
      <div
        className="flex flex-col  space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between  sm:flex sm:space-x-5 px-2 sm:px-4 xl:px-8 py-[2.13rem] bg-banner-pattern"
        id="profile-header"
      >
        <div className="flex items-center space-x-4 ">
          <ProfilePicture
            className="h-20 w-20 rounded-full ring-4 ring-white sm:h-20 sm:w-20"
            src={user.profilepic_url}
            alt=""
            defaultimage={<UserImagePlaceholder className="h-20 w-20 rounded-full ring-4 ring-white sm:h-20 sm:w-20" />}
          />
          <span className="text-2xl  mt-4 sm:mt-0 font-bold text-gray-900 truncate">{user.full_name}</span>
        </div>
        <div className="flex flex-col mt-auto justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
          {user.account_type_id && userCanEdit && (
            <>
              {shouldAppearProfileEditButton && (
                <ButtonSimple icon={<EditSquare />} onClick={handleEditProfileClick} text={'Edit'} />
              )}
              {shouldAppearUndoDeleteButton && <ButtonPrimary accept text="Activate" onClick={handelUndoDeleteUser} />}
              {shouldAppearActiveButton && <ButtonPrimary accept onClick={handleActivateClick} style="mr-2" text="Activate" />}
              {shouldAppearDeleteButton && (
                <ButtonPrimary canDelete onClick={handleDeleteClick} style="mr-2" text="Delete User" />
              )}
              {shouldAppearUpdateLeaveBalance && (
                <ButtonSimple icon={<EditSquare />} onClick={handleUpdateLeaveBalanceClick} text={'Update'} />
              )}
            </>
          )}
        </div>
      </div>
      <div className={`${!isActive && 'hidden'} bg-white flex flex-col`} style={STYLES}>
        {/* <!-- Tabs --> */}
        <div aria-label="Tabs" className="flex flex-nowrap space-x-2 sm:space-x-8 border-b pt-8 px-8">
          <TabItem label={'Profile'} onClick={() => profileClickHandler()} isActive={selectedTab.profile} />
          <TabItem label={'Staff Contracts'} onClick={() => staffContractsClickHandler()} isActive={selectedTab.staffContracts} />
          <TabItem label={'Missions'} onClick={() => missionsClickHandler()} isActive={selectedTab.missions} />
          <TabItem label={'Leave balance'} onClick={() => leaveBalanceClickHandler()} isActive={selectedTab.leaveBalance} />
          <TabItem label="Documents" onClick={() => documentsClickHandler()} isActive={selectedTab.documents} />
        </div>
        {/* <!-- Content --> */}
        <div className={`overflow-y-auto flex-grow `}>
          {selectedTab.profile && <UserProfile user={user} />}
          {selectedTab.staffContracts && <UserStaffContracts userContracts={userContracts} />}
          {selectedTab.missions && <UserMissions staffOrders={userStaffOrders} />}
          {selectedTab.leaveBalance && userLeaveBalance}
          {selectedTab.documents && <UserDocuments userId={user.id} userName={user.full_name} />}
        </div>
      </div>
    </>
  );
}

export default ProfileSection;
