import React, { useEffect, useState, useMemo } from 'react';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import InputSimple from '../../../components/Inputs/InputSimple';
import { staffContractType } from '../../../helpers/enum/staffContractType';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import Checkbox from '../../../components/Checkbox/Checkbox';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import { AccountType } from '../../../helpers/enum/accounts';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { date } from '../../../helpers/date';
import useApi from '../../../hooks/useApi';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import * as contractTypes from '../../../helpers/enum/contractTypeIDs';

const timesheetListboxData = [
  {
    label: 'Manual',
    value: 'Manual',
  },
  {
    label: 'Auto',
    value: 'Auto',
  },
];

function AddPurchaseOrderAndUser({
  purchaseOrder,
  setPurchaseOrder,
  setStaffContract,
  user,
  setUser,
  staffContract,
  formErrors,
  openEnded,
  setOpenEnded,
  contacts,
  fetchAllContacts,
}) {
  const [users, setUsers] = useState([]);
  const [userStaffContracts, setUserStaffContracts] = useState([]);
  const {
    staffContracts: { getUserStaffContracts },
    users: { getAllUsers },
  } = useApi();

  const fetchAllUsers = () => {
    getAllUsers().then(data => {
      let array = [];
      data
        .filter(user => user.accountType && [AccountType.ADMIN, AccountType.STAFF, AccountType.USER].includes(user.accountTypeId))
        .forEach(user => {
          array.push({ value: user.id, label: user.full_name, ...user });
        });
      setUsers(array);
    });
  };

  const fetchUserStaffContracts = () => {
    if (!user || user.value === 0) return;
    getUserStaffContracts(user.value).then(data => {
      let formattedData = data.length
        ? data.map(el => ({ value: el.id, label: el.contract_ref, ...el }))
        : [{ value: 0, label: 'This user has no staff contracts' }];
      setUserStaffContracts(formattedData);
      setStaffContract(formattedData[0]);
    });
  };

  useEffect(() => {
    fetchAllContacts();
    fetchAllUsers();
  }, []);

  //get consultant's staff contracts
  useEffect(() => {
    fetchUserStaffContracts();
  }, [user]);

  useEffect(() => {
    if (staffContract?.contract_type_id === staffContractType.EMPLOYEE) {
      setPurchaseOrder(prev => ({ ...prev, daily_rate: '' }));
    }
  }, [staffContract]);

  //point of contacts
  const contactsListboxData = useMemo(() => {
    let array = [];
    if (contacts.length) {
      contacts.map(type => {
        array.push({
          value: type.id,
          label: type.first_name + ' ' + type.last_name,
        });
      });
    }
    return array;
  }, [contacts]);

  //change handler
  const handlePurchaseOrderChange = (e, label) => {
    setPurchaseOrder(prev => ({ ...prev, [label]: e?.target ? e?.target?.value || '' : e }));
  };

  return (
    <TwoColumnForm label="Purchase order and consultant" description={'Add info for new purchase order to be created.'}>
      <DividerWithLabel label="Consultant" />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="flex flex-col">
          <div className="flex items-end">
            <ReactSelect
              isSearchable
              error={formErrors.find(e => e.field === 'user')?.msg}
              label="Consultant"
              bold
              options={users}
              selectedOptions={user}
              onChange={setUser}
            />
            <RefreshButton onClick={() => fetchAllUsers()} />
          </div>
          <NewTabOpener link={'/admin-panel/hr/user-directory/create/'} title="Add new user" />
        </div>
        <div className="flex flex-col">
          <div className="flex items-end">
            <ReactSelect
              label="Staff contract"
              error={formErrors.find(e => e.field === 'staffContract')?.msg}
              options={userStaffContracts}
              selectedOptions={staffContract}
              onChange={setStaffContract}
            />
            <RefreshButton onClick={() => fetchUserStaffContracts()} />
          </div>
          <div className="flex gap-x-2">
            {user.id !== 0 && (
              <>
                <NewTabOpener link={'/admin-panel/contracts/staff-contracts/create'} title="Add new staff contract" />
                {staffContract.id !== 0 && (
                  <NewTabOpener link={`/admin-panel/contracts/staff-contracts/${staffContract.id}`} title="Edit staff contract" />
                )}
              </>
            )}
          </div>
        </div>
        {staffContract.id !== 0 && (
          <>
            <SimpleEntry
              label={'Contract type'}
              data={staffContract?.contractType?.contract_type || '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={`${
                staffContract?.value !== 0 && staffContract?.contract_type_id !== contractTypes.employee
                  ? 'Billing country'
                  : 'Country'
              }`}
              data={staffContract?.country?.name || '-'}
              style={`sm:col-span-1`}
            />
            {staffContract?.value !== 0 && staffContract?.contract_type_id !== contractTypes.employee && (
              <SimpleEntry
                label={'Holiday calendar'}
                data={staffContract?.holiday_calendar?.name || '-'}
                style={`sm:col-span-1`}
              />
            )}
            <SimpleEntry label={'Position'} data={staffContract?.position || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Start date'} data={date(staffContract?.contract_start || '-')} style={`sm:col-span-1`} />
            <SimpleEntry label={'End date'} data={date(staffContract?.contract_end || '-')} style={`sm:col-span-1`} />
          </>
        )}
      </dl>
      <DividerWithLabel label="Purchase order" />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <InputSimple
          label={'Reference*'}
          error={formErrors.find(e => e.field === 'purchaseOrder-order_ref')?.msg}
          dataLabel={'order_ref'}
          onChange={handlePurchaseOrderChange}
          value={purchaseOrder.order_ref}
        />
        <InputDate
          label={'Start Date*'}
          onChange={value => handlePurchaseOrderChange(value, 'order_start')}
          error={formErrors.find(e => e.field === 'purchaseOrder-order_start')?.msg}
          selected={purchaseOrder.order_start && new Date(purchaseOrder.order_start)}
        />
        <div className="flex">
          <InputDate
            disabled={openEnded}
            label={'End Date*'}
            error={formErrors.find(e => e.field === 'purchaseOrder-order_end')?.msg}
            onChange={value => handlePurchaseOrderChange(value, 'order_end')}
            selected={purchaseOrder.order_end && new Date(purchaseOrder.order_end)}
          />
          <Checkbox className="ml-4" value={openEnded} onChange={e => setOpenEnded(e.target.checked)} title="Open Ended" />
        </div>

        <InputSimple
          type="number"
          label={'Cost (€/day)*'}
          error={formErrors.find(e => e.field === 'purchaseOrder-daily_rate')?.msg}
          dataLabel={'daily_rate'}
          value={purchaseOrder.daily_rate}
          onChange={handlePurchaseOrderChange}
        />

        <InputSimple
          type="number"
          label={'Days ordered*'}
          error={formErrors.find(e => e.field === 'purchaseOrder-days_ordered')?.msg}
          dataLabel={'days_ordered'}
          onChange={handlePurchaseOrderChange}
          step=".5"
          placeholder="FTE"
          // value={(purchaseOrder.days_ordered * 1.5).toFixed(2).replace(/[.,]00$|0$/, '')}
          value={purchaseOrder.days_ordered}
        />
        <ReactSelect
          label={'Timesheet'}
          placeholder="Select timesheet"
          error={formErrors.find(e => e.field === 'purchaseOrder-timesheet_type')?.msg}
          options={timesheetListboxData}
          selectedOptionsIds={[purchaseOrder.timesheet_type]}
          onChange={el => handlePurchaseOrderChange(el.value, 'timesheet_type')}
        />
        <div className="flex flex-col">
          <div className="flex items-end">
            <ReactSelect
              isSearchable
              label={'Point of contact'}
              placeholder="Select point of contact"
              error={formErrors.find(e => e.field === 'purchaseOrder-point_of_contact_id')?.msg}
              options={contactsListboxData}
              selectedOptionsIds={[purchaseOrder.point_of_contact_id]}
              //When isClearable option is turned on, the element (el) returned is null so we cannot access value
              onChange={el => handlePurchaseOrderChange(el ? el.value : null, 'point_of_contact_id')}
              isClearable
            />
            <RefreshButton onClick={() => fetchAllContacts()} />
          </div>
          <NewTabOpener link={'/admin-panel/admin/points-of-contact/create'} title="Add new point of contact" />
        </div>
      </dl>
    </TwoColumnForm>
  );
}

export default AddPurchaseOrderAndUser;
