import React from 'react';

function Toggle({ onChange, label, subLabel, enable, className, disabled }) {
  return (
    <div className={`flex items-center outline-none ${className}  gap-x-4 `}>
      {/* <!-- Enabled: "bg-thaleria-orange-700", Not Enabled: "bg-gray-200" --> */}
      <button
        type="button"
        onClick={onChange}
        disabled={disabled}
        className={`${
          enable ? 'bg-thaleria-orange-700' : 'bg-gray-200'
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-orange-700" aria-pressed="false" aria-labelledby="annual-billing-label`}
      >
        <span className="sr-only">Use setting</span>
        {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
        <span
          aria-hidden="true"
          className={`${
            enable ? 'translate-x-5' : 'translate-x-0'
          } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        ></span>
      </button>
      <span className="" id="annual-billing-label">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        <span className="text-sm text-gray-500">{subLabel}</span>
      </span>
    </div>
  );
}

export default Toggle;
