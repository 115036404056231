import React from 'react';
import PageWrapper from '../../containers/App/PageWrapper';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CalculatorIcon, ClipboardListIcon, CurrencyEuroIcon, OfficeBuildingIcon, UserGroupIcon } from '@heroicons/react/outline';

function AdminSidebar({ children, pages, noPadding, navbar = true }) {
  const location = useLocation();

  const TYPES_V2 = [
    {
      name: 'Administrative',
      icon: OfficeBuildingIcon,
      items: [
        {
          name: 'Clients',
          url: `/admin-panel/admin/clients`,
          isActive: location.pathname.includes(`/admin-panel/admin/clients`),
        },
        {
          name: 'Points of Contact',
          url: `/admin-panel/admin/points-of-contact`,
          isActive: location.pathname.includes(`/admin-panel/admin/points-of-contact`),
        },
        {
          name: 'Countries',
          url: `/admin-panel/admin/countries`,
          isActive: location.pathname.includes(`/admin-panel/admin/countries`),
        },
        {
          name: 'Holidays',
          url: `/admin-panel/admin/holidays`,
          isActive: location.pathname.includes(`/admin-panel/admin/holidays`),
        },
        {
          name: 'Bank Accounts',
          url: `/admin-panel/admin/bank-accounts`,
          isActive: location.pathname.includes(`/admin-panel/admin/bank-accounts`),
        },
      ],
    },
    {
      name: 'Human Resources',
      icon: UserGroupIcon,
      items: [
        {
          name: 'User Directory',
          url: `/admin-panel/hr/user-directory`,
          isActive: location.pathname.includes(`/admin-panel/hr/user-directory`),
        },
        {
          name: 'Documents',
          url: `/admin-panel/hr/documents`,
          isActive: location.pathname.includes(`/admin-panel/hr/documents`),
        },
        {
          name: 'Timesheets',
          url: `/admin-panel/hr/timesheets`,
          isActive: location.pathname.includes(`/admin-panel/hr/timesheets`),
        },
        {
          name: 'Leave Requests',
          url: `/admin-panel/hr/leave-requests`,
          isActive: location.pathname.includes(`/admin-panel/hr/leave-requests`),
        },
        {
          name: 'Task Manager',
          url: `/admin-panel/hr/task-manager`,
          isActive: location.pathname.includes(`/admin-panel/hr/task-manager`),
        },
      ],
    },
    {
      name: 'Company Contracts',
      icon: ClipboardListIcon,
      items: [
        {
          name: 'Purchase Orders',
          url: `/admin-panel/contracts/purchase-orders`,
          isActive: location.pathname.includes(`/admin-panel/contracts/purchase-orders`),
        },
        {
          name: 'Framework Contracts',
          url: `/admin-panel/contracts/framework-contracts`,
          isActive: location.pathname.includes(`/admin-panel/contracts/framework-contracts`),
        },
        {
          name: 'Staff Contracts',
          url: `/admin-panel/contracts/staff-contracts`,
          isActive: location.pathname.includes(`/admin-panel/contracts/staff-contracts`),
        },
      ],
    },
    {
      name: 'Finance',
      icon: CalculatorIcon,
      items: [
        {
          name: 'Invoicing Lines',
          url: `/admin-panel/finance/invoicing-lines`,
          isActive: location.pathname.includes(`/admin-panel/finance/invoicing-lines`),
        },
        {
          name: 'Accounts Receivable',
          url: `/admin-panel/finance/accounts-receivable`,
          isActive: location.pathname.includes(`/admin-panel/finance/accounts-receivable`),
        },
        {
          name: 'Credit Notes',
          url: `/admin-panel/finance/credit-notes`,
          isActive: location.pathname.includes(`/admin-panel/finance/credit-notes`),
        },
        {
          name: 'Accounts Payable',
          url: `/admin-panel/finance/accounts-payable`,
          isActive: location.pathname.includes(`/admin-panel/finance/accounts-payable`),
        },
      ],
    },
  ];

  // const STYLES = {
  //   maxWidth: `calc(100vw - ${mainConta})`,
  // };

  return (
    <PageWrapper pages={pages} noXPadding noYPadding constrainWidth={false}>
      <div className="flex grow">
        {navbar && (
          <div className="flex shrink-0 w-56 flex-col inset-y-0 min-h-0 border-r border-gray-200 p-4 space-y-2 bg-gray-700">
            {TYPES_V2.map(el => (
              <div className="flex flex-col mb-2">
                <div className="flex space-x-2 text-gray-400 text-base items-center mb-1">
                  <el.icon className="w-5 h-5 " />
                  <span>{el.name}</span>
                </div>
                <div className="flex flex-col pl-6 gap-y-1">
                  {el.items.map(el => (
                    <Link
                      to={el.url}
                      className={`${
                        el.isActive ? 'bg-gray-800 text-white' : 'text-gray-300 hover:text-white'
                      } group flex items-center px-2 py-1 text-sm rounded-md transition duration-200`}
                    >
                      {el.name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="grow flex flex-col items-center" id="main-container">
          <div className={`w-full grow h-[700px] ${noPadding ? '' : 'p-8'} flex flex-col`}>{children}</div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default AdminSidebar;
