import React, { useRef, useState, useMemo, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import formatCurrency from '../../../utils/formatCurrency';
import format from 'date-fns/format';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import Checkbox from '../../../components/Checkbox/Checkbox';
import InfoTooltip from '../../../components/Tooltips/InfoTooltip';
import PayInvoice from '../../../components/Modals/PayInvoice';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import paidStatus from '../../../helpers/enum/paidStatus';
import InvoiceBalance from '../../../components/Finance/InvoiceBalance';
import { ClipLoader } from 'react-spinners';
import useApi from '../../../hooks/useApi';
import InvoiceTemplate from '../../../helpers/invoiceTemplate';
import replaceCommentVariables from '../../../helpers/replaceCommentVariables';

function AccountsReceivablePDFDetails({
  invoiceData,
  lineItems,
  creditNotes,
  toggleSentHandler,
  updateInvoicePaid,
  showPayModal,
  setShowPayModal,
  setEditInvoice,
  deleteHandler,
  paidRef,
  isLoading,
}) {
  const { paid, sent, ...invoice } = invoiceData;
  const iframeRef = useRef(null); // Reference to the iframe element

  const [showAlert, setShowAlert] = useState(false);

  const {
    files: { getInvoicePDFByID },
  } = useApi();

  const downloadAndDisplayPDF = () => {
    if (!invoiceData?.id) return;
    getInvoicePDFByID(invoiceData.id)
      .then(res => {
        const base64Data = res.data;
        const contentType = 'application/pdf';
        const url = `data:${contentType};base64:${base64Data}`;
        const iframe = iframeRef.current;
        if (iframe) iframe.src = url + '#toolbar=0';
      })
      .catch(err => {
        //in case invoice is not found in S3, re-create Pdf based on existing data
        const formattedData = {
          ...invoiceData,
          metadata: {
            ...invoiceData.metadata,
            header_comment: replaceCommentVariables(invoiceData.metadata.header_comment, invoiceData),
            footer_comment: replaceCommentVariables(invoiceData.metadata.footer_comment, invoiceData),
          },
          issue_date: format(invoiceData.issue_date, 'MMM do, yyyy'),
          due_date: invoiceData.due_date ? format(invoiceData.due_date, 'MMM do, yyyy') : '',
        };
        const documentDefinition = InvoiceTemplate({ invoiceData: formattedData, lineItems });
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getDataUrl(dataUrl => {
          const iframe = iframeRef.current;
          if (iframe) iframe.src = dataUrl + '#toolbar=0';
        });
      });
  };

  useEffect(() => {
    if (!invoiceData || !lineItems.length) return;
    downloadAndDisplayPDF();
  }, [invoiceData.id, lineItems]); // Regenerate PDF when inputs change

  const canEditAndDelete = useMemo(() => {
    let _canEditAndDelete = false;
    if (!sent && !creditNotes.length) _canEditAndDelete = true;
    return _canEditAndDelete;
  }, [invoice, creditNotes]);

  const paidLabel = useMemo(() => {
    const _paid =
      paid === paidStatus.numbers.partiallyPaid ? (
        <div className="flex space-x-2 place-items-center">
          Paid
          <InfoTooltip size="sm">
            <div className="flex flex-col space-y-2">
              <span>Date: {format(new Date(paid), 'dd/MM/yyyy')}</span>
              <span>Amount: {invoice.paid_amount ? invoice.paid_amount : 'Paid in full'}</span>
            </div>
          </InfoTooltip>
        </div>
      ) : (
        'Paid'
      );
    return _paid;
  }, [paid]);

  return (
    <div className="flex h-full ">
      <PayInvoice
        show={showPayModal}
        setShow={setShowPayModal}
        id={invoiceData?.id}
        _paid={invoiceData?.paid}
        _paidAmount={invoiceData?.paid_amount}
        onConfirm={updateInvoicePaid}
        adjustedRevenueAndVat={invoiceData.adjusted_revenue_and_vat}
      />
      <SimpleAlert
        errorTitle={'Delete invoice?'}
        errorMsg={'Deleting will completely remove this Invoice from the application. Are you sure you want to proceed?'}
        onAcceptText={isLoading === 'delete' ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Confirm'}
        onAcceptClick={deleteHandler}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      {/* Left-side: invoice preview */}
      <iframe ref={iframeRef} title="PDF Preview" className="w-full h-full" />
      {/* Right-side: action bar */}
      <div className="w-[22rem] bg-gray-200 flex flex-col justify-between px-2 pb-2 border-l border-gray-500">
        <div className="flex flex-col">
          <span className="font-semibold text-lg my-4">Invoice details</span>
          <div className="grid grid-cols-2 gap-x-2 gap-y-4">
            <SimpleEntry label="Client" data={invoice?.metadata?.to?.name} className="col-span-1" />
            <SimpleEntry label="Due date" data={format(invoice?.due_date, 'dd/MM/yyyy')} className="col-span-1" />
            <SimpleEntry label={'Sent'} data={<Checkbox value={sent} onChange={() => toggleSentHandler()} />} />
            <SimpleEntry
              label={paidLabel}
              data={
                <input
                  id={invoice?.id}
                  ref={el => {
                    if (paid === paidStatus.numbers.partiallyPaid && el?.indeterminate === false) {
                      el.indeterminate = true;
                    }
                    return (paidRef.current = el);
                  }}
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  disabled={!sent}
                  checked={paid}
                  onChange={() => setShowPayModal(true)}
                  onClick={e => e.stopPropagation()}
                  className={`focus:ring-thaleria-orange-500 h-4 w-4 border-gray-300 rounded mb-1 ${
                    !sent ? 'bg-gray-200 cursor-not-allowed text-gray-300' : 'text-thaleria-orange-600'
                  }`}
                />
              }
            />
            <SimpleEntry label="Total" data={formatCurrency(invoice?.revenue_and_vat)} />
            <SimpleEntry
              label={'Paid amount'}
              data={invoice.paid_amount ? formatCurrency(invoice.paid_amount) : formatCurrency(0)}
            />
            <SimpleEntry
              label="Payment date"
              data={invoice?.payment_date ? format(invoice?.payment_date, 'dd/MM/yyyy') : '-'}
              className="col-span-1"
            />
            <SimpleEntry label="Overdue" data={invoice?.overdue ? invoice?.overdue + ' days' : 'No'} className="col-span-1" />
          </div>
          <InvoiceBalance accountsReceivableId={invoiceData?.invoice_number || null} />
        </div>
        <div className="flex justify-center space-x-2">
          <ButtonPrimary text="Delete" disable={!canEditAndDelete} color="red" onClick={() => setShowAlert(true)} />
          <ButtonPrimary text="Edit" disable={!canEditAndDelete} onClick={() => setEditInvoice(true)} />
        </div>
      </div>
    </div>
  );
}

export default AccountsReceivablePDFDetails;
