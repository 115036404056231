import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageWrapperV2 from '../../../../containers/App/PageWrapper';
import ButtonWhite from '../../../../components/Buttons/ButtonWhite';
import ButtonPrimary from '../../../../components/Buttons/ButtonPrimary';
import DividerSimple from '../../../../components/Dividers/DividerSimple';
import EditSalesOrderAndSpecificContract from '../../../../containers/Contracts/StaffOrderEntry/EditSalesOrderAndSpecificContract';
import EditPurchaseOrderAndUser from '../../../../containers/Contracts/StaffOrderEntry/EditPurchaseOrderAndUser';
import EditEndClientAndPrimeFrameworkContract from '../../../../containers/Contracts/StaffOrderEntry/EditEndClientAndPrimeFrameworkContract';
import SummaryModal from '../../../../containers/Contracts/StaffOrderEntry/SummaryModal';
import * as Yup from 'yup';
import { DATE_VALIDATION_MSG, VALIDATION_MSG } from '../../../../helpers/enum/errorValidationMsgs';
import Loader from '../../../../components/Loading/Loader';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import { showErrorNotification, showSuccessNotification } from '../../../../store/app/actions';
import useApi from '../../../../hooks/useApi';
import { ExclamationIcon } from '@heroicons/react/outline';
import { subDays, isBefore, add } from 'date-fns';
import ExtensionModal from '../../../../containers/Contracts/StaffOrderEntry/ExtensionModal';
function EditDashboardEntry() {
  const {
    salesOrders: { getSalesOrderRefs },
    pointOfContacts: { getAllContacts },
    clients: { getAllClients },
    specificContracts: { getSpecificContractRefs },
    purchaseOrders: { getStaffOrderRefs, fetchStaffOrder, updateOrderInfo, deleteOrder },
    countries: { getAllCountries },
  } = useApi();
  const [dateErrors, setDateErrors] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [staffOrderOriginalData, setStaffOrderOriginalData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isAboutToExpire, setIsAboutToExpire] = useState(false);
  const [hasExpired, setHasExpired] = useState(false);
  const [showExtensionModal, setShowExtensionModal] = useState(false);
  const [extensionData, setExtensionData] = useState({
    contract_extension: null,
    extension_notes: '',
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const { id: activeStaffOrderFromURL } = useParams();

  const fetchAllContacts = () => getAllContacts().then(data => setContacts(data));
  const fetchAllClients = () => getAllClients().then(data => setClients(data));
  const fetchAllCountries = () => getAllCountries().then(data => setCountries(data));
  const fetchPurchaseOrderInfo = async id =>
    await fetchStaffOrder(id).then(data => {
      setStaffOrderOriginalData(data);
      setDataToEdit(data);
    });

  useEffect(() => {
    fetchAllClients();
    fetchAllContacts();
    fetchAllCountries();
  }, []);

  useEffect(() => {
    fetchPurchaseOrderInfo(activeStaffOrderFromURL);
  }, [activeStaffOrderFromURL]);

  const setDataToEdit = staffOrder => {
    //we need to convert database dates to date objects and some strings to integers for the form validation to work
    setPurchaseOrder({
      ...staffOrder,
      order_start: new Date(staffOrder.order_start),
      order_end: staffOrder?.order_end ? new Date(staffOrder.order_end) : null,
      daily_rate: staffOrder.daily_rate ? staffOrder.daily_rate.toString() : null,
      days_ordered: staffOrder.days_ordered ? staffOrder.days_ordered.toString() : null,
    });
    setPurchaseOrderOpenEnded(staffOrder.order_end ? false : true);
    setExtensionData({
      contract_extension: staffOrder?.contract_extension,
      extension_notes: staffOrder?.extension_notes,
    });

    setSalesOrder({
      ...staffOrder.salesOrder,
      order_start: new Date(staffOrder.salesOrder.order_start),
      order_end: staffOrder.salesOrder.order_end ? new Date(staffOrder.salesOrder.order_end) : null,
      daily_rate: staffOrder.salesOrder.daily_rate ? staffOrder.salesOrder.daily_rate.toString() : null,
      days_ordered: staffOrder.salesOrder.days_ordered ? staffOrder.salesOrder.days_ordered.toString() : null,
    });
    setSalesOrderOpenEnded(staffOrder.salesOrder.order_end ? false : true);

    setSpecificContract({
      ...staffOrder.specificContract,
      order_start: new Date(staffOrder.specificContract.order_start),
      order_end: staffOrder.specificContract.order_end ? new Date(staffOrder.specificContract.order_end) : null,
      daily_rate: staffOrder.specificContract.daily_rate ? staffOrder.specificContract.daily_rate.toString() : null,
      days_ordered: staffOrder.specificContract.days_ordered ? staffOrder.specificContract.days_ordered.toString() : null,
    });
    setSpecificContractOpenEnded(staffOrder.specificContract.order_end ? false : true);

    setEndClientFWC({
      value: staffOrder.frameworkContract.id,
      label: staffOrder.frameworkContract.contract_ref,
      ...staffOrder.frameworkContract,
    });
    setPrimeFWC({
      value: staffOrder.partnerContract.id,
      label: staffOrder.partnerContract.contract_ref,
      ...staffOrder.partnerContract,
    });

    setSubcoPlacement(staffOrder.frameworkContract.id !== staffOrder.partnerContract.id ? true : false);

    setUser({ label: staffOrder.user.full_name, value: staffOrder.user.id, ...staffOrder.user });

    setStaffContract({
      label: staffOrder.staffContract.contract_ref,
      value: staffOrder.staffContract.id,
      ...staffOrder.staffContract,
    });

    setIsLoading(false);
  };

  //------------------END-CLIENT AND PRIME FRAMEWORK CONTRACTS----------------------//

  const [endClientFWC, setEndClientFWC] = useState(null);

  const [subcoPlacement, setSubcoPlacement] = useState(false);
  const [primeFWC, setPrimeFWC] = useState(null);

  //------------------SPECIFIC CONTRACT AND SALES ORDER----------------------//

  const [specificContractOpenEnded, setSpecificContractOpenEnded] = useState(false);
  const [salesOrderOpenEnded, setSalesOrderOpenEnded] = useState(false);

  const [specificContract, setSpecificContract] = useState();

  const [salesOrder, setSalesOrder] = useState();

  //------------------PURCHASE ORDER AND CONSULTANT----------------------//

  const [purchaseOrderOpenEnded, setPurchaseOrderOpenEnded] = useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState();

  const [user, setUser] = useState({ value: 0, label: 'Select user...' });
  const [staffContract, setStaffContract] = useState({ value: 0, label: 'Select contract...' });

  //-------------SUMMARY MODAL----------//

  const [showSummaryModal, setShowSummaryModal] = useState(false);

  //------------PAGES-------------//

  useEffect(() => {
    if (!activeStaffOrderFromURL || !staffOrderOriginalData) return;
    const pathnameArray = location.pathname.split('/');
    const lastWordOfUrl = pathnameArray[pathnameArray.length - 1];
    let _pages = [
      { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
      { name: 'Purchase Orders', href: '/admin-panel/contracts/purchase-orders', current: false },
      {
        name: staffOrderOriginalData.order_ref,
        href: `/admin-panel/contracts/purchase-orders/${activeStaffOrderFromURL}`,
        current: false,
      },
    ];
    if (lastWordOfUrl === 'edit') {
      _pages.push({
        name: 'Edit',
        href: '',
        current: false,
      });
      setEdit(true);
    } else {
      setEdit(false);
    }
    _pages[_pages.length - 1].current = true;
    setPages(_pages);
  }, [location.pathname, staffOrderOriginalData]);

  //--------------HELPERS---------------//

  //handle pagewrapper click
  const history = useHistory();

  const handleCancelClick = () => {
    history.push('/admin-panel/contracts/purchase-orders');
  };

  const checkDateErrors = () => {
    // verify date consistency
    const errors = [];

    if (new Date(purchaseOrder.order_start) < new Date(specificContract?.order_start))
      errors.push({
        errors: ['purchaseOrder', 'specificContract'],
        msg: "Purchase order start date cannot be before specific contract's start date",
      });
    if (!specificContractOpenEnded) {
      if (new Date(purchaseOrder.order_end) > new Date(specificContract?.order_end) || purchaseOrderOpenEnded) {
        if (!specificContractOpenEnded) {
          errors.push({
            errors: ['purchaseOrder', 'specificContract'],
            msg: "Purchase order end date cannot be after specific contract's end date",
          });
        }
      }
    }
    if (new Date(purchaseOrder.order_start) < new Date(salesOrder.order_start)) {
      errors.push({
        errors: ['purchaseOrder', 'salesOrder'],
        msg: "Purchase order start date cannot be before sales order's start date",
      });
    }
    if (!salesOrderOpenEnded) {
      if (new Date(purchaseOrder.order_end) > new Date(salesOrder.order_end) || purchaseOrderOpenEnded) {
        if (!salesOrderOpenEnded) {
          errors.push({
            errors: ['purchaseOrder', 'salesOrder'],
            msg: "Purchase order end date cannot be after sales order's end date",
          });
        }
      }
    }
    if (salesOrder.order_start < new Date(endClientFWC.contract_start)) {
      errors.push({
        errors: ['salesOrder', 'endClientFWC'],
        msg: "Sales order start date cannot be before framework contract's start date",
      });
    }
    if (endClientFWC?.contract_end) {
      if (new Date(salesOrder.order_end) > new Date(endClientFWC.contract_end) || salesOrderOpenEnded) {
        if (endClientFWC.contract_end)
          errors.push({
            errors: ['salesOrder', 'endClientFWC'],
            msg: "Sales order end date cannot be after framework contract's end date",
          });
      }
    }
    if (new Date(salesOrder.order_start) < new Date(primeFWC?.contract_start)) {
      errors.push({
        errors: ['salesOrder', 'primeFWC'],
        msg: "Sales order start date cannot be before partner contract's start date",
      });
    }
    if (primeFWC?.contract_end) {
      if (new Date(salesOrder.order_end) > new Date(primeFWC?.contract_end) || salesOrderOpenEnded) {
        if (primeFWC?.contract_end) {
          errors.push({
            errors: ['salesOrder', 'primeFWC'],
            msg: "Sales order end date cannot be after partner contract's end date",
          });
        }
      }
    }

    if (new Date(specificContract?.order_start) < new Date(endClientFWC.contract_start)) {
      errors.push({
        errors: ['specificContract', 'endClientFWC'],
        msg: "Specific contract start date cannot be before framework contract's start date",
      });
    }
    if (endClientFWC?.contract_end) {
      if (new Date(specificContract?.order_end) > new Date(endClientFWC.contract_end) || specificContractOpenEnded) {
        if (endClientFWC.contract_end) {
          errors.push({
            errors: ['specificContract', 'endClientFWC'],
            msg: "Specific contract end date cannot be after framework contract's end date",
          });
        }
      }
    }

    if (new Date(specificContract?.order_start) < new Date(primeFWC?.contract_start)) {
      errors.push({
        errors: ['specificContract', 'primeFWC'],
        msg: "Specific contract start date cannot be before partner contract's start date",
      });
    }
    if (primeFWC?.contract_end) {
      if (new Date(specificContract?.order_end) > new Date(primeFWC?.contract_end) || specificContractOpenEnded) {
        if (primeFWC?.contract_end) {
          errors.push({
            errors: ['specificContract', 'primeFWC'],
            msg: "Specific contract end date cannot be after partner contract's end date",
          });
        }
      }
    }
    if (new Date(staffContract?.contract_start) > new Date(purchaseOrder?.order_start)) {
      errors.push({
        errors: ['purchaseOrder', 'staffContract'],
        msg: 'Purchase order cannot start before staff contract',
      });
    }
    if (staffContract?.contract_end) {
      if (new Date(staffContract?.contract_end) < new Date(purchaseOrder?.order_end || purchaseOrderOpenEnded)) {
        errors.push({
          errors: ['purchaseOrder', 'staffContract'],
          msg: 'Staff contract cannot end before purchase order',
        });
      }
    }
    if (errors.length) {
      setDateErrors(errors);
      return false;
    } else {
      setDateErrors([]);
      return true;
    }
  };

  const checkFormErrors = async () => {
    let errors = [];

    //framework contracts validation
    if (!endClientFWC) errors.push({ field: 'endClientFWC', msg: 'Please select a framework contract' });
    if (!primeFWC) errors.push({ field: 'primeFWC', msg: 'Please select a framework contract' });

    //specific contract validation
    let specificContractRefs;
    await getSpecificContractRefs().then(
      res =>
        (specificContractRefs = res
          .filter(item => item.contract_ref !== staffOrderOriginalData.specificContract.contract_ref)
          .map(item => item.contract_ref)),
    );

    if (
      specificContract?.days_ordered &&
      new Number(specificContract.days_ordered).valueOf() <
        new Number(staffOrderOriginalData.specificContract.days_consumed).valueOf()
    ) {
      errors.push({
        field: 'specificContract-days_ordered',
        msg: 'Days ordered cannot be less than days consumed.',
      });
    }

    const specificContractValidationSchema = Yup.object().shape({
      contract_ref: Yup.string()
        .required(VALIDATION_MSG)
        .trim()
        .notOneOf(specificContractRefs, 'Reference name already in use')
        .nullable(),
      order_start: Yup.date().required(VALIDATION_MSG).nullable(),
      order_end:
        specificContractOpenEnded || specificContract?.order_start
          ? ''
          : Yup.date()
              .required(VALIDATION_MSG)
              .nullable()
              .when('order_start', (order_start, schema) => order_start && schema.min(order_start, DATE_VALIDATION_MSG)),
      daily_rate: Yup.string().required(VALIDATION_MSG).nullable(),
      days_ordered: Yup.string()
        .notRequired()
        .nullable(true)
        .matches(/^(\s*|[0-9.])+$/, 'Must be only digits'),
      department: Yup.string().nullable().notRequired(),
      client_id: Yup.number().nullable().required(VALIDATION_MSG),
      country_id: Yup.number().nullable().required(VALIDATION_MSG),
      point_of_contact_id: Yup.number().notRequired().nullable(),
    });

    await specificContractValidationSchema.validate(specificContract, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: 'specificContract-' + _err.path,
          msg: _err.errors[0],
        });
      });
    });

    //sales order validation
    let salesOrderRefs;
    await getSalesOrderRefs().then(
      res =>
        (salesOrderRefs = res
          .filter(item => item.order_ref !== staffOrderOriginalData.salesOrder.order_ref)
          .map(item => item.order_ref)),
    );

    if (
      salesOrder?.days_ordered &&
      new Number(salesOrder.days_ordered).valueOf() < new Number(staffOrderOriginalData.salesOrder.days_consumed).valueOf()
    ) {
      errors.push({
        field: 'salesOrder-days_ordered',
        msg: 'Days ordered cannot be less than days consumed.',
      });
    }

    const salesOrderValidationSchema = Yup.object().shape({
      order_ref: Yup.string()
        .required(VALIDATION_MSG)
        .trim()
        .notOneOf(salesOrderRefs, 'Reference name already in use')
        .nullable(),
      order_start: Yup.date().required(VALIDATION_MSG).nullable(),
      order_end:
        salesOrderOpenEnded || !salesOrder?.order_start
          ? ''
          : Yup.date()
              .required(VALIDATION_MSG)
              .nullable()
              .when('order_start', (order_start, schema) => order_start && schema.min(order_start, DATE_VALIDATION_MSG)),
      daily_rate: Yup.string().required(VALIDATION_MSG).nullable(),
      days_ordered: Yup.string()
        .notRequired()
        .nullable(true)
        .matches(/^(\s*|[0-9.])+$/, 'Must be only digits'),
      client_id: Yup.number().nullable().required(VALIDATION_MSG),
    });

    await salesOrderValidationSchema.validate(salesOrder, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: 'salesOrder-' + _err.path,
          msg: _err.errors[0],
        });
      });
    });
    //purchase order validation
    let purchaseOrderRefs;
    await getStaffOrderRefs().then(
      res =>
        (purchaseOrderRefs = res.filter(item => item.order_ref !== staffOrderOriginalData.order_ref).map(item => item.order_ref)),
    );

    if (
      purchaseOrder?.days_ordered &&
      new Number(purchaseOrder.days_ordered).valueOf() < new Number(staffOrderOriginalData.days_consumed).valueOf()
    ) {
      errors.push({
        field: 'purchaseOrder-days_ordered',
        msg: 'Days ordered cannot be less than days consumed.',
      });
      //days ordered validation
      if (
        (specificContract?.days_ordered !== null &&
          new Number(salesOrder?.days_ordered) > new Number(specificContract?.days_ordered)) ||
        (salesOrder?.days_ordered == null && specificContract?.days_ordered !== null)
      ) {
        errors.push({
          field: 'salesOrder-days_ordered',
          msg: "Cannot exceed specific contract's days ordered",
        });
      }
      if (
        (salesOrder?.days_ordered !== null && new Number(purchaseOrder?.days_ordered) > new Number(salesOrder?.days_ordered)) ||
        (purchaseOrder?.days_ordered == null && salesOrder?.days_ordered !== null)
      ) {
        errors.push({
          field: 'purchaseOrder-days_ordered',
          msg: "Cannot exceed sales order's days ordered",
        });
      }
    }

    const purchaseOrderValidationSchema = Yup.object().shape({
      order_ref: Yup.string()
        .required(VALIDATION_MSG)
        .trim()
        .notOneOf(purchaseOrderRefs, 'Reference name already in use')
        .nullable(),
      order_start: Yup.date().required(VALIDATION_MSG).nullable(),
      order_end:
        purchaseOrderOpenEnded || purchaseOrder?.order_start
          ? ''
          : Yup.date()
              .required(VALIDATION_MSG)
              .nullable()
              .when('order_start', (order_start, schema) => order_start && schema.min(order_start, DATE_VALIDATION_MSG)),
      daily_rate: Yup.string().required(VALIDATION_MSG).nullable(),
      days_ordered: Yup.string().notRequired().nullable(true),
    });

    await purchaseOrderValidationSchema.validate(purchaseOrder, { strict: true, abortEarly: false }).catch(err => {
      err.inner.forEach(_err => {
        errors.push({
          field: 'purchaseOrder-' + _err.path,
          msg: _err.errors[0],
        });
      });
    });

    //consultant and staff contract validation

    //framework contracts validation
    if (!user || user.value === 0) errors.push({ field: 'user', msg: 'Please select a user' });
    if (!staffContract || staffContract.value === 0)
      errors.push({ field: 'staffContract', msg: 'Please select a staff contract' });

    return errors;
  };

  const handleEditContractClick = async () => {
    const errors = await checkFormErrors();

    if (errors.length) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setFormErrors(errors);
      return;
    }
    checkDateErrors();
    setFormErrors([]);
    setShowSummaryModal(true);
  };

  const handleEditClick = () => {
    setDataToEdit(staffOrderOriginalData);
    setFormErrors([]);
    setEdit(true);
  };

  const handleDeleteClick = () => {
    deleteOrder(activeStaffOrderFromURL)
      .then(d => {
        dispatch(showSuccessNotification('Purchase Order deleted'));
        setShowAlert(false);
        history.push(`/admin-panel/contracts/purchase-orders`);
      })
      .catch(e => {
        showErrorNotification(e.response.data.message);
        setShowAlert(false);
      });
  };

  useEffect(() => {
    const orderEnd = staffOrderOriginalData?.order_end;
    const days_ordered = staffOrderOriginalData?.days_ordered;
    const days_consumed = staffOrderOriginalData?.days_consumed;
    if (
      (orderEnd && isBefore(new Date(orderEnd), add(new Date(), { months: 2 }))) ||
      (days_ordered && days_ordered - days_consumed <= 50)
    ) {
      setIsAboutToExpire(true);
      if (
        (orderEnd && isBefore(new Date(orderEnd), subDays(new Date(), 1))) ||
        (days_ordered && days_ordered - days_consumed === 0)
      ) {
        setIsAboutToExpire(false);
        setHasExpired(true);
      } else {
        setIsAboutToExpire(true);
      }
    } else {
      setIsAboutToExpire(false);
      setHasExpired(false);
    }
  }, [staffOrderOriginalData]);

  const updatedExtensionInfo = async () => {
    await updateOrderInfo(purchaseOrder.id, extensionData).then(async res => {
      await fetchStaffOrder(purchaseOrder.id).then(data => {
        setStaffOrderOriginalData(data);
        setDataToEdit(data);
        setShowExtensionModal(false);
      });
    });
  };

  if (isLoading)
    return (
      <PageWrapperV2 pages={pages}>
        <Loader isLoading className={'flex justify-around min-h-500 p-32 '} />
      </PageWrapperV2>
    );

  return (
    <PageWrapperV2 pages={pages}>
      {staffOrderOriginalData.contract_extension === null && (isAboutToExpire || hasExpired) && (
        <div className="bg-red-100 rounded-lg flex space-x-1 justify-between text-red-700 items-center text-md p-4 mb-4">
          <div className="flex space-x-1">
            <ExclamationIcon className=" h-6 w-6 mr-2 text-red-500" />
            <p>{`This purchase order ${hasExpired ? 'has expired' : 'is about to expire'}! Please add extension details.`}</p>
          </div>
          <ButtonWhite text="Add" onClick={() => setShowExtensionModal(true)} />
        </div>
      )}
      <ExtensionModal
        show={showExtensionModal}
        hide={() => setShowExtensionModal(false)}
        data={extensionData}
        setData={setExtensionData}
        onClick={() => updatedExtensionInfo()}
      />
      <SummaryModal
        show={showSummaryModal}
        hide={() => setShowSummaryModal(false)}
        endClientFWC={endClientFWC}
        primeFWC={primeFWC}
        specificContract={specificContract}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        user={user}
        staffContract={staffContract}
        dateErrors={dateErrors}
        clients={clients}
        contacts={contacts}
        setEdit={setEdit}
        setStaffOrderOriginalData={setStaffOrderOriginalData}
        staffOrderOriginalData={staffOrderOriginalData}
        setDataToEdit={setDataToEdit}
        countries={countries}
      />
      <SimpleAlert
        errorTitle="Delete Purchase Order?"
        errorMsg="This action is irreversible and will also permanently delete the associated Specific Contract and Sales Order. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleDeleteClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <EditEndClientAndPrimeFrameworkContract
        endClientFWC={endClientFWC}
        setEndClientFWC={setEndClientFWC}
        primeFWC={primeFWC}
        setPrimeFWC={setPrimeFWC}
        formErrors={formErrors}
        edit={edit}
        subcoPlacement={subcoPlacement}
        setSubcoPlacement={setSubcoPlacement}
      />
      <DividerSimple />
      <EditSalesOrderAndSpecificContract
        formErrors={formErrors}
        salesOrder={salesOrder}
        setSalesOrder={setSalesOrder}
        specificContract={specificContract}
        setSpecificContract={setSpecificContract}
        dateErrors={dateErrors}
        specificContractOpenEnded={specificContractOpenEnded}
        setSpecificContractOpenEnded={setSpecificContractOpenEnded}
        salesOrderOpenEnded={salesOrderOpenEnded}
        setSalesOrderOpenEnded={setSalesOrderOpenEnded}
        day
        clients={clients}
        contacts={contacts}
        fetchAllClients={fetchAllClients}
        fetchAllContacts={fetchAllContacts}
        edit={edit}
        specificContractOriginalData={staffOrderOriginalData.specificContract}
        salesOrderOriginalData={staffOrderOriginalData.salesOrder}
        countries={countries}
        fetchAllCountries={fetchAllCountries}
      />
      <DividerSimple />
      <EditPurchaseOrderAndUser
        edit={edit}
        formErrors={formErrors}
        purchaseOrder={purchaseOrder}
        setPurchaseOrder={setPurchaseOrder}
        user={user}
        setUser={setUser}
        staffContract={staffContract}
        setStaffContract={setStaffContract}
        openEnded={purchaseOrderOpenEnded}
        setOpenEnded={setPurchaseOrderOpenEnded}
        contacts={contacts}
        fetchAllContacts={fetchAllContacts}
        purchaseOrderOriginalData={staffOrderOriginalData}
        isAboutToExpire={isAboutToExpire}
        hasExpired={hasExpired}
      />
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1"></div>
        <div className="py-3 flex flex-row-reverse justify-between md:col-span-2">
          <div className="text-right">
            {!edit && (
              <div>
                <ButtonWhite style="mr-2" onClick={handleCancelClick} text="Back" />
                <ButtonPrimary onClick={handleEditClick} text="Edit entry" />
              </div>
            )}
            {edit && (
              <div>
                <ButtonWhite style="mr-2" onClick={() => setEdit(false)} text="Cancel changes" />
                <ButtonPrimary onClick={handleEditContractClick} text="Save changes" />
              </div>
            )}
          </div>
          <div className="text-left">
            {!edit && <ButtonPrimary canDelete color="red" style="mr-2" onClick={() => setShowAlert(true)} text="Delete entry" />}
            {!edit && (
              <ButtonPrimary style="mr-2" text="View logs" link={`/admin-panel/contracts/days-log/${activeStaffOrderFromURL}`} />
            )}
          </div>
        </div>
      </div>
    </PageWrapperV2>
  );
}

export default EditDashboardEntry;
