import React, { useState, useEffect, useMemo } from 'react';
import Modal from '../../../components/Modals/Modal';
import useApi from '../../../hooks/useApi';
import formatCurrency from '../../../utils/formatCurrency';
import Loader from '../../../components/Loading/Loader';
import { format } from 'date-fns';

function OverdueKPIModal({ show, setShow, filters }) {
  const [selectedTab, setSelectedTab] = useState('total');
  const [KPIContent, setKPIContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    accountsPayable: { getUnpaidKPI },
  } = useApi();

  const totals = useMemo(() => {
    let totalAmount = 0;
    let totalInvoices = 0;
    KPIContent.forEach(row => {
      totalAmount += Number(row.total_cost_and_vat);
      totalInvoices += Number(row.ids.length);
    });
    return { totalAmount, totalInvoices };
  }, [KPIContent]);

  const handleHide = () => {
    setShow(false);
  };

  const getInitialData = async () => {
    const query = {
      ...filters,
      applyFilters: selectedTab === 'total' ? false : true,
    };
    await getUnpaidKPI(query).then(res => {
      setIsLoading(false);
      setKPIContent(res);
    });
  };

  useEffect(() => {
    setIsLoading(true);

    getInitialData();
  }, [show, selectedTab]);

  const handleTabClick = tab => {
    setSelectedTab(tab);
  };

  return (
    <Modal size="md" show={show} hide={handleHide}>
      <div className=" rounded-t-lg">
        {/* Tab buttons */}
        <div className="flex">
          <button
            className={`p-2 flex-grow text-center ${
              selectedTab === 'total' ? 'button-border border-thaleria-orange-500' : 'button-border'
            }`}
            onClick={() => handleTabClick('total')}
            style={{ paddingBottom: selectedTab === 'total' ? '2px' : '0' }} /* Adjust padding based on selected tab */
          >
            Total
          </button>
          <button
            className={`p-2 flex-grow text-center ${
              selectedTab === 'withFilters' ? 'button-border border-thaleria-orange-500' : 'button-border'
            }`}
            onClick={() => handleTabClick('withFilters')}
            style={{ paddingBottom: selectedTab === 'withFilters' ? '2px' : '0' }} /* Adjust padding based on selected tab */
          >
            With filters
          </button>
        </div>
      </div>
      {/* Tab content */}
      <div className="-mx-4 mt-8 flow-root sm:mx-0">
        <Loader isLoading={isLoading} className={'w-full flex items-center justify-center h-80'}>
          <table className="min-w-full">
            <colgroup>
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
            </colgroup>
            <thead className="border-b border-gray-300 text-gray-900">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Period
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Invoices
                </th>
                <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {KPIContent.map(row => (
                <tr key={row.overduePeriod} className="border-b border-gray-200">
                  <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                    <div className="font-medium text-gray-900">{format(new Date(row.period), 'MMMM - yyyy')}</div>
                  </td>
                  <td className=" px-3 py-5 text-left text-sm text-gray-500">{row.ids.length}</td>
                  <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                    {formatCurrency(row.total_cost_and_vat)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th scope="row" className=" pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Total
                </th>
                <th scope="row" className="px-3 pt-4 text-left text-sm font-semibold text-gray-900">
                  {totals.totalInvoices}
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                  {formatCurrency(totals.totalAmount)}
                </td>
              </tr>
            </tfoot>
          </table>
        </Loader>
      </div>
    </Modal>
  );
}

export default OverdueKPIModal;
