import React from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';

const InfoText = ({ text, title, className }) => {
  return (
    <div className={` ${className} rounded-md bg-yellow-50 p-4`}>
      {title ? (
        <>
          <div className="flex space-x-2 items-center">
            <ExclamationIcon className="h-6 w-6 text-yellow-400" />
            <p className="text-sm font-medium text-yellow-800">{title}</p>
          </div>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{text}</p>
          </div>
        </>
      ) : (
        <p className="text-sm text-yellow-700 leading-relaxed">
          <ExclamationIcon className="h-5 w-5 text-yellow-400 inline-block mr-2" />
          {text}
        </p>
      )}
    </div>
  );
};

export default InfoText;
