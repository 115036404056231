import * as React from 'react';
import { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';

export default function PopOutMenu({ children, xOffset = 0, title, className, openButton, key }) {
  const popperElRef = React.useRef(null);
  const menuButtonRef = React.useRef(null);
  const menuItemsRef = React.useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [popperPosition, setPopperPosition] = useState('bottom');

  const offset = React.useMemo(() => {
    let _offset;
    switch (popperPosition) {
      case 'bottom':
        _offset = [20 + xOffset, 0];
        break;
      case 'top':
        _offset = [20 + xOffset, 85];
        break;
      default:
        break;
    }
    return _offset;
  }, [popperPosition]);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: popperPosition,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
    ],
  });

  React.useEffect(() => {
    //Check if click happened close to the bottom or right edge, than add offset to anchorpoint
    let menuButtonXPosition = menuButtonRef?.current?.getBoundingClientRect().left;
    let menuButtonYPosition = menuButtonRef?.current?.getBoundingClientRect().top;
    let menuHeight = menuItemsRef?.current?.clientHeight + 40;
    let menuWidth = menuItemsRef?.current?.clientWidth;
    if (menuButtonXPosition + menuWidth > window?.window?.innerWidth) {
      //TODO: If needed, add a position to 'left' side to offset against right edge
    }

    if (menuButtonYPosition + menuHeight > window?.window?.innerHeight) {
      return setPopperPosition('top');
    } else {
      return setPopperPosition('bottom');
    }
  });

  return (
    <Menu>
      {({ open }) => (
        <>
          <div className="inline-flex" ref={setTargetElement} key={key}>
            <Menu.Button
              ref={menuButtonRef}
              className="cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              {openButton}
            </Menu.Button>
          </div>

          <Portal>
            <div ref={popperElRef} style={styles.popper} {...attributes.popper}>
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items
                  className="p-4 origin-bottom-right z-50 absolute right-0 mt-2 w-60 md:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  ref={menuItemsRef}
                >
                  {title && (
                    <div className="w-full flex justify-between pb-4">
                      <p className="text-lg font-semibold ">{title}</p>
                    </div>
                  )}
                  <div className={`${className ? className : 'h-80'}`}>{children}</div>
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
}
