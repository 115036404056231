import React, { useEffect, useState } from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import RichTextEditor from '../../../components/Inputs/RichTextEditor/RichTextEditor';
import ProfilePicture from '../../../components/ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../../../components/UserImagePlaceholder/UserImagePlaceholder';
import moment from 'moment';
import useWindowSize from '../../../hooks/useWindowSize';

const ViewAnnouncement = ({ announcement }) => {
  const [showText, setShowText] = useState();

  const windowSize = useWindowSize();

  useEffect(() => {
    if (announcement?.body) {
      var convertedJSON = JSON.parse(announcement?.body);
      setShowText(EditorState.createWithContent(convertFromRaw(convertedJSON)));
    }
  }, [announcement]);

  const formatDate = moment(announcement?.created_at).format('MMMM D [at] h:mm a');

  if (!showText) return <div></div>;

  return (
    <div className="v-full shadow bg-white p-2 sm:px-6 sm:py-4 whitespace-pre-line rounded-md">
      <div className="flex flex-col-reverse  md:flex-row md:justify-between">
        <h1 className="ml-3 text-2xl md:text-4xl">{announcement?.title}</h1>
        <div
          className={`flex items-center space-x-4 ${
            windowSize.width <= windowSize.tailwind.md && 'mr-auto'
          } flex-row-reverse md:flex-row`}
        >
          <div className="flex flex-col ml-2 md:ml-0">
            <span className="text-gray-700 truncate md:text-right">
              {(announcement?.created_by?.first_names || '') + ' ' + (announcement?.created_by?.last_names || '')}
            </span>
            <span className="text-sm text-gray-400 truncate">Posted {formatDate}</span>
          </div>
          <ProfilePicture
            className="h-8 w-8 rounded-full ring-4 ring-white sm:h-12 sm:w-12 "
            src={announcement?.created_by?.profilepic_url}
            alt=""
            defaultimage={<UserImagePlaceholder className="h-8 w-8 rounded-full ring-4 ring-white sm:h-12 sm:w-12" />}
          />
        </div>
      </div>
      <RichTextEditor readOnly editorState={showText} />
    </div>
  );
};

export default ViewAnnouncement;
