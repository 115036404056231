import React, { useEffect, useMemo, useState } from 'react';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import Modal from './Modal';
import ButtonWhite from '../Buttons/ButtonWhite';
import DividerWithLabel from '../Dividers/DividerWithLabel';
import * as countryID from '../../helpers/enum/CountryIDs';
import formatCurrency from '../../utils/formatCurrency';
import roundNumber from '../../utils/roundNumber';
import ReactSelect from '../Inputs/ReactSelect';
import InputSimple from '../Inputs/InputSimple';
import Checkbox from '../Checkbox/Checkbox';
import allocationTypes from '../../helpers/enum/allocationTypes';

const FinancialDataFormat = ({ show, setShow, selectedStatus, activeStaffOrders, entries, onAcceptClick }) => {
  const [accountsPayableData, setAccountsPayableData] = useState([]);
  const [accountsReceivableData, setAccountsReceivableData] = useState([]);
  const [applyChangesToAP, setApplyChangesToAP] = useState(true);
  const onProceed = () => {
    let dataForLineCreation = activeStaffOrders.map(so => ({
      staff_order_id: so.id,
    }));

    accountsPayableData.forEach(ap => {
      const index = dataForLineCreation.findIndex(i => {
        return i.staff_order_id === ap.staff_order_id;
      });
      //If index found (can be 0)
      if (index !== -1) {
        dataForLineCreation[index].ap_decimal_places = ap.decimal_places;
        dataForLineCreation[index].ap_uom = ap.uom;
      }
    });

    accountsReceivableData.forEach(ar => {
      const index = dataForLineCreation.findIndex(i => {
        return i.staff_order_id === ar.staff_order_id;
      });
      //If index found (can be 0)
      if (index !== -1) {
        dataForLineCreation[index].ar_decimal_places = ar.decimal_places;
        dataForLineCreation[index].ar_uom = ar.uom;
      }
    });
    setShow(false);
    onAcceptClick(selectedStatus, dataForLineCreation);
  };

  useEffect(() => {
    if (!activeStaffOrders.length || !entries.length) return;
    let arArray = [];
    let apArray = [];

    activeStaffOrders.forEach(so => {
      let apObj = {
        order_ref: so.order_ref,
        staff_order_id: so.id,
        daily_rate: Number(so.daily_rate),
        vat_rate: so.staffContract.country_id === countryID.belgium ? 21 : 0,
        decimal_places: 2,
        uom: 'days',
        hours_worked: 0,
      };

      let arObj = {
        order_ref: so.order_ref,
        staff_order_id: so.id,
        daily_rate: Number(so.salesOrder.daily_rate),
        vat_rate: so.salesOrder.client.country_id === countryID.belgium ? 21 : 0,
        decimal_places: 2,
        uom: 'days',
        hours_worked: 0,
      };

      const entriesForThisSO = entries.filter(entry => entry.staff_order_id === so.id);
      entriesForThisSO.forEach(entry => {
        switch (entry.type) {
          case allocationTypes.billable:
            arObj.hours_worked += entry.hours;
            apObj.hours_worked += entry.hours;
            break;
          case allocationTypes.offboarding:
            arObj.hours_worked += entry.hours;
            break;
          case allocationTypes.onboarding:
            apObj.hours_worked += entry.hours;
            break;
          default:
            return;
        }
      });
      apObj.hours_worked = roundNumber(apObj.hours_worked);
      arObj.hours_worked = roundNumber(arObj.hours_worked);

      if (apObj.hours_worked) apArray.push(apObj);
      if (arObj.hours_worked) arArray.push(arObj);
    });
    setAccountsPayableData(apArray);
    setAccountsReceivableData(arArray);
  }, [entries, activeStaffOrders]);

  const uomOptions = [
    {
      value: 'days',
      label: 'days',
    },
    {
      value: 'hours',
      label: 'hours',
    },
  ];

  const onChangeUoM = (e, index, type, id) => {
    if (type === 'ar') {
      setAccountsReceivableData(prevData => {
        return prevData.map((item, i) => {
          if (i === index) {
            let newData = {
              ...item,
              uom: e.value,
            };
            if (e.value === 'hours') newData['decimal_places'] = 2;
            return newData;
          }
          return item;
        });
      });

      if (applyChangesToAP) {
        setAccountsPayableData(prevData => {
          return prevData.map((item, i) => {
            if (item.staff_order_id === id) {
              let newData = {
                ...item,
                uom: e.value,
              };
              if (e.value === 'hours') newData['decimal_places'] = 2;
              return newData;
            }
            return item;
          });
        });
      }
    }

    if (type === 'ap') {
      setAccountsPayableData(prevData => {
        return prevData.map((item, i) => {
          if (i === index) {
            let newData = {
              ...item,
              uom: e.value,
            };
            if (e.value === 'hours') newData['decimal_places'] = 2;
            return newData;
          }
          return item;
        });
      });
    }
  };

  const onChangeDecimals = (e, index, type, id) => {
    if (type === 'ar') {
      setAccountsReceivableData(prevData => {
        return prevData.map((item, i) => {
          if (i === index) {
            let newData = {
              ...item,
              decimal_places: e.target.value,
            };
            return newData;
          }
          return item;
        });
      });

      if (applyChangesToAP) {
        setAccountsPayableData(prevData => {
          return prevData.map((item, i) => {
            if (item.staff_order_id === id) {
              let newData = {
                ...item,
                decimal_places: e.target.value,
              };
              return newData;
            }
            return item;
          });
        });
      }
    }

    if (type === 'ap') {
      setAccountsPayableData(prevData => {
        return prevData.map((item, i) => {
          if (i === index) {
            let newData = {
              ...item,
              decimal_places: e.target.value,
            };
            return newData;
          }
          return item;
        });
      });
    }
  };

  const onApplyChangesToAPClick = () => {
    setApplyChangesToAP(!applyChangesToAP);
    accountsReceivableData.forEach(ar => {
      setAccountsPayableData(prevData => {
        return prevData.map(item => {
          if (item.staff_order_id === ar.staff_order_id) {
            let newData = {
              ...item,
              decimal_places: ar.decimal_places,
              uom: ar.uom,
            };
            return newData;
          }
          return item;
        });
      });
    });
  };

  return (
    <Modal show={show} hide={() => setShow(false)} title="How should we prepare the invoices?" size={'lg'}>
      <div>
        <p>The amount of days worked contains more than two decimal places. Please select how you want to format the units.</p>
        <DividerWithLabel label="Accounts receivable" className="my-4" />

        <div className={`px-2 border border-gray-300 rounded-md my-2`}>
          <table className="table-auto w-full h-max my-2 px-4">
            <thead>
              <tr className="text-md border-dashed my-1 h-8">
                <th className="text-center">Purchase order</th>
                <th className="text-center">Units</th>
                <th className="text-center">Decimals</th>
                <th className="text-center">UoM</th>
                <th className="text-center">Price</th>
                <th className="text-center">Excl. VAT</th>
                <th className="text-center">VAT</th>
              </tr>
            </thead>
            <tbody>
              {accountsReceivableData.map((so, index) => {
                const units = so.uom === 'hours' ? so.hours_worked : roundNumber(so.hours_worked / 8, false, so.decimal_places);
                const rate = so.uom === 'hours' ? roundNumber(so.daily_rate / 8) : so.daily_rate;
                const total = units * rate;
                const vat = (total * so.vat_rate) / 100;
                return (
                  <tr className="text-md border-dashed my-1 h-8">
                    <td className="text-center">{so.order_ref}</td>
                    <td className="text-center">{roundNumber(units, true, so.decimal_places)}</td>
                    <td className="text-center w-8 px-2">
                      <InputSimple
                        type="number"
                        max={6}
                        min={2}
                        value={so.decimal_places}
                        onChange={e => onChangeDecimals(e, index, 'ar', so.staff_order_id)}
                        onKeyDown={e => e.preventDefault()}
                        disabled={so.uom === 'hours'}
                      />
                    </td>
                    <td className="text-center w-24 px-1">
                      <div className="mb-1">
                        <ReactSelect
                          options={uomOptions}
                          onChange={e => onChangeUoM(e, index, 'ar', so.staff_order_id)}
                          selectedOptionsIds={[so.uom]}
                        />
                      </div>
                    </td>
                    <td className="text-center">{formatCurrency(rate)}</td>
                    <td className="text-center">{formatCurrency(total)}</td>
                    <td className="text-center">{formatCurrency(vat)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Checkbox
          title="Apply changes to Accounts Payable lines"
          labelTitle
          value={applyChangesToAP}
          horizontal
          onChange={e => onApplyChangesToAPClick()}
          className="my-2"
        />
        <DividerWithLabel label="Accounts payable" className="my-4" />
        <div className={`px-2 border border-gray-300 rounded-md my-2`}>
          <table className="table-auto w-full h-max my-2 px-4">
            <thead>
              <tr className="text-md border-dashed my-1 h-8">
                <th className="text-center">Purchase order</th>
                <th className="text-center">Units</th>
                <th className="text-center">Decimals</th>
                <th className="text-center">UoM</th>
                <th className="text-center">Cost</th>
                <th className="text-center">Excl. VAT</th>
                <th className="text-center">VAT</th>
              </tr>
            </thead>
            <tbody>
              {accountsPayableData.map((so, index) => {
                const units = so.uom === 'hours' ? so.hours_worked : roundNumber(so.hours_worked / 8, false, so.decimal_places);
                const rate = so.uom === 'hours' ? roundNumber(so.daily_rate / 8) : so.daily_rate;
                const total = units * rate;
                const vat = (total * so.vat_rate) / 100;
                return (
                  <tr className="text-md border-dashed my-1 h-8">
                    <td className="text-center">{so.order_ref}</td>
                    <td className="text-center">{roundNumber(units, true, so.decimal_places)}</td>
                    <td className="text-center w-8 px-2">
                      <InputSimple
                        type="number"
                        max={6}
                        min={2}
                        value={so.decimal_places}
                        onChange={e => onChangeDecimals(e, index, 'ap')}
                        onKeyDown={e => e.preventDefault()}
                        disabled={
                          (applyChangesToAP && accountsReceivableData.find(el => el.staff_order_id === so.staff_order_id)) ||
                          so.uom === 'hours'
                        }
                      />
                    </td>
                    <td className="text-center w-24 px-1">
                      <div className="mb-1">
                        <ReactSelect
                          options={uomOptions}
                          onChange={e => onChangeUoM(e, index, 'ap')}
                          selectedOptionsIds={[so.uom]}
                          disabled={
                            applyChangesToAP && accountsReceivableData.find(el => el.staff_order_id === so.staff_order_id)
                          }
                        />
                      </div>
                    </td>
                    <td className="text-center">{formatCurrency(rate)}</td>
                    <td className="text-center">{formatCurrency(total)}</td>
                    <td className="text-center">{formatCurrency(vat)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex w-full justify-end gap-x-4">
          <ButtonWhite text="Cancel" onClick={() => setShow(false)} />
          <ButtonPrimary text="Proceed" onClick={() => onProceed()} />
        </div>
      </div>
    </Modal>
  );
};

export default FinancialDataFormat;
