import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import useApi from '../../../../hooks/useApi';
import { CancelTokenSource } from 'axios';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import AdminManager from '../AdminManager';
import Table from '../../../../containers/ServerSideTable';
import AdminSidebar from '../../AdminSidebar';

function BankAccounts() {
  const {
    bankAccounts: { getBankAccountsPaginated },
  } = useApi();

  const history = useHistory();

  const [bankAccounts, setBankAccounts] = useState({});
  const fetchBankAccounts = async (page, filters, sortByArray, globalFilter, downloadToCsv, source) => {
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (downloadToCsv) {
      let csvData = [];
      await getBankAccountsPaginated(page + 1, sortBy, globalFilter, downloadToCsv).then(res => {
        res.forEach(account => {
          csvData.push({
            id: account.id,
            name: account.name,
            iban: account.iban,
            bic_swift: account.bic_swift,
          });
        });
      });
      return csvData;
    }

    getBankAccountsPaginated(page + 1, sortBy, globalFilter, null, source).then(res => {
      setBankAccounts(res);
    });
  };

  const tableData = useMemo(() => {
    let _tableData = [];
    if (bankAccounts && bankAccounts?.rows?.length) {
      bankAccounts?.rows?.map(e => {
        _tableData.push({
          id: e?.id,
          name: e?.name,
          iban: e?.iban,
          bic_swift: e?.bic_swift,
        });
      });
    }
    return _tableData;
  }, [bankAccounts]);

  const columns = useMemo(() => {
    const _columns = [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'IBAN',
        accessor: 'iban',
        disableFilters: true,
      },
      {
        Header: 'BIC-SWIFT',
        accessor: 'bic_swift',
        disableFilters: true,
      },
    ];
    return _columns;
  }, []);

  const pages = [
    { name: 'Admin Manager', href: '/admin-panel/admin/clients', current: false },
    { name: `Bank Accounts`, href: '/admin-panel/admin/bank-accounts', current: false },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/admin/bank-accounts/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/admin/bank-accounts/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/admin/bank-accounts/create',
  };
  const rowOnClick = row => {
    history.push(`/admin-panel/admin/bank-accounts/${row.values.id}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        columns={columns}
        data={tableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        addButton={addButton}
        rowOnClick={rowOnClick}
        pageCount={bankAccounts?.totalPages}
        fetchData={fetchBankAccounts}
        totalItems={bankAccounts.totalItems}
        tableName="bankAccounts"
      />
    </AdminSidebar>
  );
}

export default BankAccounts;
