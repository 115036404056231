import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputSimple from '../../components/Inputs/InputSimple';
import TwoColumnForm from '../../components/Layouts/TwoColumnForm';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import { Controller, useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../utils/hooks/useYupValidationResolver';
import * as Yup from 'yup';
import { VALIDATION_MSG } from '../../helpers/enum/errorValidationMsgs';
import useApi from '../../hooks/useApi';
import extractObjectDifferences from '../../helpers/extractObjectDifferences';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../store/app/actions';
import notifications from '../../helpers/enum/notifications';
import ReactSelect from '../../components/Inputs/ReactSelect';
import countryListWithIds from '../../helpers/countryListWithIds';
function AddCountry({ countries, id, handleSave }) {
  const history = useHistory();
  const {
    countries: { deleteCountry },
  } = useApi();
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState(false);

  const validationSchema = Yup.object();

  const formOptions = { resolver: useYupValidationResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    if (id && id !== 'create') {
      countries.length &&
        countries.forEach(p => {
          if (p.id == id) {
            setValue('countryId', id);
          }
        });
    }
  }, [id, countries]);

  const handleRemoveClick = () => {
    deleteCountry(id)
      .then(d => {
        history.goBack();
        setShowAlert(false);
      })
      .catch(e => {
        setError(e.response.data.error.message);
        setShowAlert(false);
        // setShowAlert(e.response.data.message);
      });
  };

  const onSubmit = data => {
    // let dataToSend = data;
    // if (id != 'create') {
    //   dataToSend = extractObjectDifferences(
    //     data,
    //     countries.find(country => country.id == id),
    //   );
    // }
    // if (!dataToSend) {
    //   dispatch(
    //     setNotification(notifications.WARNING_YELLOW, 'Unable to update Country', 'No changes to the country were detected.'),
    //   );
    // }
    handleSave(data);
  };

  function pressEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  return (
    <TwoColumnForm
      onClick={handleSubmit(onSubmit)}
      button={id === 'create'}
      buttonText={'Save'}
      label="Country Info"
      description=""
      onClickRed={() => setShowAlert(true)}
      buttonRed={id && id !== 'create' ? true : false}
      buttonRedText={'Delete'}
    >
      <SimpleAlert
        errorTitle="Warning"
        errorMsg="Removing this will completely remove the country, and will delete it from the application. Are you sure you want to proceed?"
        onAcceptText="Proceed"
        onAcceptClick={handleRemoveClick}
        onDeclineText="Cancel"
        show={showAlert}
        hide={() => setShowAlert(false)}
      />
      <SimpleAlert errorTitle="Error" errorMsg={error} onAcceptText="Ok" show={error} hide={() => setError(false)} />
      <dl className="grid grid-cols-1 gap-y-6 sm:grid-cols-1">
        <Controller
          control={control}
          name={'countryId'}
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              isSearchable
              options={countryListWithIds.filter(country => !countries.some(c => c.id == country.value))}
              selectedOptions={countryListWithIds.find(country => country.value == value)}
              label={'Country'}
              onChange={e => onChange(e.value)}
              disabled={id !== 'create'}
            />
          )}
        />
      </dl>
    </TwoColumnForm>
  );
}

export default AddCountry;
