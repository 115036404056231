import React from 'react';

function ButtonSecondary(props) {
  const BUTTON_STYLES = {
    minWidth: '140px',
  };
  let sizeStyle;

  switch (props.size) {
    case 'xs':
      sizeStyle = 'px-2.5 py-1.5 text-xs rounded ';
      break;
    case 'sm':
      sizeStyle = 'px-3 py-2 text-sm leading-4 rounded-md';
      break;
    case 'md':
      sizeStyle = 'px-4 py-2 text-sm rounded-md ';
      break;
    case 'lg':
      sizeStyle = 'px-4 py-2 text-base rounded-md ';
      break;
    case 'xl':
      sizeStyle = 'px-6 py-3 text-base rounded-md';
      break;
    default:
      sizeStyle = 'px-4 py-2 text-sm rounded-md ';
      break;
  }

  return (
    <button
      onClick={props.onClick}
      style={BUTTON_STYLES}
      type="button"
      className={`${
        props.style ? props.style : ''
      } ${sizeStyle} inline-flex justify-center border border-transparent font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-blue-300 
      ${
        props.disable
          ? 'text-gray-400 border-4 border-gray-400'
          : 'text-thaleria-blue-700 bg-thaleria-blue-50 hover:bg-thaleria-blue-100'
      }`}
    >
      {props.icon}
      {props.text}
    </button>
  );
}

export default ButtonSecondary;
