import React, { useEffect, useMemo, useState } from 'react';
import PageWrapperV2 from '../../../containers/App/PageWrapper';
import TimesheetDayAllocationModal from '../../../containers/Timesheets/TimesheetDayAllocationModal';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import TimesheetCalendar from '../../../containers/Timesheets/TimesheetCalendar';
import Attachments from '../../../containers/Attachments/TimesheetAttachments';
import { downloadBase64File } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import Chat from '../../../containers/Chat/Chat';
import { TimeSheetStatus, TimeSheetStatusLabels } from '../../../helpers/enum/timeSheet';
import TimesheetCalendarGuide from '../../../containers/Timesheets/TimesheetCalendarGuide';
import RequestChange from '../../../containers/Timesheets/RequestChange';
import { LeaveRequestStatus } from '../../../helpers/enum/leaveRequest';
import { MONTH_NAMES } from '../../../helpers/date';
import { showErrorNotification, showSuccessNotification } from '../../../store/app/actions';
import useApi from '../../../hooks/useApi';
import PreviewBox from '../../../containers/PDFViewer/PreviewBox';
import contractTypes from '../../../helpers/enum/contractTypeIDs';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import PreviousAndNext from '../../../containers/Timesheets/PreviousAndNext';
import RemainingDays from '../../../containers/Timesheets/RemainingDays';
import { isAfter, parse } from 'date-fns';
import months from '../../../helpers/enum/months';
import WarningText from '../../../components/Warnings/WarningText';
import format from 'date-fns/format';
import * as moment from 'moment';
import Loader from '../../../components/Loading/Loader';

function TimesheetDetails() {
  const {
    files: { getFilesOnFolderForTimesheets, downloadTimesheetFile, deleteTimesheetFile },
    holidays: { getMyHolidaysForTimesheets },
    timesheets: {
      getMyTimeSheet,
      uploadTimesheetFile,
      addComment,
      updateTimesheetStatus,
      getMyTimesheetEntries,
      calculateDaysRemainingUntilDate,
    },
    leaveRequests: { getAllMyLeaveRequests },
    purchaseOrders: { getOrdersForTimesheet },
  } = useApi();
  const dispatch = useDispatch();
  const { id } = useParams();

  const currentUser = useSelector(state => state.auth.currentUser);
  const [draftTimesheets, setDraftTimesheets] = useState([]);
  const [signedTimesheets, setSignedTimesheets] = useState([]);
  const [invoice, setInvoice] = useState();
  const [timesheetEntries, setTimeSheetEntries] = useState([]);
  const [showDayAllocationModal, setShowDayAllocationModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [submitTimesheetModal, setSubmitTimesheetModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [timeSheet, setTimeSheet] = useState({
    id: null,
    year: null,
    month: null,
    status: null,
    uuid: null,
  });

  const [isLoadingEntries, setIsLoadingEntries] = useState(true);
  const [isLoadingStaffOrders, setIsLoadingStaffOrders] = useState(true);
  const [isLoadingHolidays, setIsLoadingHolidays] = useState(true);
  const [isLoadingLeaveRequests, setIsLoadingLeaveRequests] = useState(true);

  const isLoading = useMemo(() => {
    let _loading = true;
    if (!isLoadingEntries && !loadingFolder && !isLoadingHolidays && !isLoadingLeaveRequests && !isLoadingStaffOrders)
      _loading = false;
    return _loading;
  }, [isLoadingEntries, loadingFolder, isLoadingHolidays, isLoadingLeaveRequests, isLoadingStaffOrders]);
  const [downloading, setDownloading] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [message, setMessage] = useState('');
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
  const [userStaffOrders, setUserStaffOrders] = useState([]);
  const [activeStaffOrders, setActiveStaffOrders] = useState([]);
  const [shouldBeSubmittedMessages, setShouldBeSubmittedMessages] = useState({
    alert: '',
    flag: '',
  });
  const [showSubmitAnimation, setShowSubmitAnimation] = useState(false);

  const shouldBeSubmitted = useMemo(() => {
    let _shouldBeSubmitted = false;
    const endDate = new Date(timeSheet.year, timeSheet.month - 1, 31);
    if (timeSheet.status === TimeSheetStatus.PENDING) {
      if (timesheetEntries.length) {
        _shouldBeSubmitted = true;
        setShouldBeSubmittedMessages({
          alert: "You've added allocations but haven't submitted your timesheet. Are you sure you want to leave this page?",
          flag: "This timesheet has allocations but is still Pending. Make sure to click Submit once you've finished adding allocations and uploaded the necessary documents.",
        });
      }

      if (isAfter(new Date(), endDate)) {
        _shouldBeSubmitted = true;
        setShouldBeSubmittedMessages({
          alert:
            'Your timesheet should be submitted as soon as possible and is still Pending. Are you sure you want to leave this page?',
          flag: `Your ${
            months[timeSheet.month - 1]
          } timesheet needs to be submitted by the end of the month. Your signed timesheet can be uploaded until the 7th of ${
            months[timeSheet.month]
          }. Please click Submit once you've finished adding allocations and uploaded the necessary documents.`,
        });
      }
    }
    return _shouldBeSubmitted;
  }, [timeSheet, timesheetEntries]);

  const prepareChatData = res => {
    const messages = res.data.map(message => {
      const momentOfPublish = new Date(message.created_at);
      return {
        id: message.id,
        ownerName: message.user.first_names,
        ownerImg: message.user.profilepic_url,
        ownerId: message.user_id,
        text: message.comment,
        time: momentOfPublish.toLocaleString('en-us'),
      };
    });

    return messages;
  };

  const onMessageChange = e => setMessage(e.target.value);

  const sendMessageHandler = () => addComment({ timesheet_id: id, comment: message }).then(res => setMessage(''));

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // alertUser();
    };
  });

  const handleBeforeUnload = e => {
    if (shouldBeSubmitted) {
      setShowSubmitAnimation(true);
      e.preventDefault();
      e.returnValue = '';
    } else {
      return;
    }
  };

  useEffect(() => {
    setLoadingFolder(true);
    setIsLoadingStaffOrders(true);
    setIsLoadingHolidays(true);
    setIsLoadingLeaveRequests(true);
    getMyTimeSheet(id).then(timeSheetResponse => {
      setShowSubmitAnimation(false);
      setTimeSheet({
        id: timeSheetResponse?.id,
        month: timeSheetResponse?.month,
        year: timeSheetResponse?.year,
        status: timeSheetResponse?.status,
        uuid: timeSheetResponse?.uuid,
        invoice_approved: timeSheetResponse?.accountsPayable?.approved,
        invoice_uploaded: timeSheetResponse?.invoice_uploaded,
        invoice_comment: timeSheetResponse?.invoice_comment,
      });
      const tsMonth = timeSheetResponse.month < 10 ? '0' + timeSheetResponse.month : timeSheetResponse.month;
      const timesheetDate = new Date(`${timeSheetResponse.year}-${tsMonth}`);
      const startDate = format(timesheetDate, 'yyyy-MM-dd');
      const endDate = format(endOfMonth(timesheetDate), 'yyyy-MM-dd');

      getAllMyLeaveRequests(startDate, endDate, [
        LeaveRequestStatus.actionRequired,
        LeaveRequestStatus.approved,
        LeaveRequestStatus.submitted,
        LeaveRequestStatus.pending,
      ])
        .then(res => {
          const leaveRequests = res.map(leaveRequest => ({
            id: leaveRequest?.id,
            status: leaveRequest?.status,
            startDate: leaveRequest?.start_date,
            endDate: leaveRequest?.end_date,
            endHalfDay: leaveRequest?.end_half_day,
            startHalfDay: leaveRequest?.start_half_day,
            type: leaveRequest?.request_type,
          }));
          setLeaveRequests(leaveRequests);

          getFilesOnFolderForTimesheets(`timesheet/${timeSheetResponse?.uuid}`)
            .then(res => {
              const data = res.data.map(res => ({ ...res, id: res.key, resource: res.key }));
              setSignedTimesheets(data.filter(e => e?.isSignedTimesheet));
              setDraftTimesheets(data.filter(e => e?.isDraftTimesheet));
              setInvoice(data.find(e => e?.isInvoice));
              setDocuments(data.filter(e => e?.isOther));
              setLoadingFolder(false);
            })
            .catch(err => {
              setLoadingFolder(false);
            });
          setIsLoadingLeaveRequests(false);
        })
        .catch(err => {
          setIsLoadingLeaveRequests(false);
          return err;
        });
      getMyHolidaysForTimesheets(startDate, endDate)
        .then(res => {
          setHolidays(res.data);
          setIsLoadingHolidays(false);
        })
        .catch(err => {
          setIsLoadingHolidays(false);
        });
    });
  }, [id]);

  useEffect(() => {
    if (!currentUser || !timeSheet) return;

    getOrdersForTimesheet(currentUser.id)
      .then(async d => {
        setUserStaffOrders(d);
        if (timeSheet) {
          const timesheetStart = startOfMonth(new Date(timeSheet.year, timeSheet.month - 1));
          const timesheetEnd = endOfMonth(new Date(timeSheet.year, timeSheet.month - 1));
          let activeStaffOrders = d.filter(so => {
            return (
              new Date(so.order_start) <= timesheetEnd && (so.order_end === null || new Date(so.order_end) >= timesheetStart)
            );
          });
          for (let [index, so] of activeStaffOrders.entries()) {
            const calculatedDays = await calculateDaysRemainingUntilDate(
              so.id,
              new Date(`${timeSheet.year}/${timeSheet.month}/01`),
            );
            if (activeStaffOrders[index].days_ordered) {
              activeStaffOrders[index].daysRemainingUntilThisMonth = calculatedDays.daysRemainingUntilThisDate;
            } else {
              activeStaffOrders[index].daysConsumedUntilThisMonth = calculatedDays.daysConsumedUntilThisDate;
            }
          }
          setActiveStaffOrders(activeStaffOrders);
          setIsLoadingStaffOrders(false);
        }
      })
      .catch(err => {
        setIsLoadingStaffOrders(false);
      });
  }, [currentUser, timeSheet]);

  useEffect(() => {
    if (!isLoadingEntries) setIsLoadingEntries(true);
    getMyTimesheetEntries(id)
      .then(res => {
        setTimeSheetEntries(res.data);
        setIsLoadingEntries(false);
      })
      .catch(err => {
        setIsLoadingEntries(false);
      });
  }, [id, showDayAllocationModal]);

  const _onDayClick = (days, entries, month, year) => {
    if (timeSheet.status === TimeSheetStatus.CHANGESREQUESTED || timeSheet.status === TimeSheetStatus.PENDING) {
      let date = days.map(d => year + '-' + month + '-' + d);
      let allocationsEntries = [];
      entries.map(e => {
        if (days.some(day => day === dayjs(e.date).get('date'))) {
          allocationsEntries.push(e);
        }
      });

      setAllocations(allocationsEntries);
      setSelectedDays(date);
      setShowDayAllocationModal(true);
    }
  };

  const closeModal = () => {
    setShowDayAllocationModal(false);
  };

  const handleSubmit = () => {
    setSubmitTimesheetModal(true);
  };

  const handleCancelSubmit = () => {
    updateTimesheetStatus({ timesheetID: id, status: TimeSheetStatus.PENDING }).then(res => {
      setTimeSheet(prev => ({ ...prev, status: TimeSheetStatus.PENDING }));
      dispatch(showSuccessNotification('Submission cancelled successfully'));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  };

  const submit = () => {
    updateTimesheetStatus({ timesheetID: id, status: TimeSheetStatus.SUBMITTED }).then(res => {
      setTimeSheet(prev => ({ ...prev, status: TimeSheetStatus.SUBMITTED }));
      dispatch(showSuccessNotification('Submission submitted successfully'));
      setSubmitTimesheetModal(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  const uploadFileHandler = async (fileInfo, type, invoiceIsAMatch, optionalComment) => {
    switch (type) {
      case 'signedTimesheets':
        if (signedTimesheets.find(st => st.name === fileInfo?.name)) {
          setUploading(false);
          return setShowDuplicateAlert(fileInfo?.name);
        }
        break;
      case 'draftTimesheets':
        if (draftTimesheets.find(dt => dt.name === fileInfo?.name)) {
          setUploading(false);
          return setShowDuplicateAlert(fileInfo?.name);
        }
        break;
      case 'invoice':
        break;
      default:
        if (documents.find(doc => doc.name === fileInfo?.name)) {
          setUploading(false);
          return setShowDuplicateAlert(fileInfo?.name);
        }
        break;
    }
    const tags = {};
    if (type === 'invoice') {
      tags.invoiceMatches = invoiceIsAMatch;
      if (optionalComment !== '') tags.comment = optionalComment;
    }
    uploadTimesheetFile(fileInfo, timeSheet.uuid, type, tags)
      .then(response => {
        const newFile = {
          ...response.data,
          id: response.key,
          resource: response.key,
        };
        switch (type) {
          case 'signedTimesheets':
            setSignedTimesheets(prev => [...prev, newFile]);
            break;
          case 'draftTimesheets':
            setDraftTimesheets(prev => [...prev, newFile]);
            break;
          case 'invoice':
            //Invoice matches needs to be turned to string to replicate the way it comes from S3
            newFile.invoiceMatches = tags.invoiceMatches.toString();
            if (tags?.comment) setTimeSheet(prev => ({ ...prev, invoice_comment: tags?.comment }));
            setInvoice(newFile);
            break;
          default:
            setDocuments(prev => [...prev, newFile]);
            break;
        }
        setUploading(false);
      })
      .catch(err => {
        setUploading(false);
      });
  };

  const previewHandler = id => {
    let file;
    file = [...draftTimesheets, ...signedTimesheets, invoice].find(doc => doc?.id === id);
    if (!file) file = documents.find(document => document.id == id);
    downloadTimesheetFile(file.resource)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          url: response.data,
          show: true,
          fileType: file.resource.split('.')[file.resource.split('.').length - 1],
          title: file.resource.split('.')[file.resource.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const downloadFileHandler = id => {
    let file;
    file = [...draftTimesheets, ...signedTimesheets, invoice].find(doc => doc?.id === id);
    if (!file) file = documents.find(document => document.id == id);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadTimesheetFile(file.resource)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const removeFileHandler = id => {
    if (timeSheet.status === TimeSheetStatus.APPROVED && id !== invoice.id)
      showErrorNotification('Cannot delete files when timesheet is approved.');
    deleteTimesheetFile(id)
      .then(response => {
        if (documents.find(document => document.id === id)) {
          setDocuments(preDocuments => [...preDocuments.filter(document => document.id !== id)]);
        }
        if (signedTimesheets.find(signedTimesheet => signedTimesheet.id === id)) {
          setSignedTimesheets(prev => [...prev.filter(st => st.id !== id)]);
        }
        if (draftTimesheets.find(draftTimesheet => draftTimesheet.id === id)) {
          setDraftTimesheets(prev => [...prev.filter(dt => dt.id !== id)]);
        }
        if (invoice?.id === id) setInvoice();
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  const shouldShowSubmitButton =
    timeSheet.status == TimeSheetStatus.PENDING || timeSheet.status == TimeSheetStatus.CHANGESREQUESTED;
  const shouldShowCancelSubmitButton =
    timeSheet.status == TimeSheetStatus.SUBMITTED || timeSheet.status == TimeSheetStatus.CHANGESREQUESTED;

  const pages = [
    { name: 'Timesheets', href: '/timesheets', current: false },
    { name: `${MONTH_NAMES[timeSheet.month - 1]} ${timeSheet.year}`, href: '', current: true },
  ];

  return (
    <PageWrapperV2 pages={pages}>
      <Prompt
        when={shouldBeSubmitted}
        message={() => {
          setShowSubmitAnimation(true);
          return shouldBeSubmittedMessages.alert;
        }}
      />
      <TimesheetDayAllocationModal
        timesheetID={id}
        selectedDays={selectedDays}
        staffOrders={userStaffOrders}
        allocations={allocations}
        onClose={closeModal}
        show={showDayAllocationModal}
      />
      <SimpleAlert
        errorTitle={'Unable to upload file'}
        errorMsg={`${showDuplicateAlert && showDuplicateAlert} file name already exists on this folder`}
        onAcceptClick={() => setShowDuplicateAlert(false)}
        onAcceptText={'Close'}
        show={showDuplicateAlert != false}
        hide={() => setShowDuplicateAlert(false)}
      />
      <PreviewBox
        filePath={showPreview.url}
        fileType={showPreview.fileType}
        showPreview={showPreview.show}
        handleHide={() => setShowPreview(false)}
        title={showPreview.title}
      />
      <SimpleAlert
        errorTitle="Upload Timesheet"
        errorMsg="I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief
              and I undertake to inform you of any changes therein, immediately. In case any of the above information is found
              to be false or untrue or misleading or misrepresenting, I am aware that I may be held liable for it."
        onAcceptText="Proceed"
        onAcceptClick={submit}
        onDeclineText="Cancel"
        show={submitTimesheetModal}
        hide={() => setSubmitTimesheetModal(false)}
      />
      <Loader isLoading={isLoading}>
        <div className="flex flex-col space-y-6">
          {shouldBeSubmitted && (
            <WarningText
              className={`mb-4 `}
              text={shouldBeSubmittedMessages.flag}
              iconClassName={`shrink-0 ${showSubmitAnimation ? 'animate-ping' : ''}`}
            />
          )}
          {timeSheet.status === TimeSheetStatus.CHANGESREQUESTED && <RequestChange />}
          <div className="flex flex-row flex-wrap w-full items-center justify-between mb-4 space-y-2 sm:space-y-0">
            <div className="flex flex-col flex-wrap items-start justify-between">
              <div className="mb-2 flex w-">
                <span className="text-lg font-bold text-gray-800">Timesheet</span>
                <div className="flex justify-start space-x-2 ">
                  <span className="ml-3 text-lg text-gray-500 font-normal">
                    {MONTH_NAMES[timeSheet.month - 1] + ' ' + timeSheet.year}
                  </span>
                  <PreviousAndNext userId={currentUser.id} month={timeSheet.month} year={timeSheet.year} />
                </div>
              </div>
              <div>
                <span className="text-lg font-bold text-gray-800">Status</span>
                <span className="text-lg text-gray-500 ml-3 font-normal ">{TimeSheetStatusLabels[timeSheet.status]}</span>
              </div>
            </div>
            <div className="flex gap-x-2 justify-center sm:justify-end w-full sm:w-max ">
              {shouldShowCancelSubmitButton && <ButtonPrimary size="xl" onClick={handleCancelSubmit} text="Cancel submission" />}
              {shouldShowSubmitButton && <ButtonPrimary size="xl" onClick={handleSubmit} text="Submit" />}
            </div>
          </div>
          <div className="flex justify-center w-full ">
            <TimesheetCalendarGuide />
          </div>
          <Loader isLoading={isLoadingEntries}>
            <TimesheetCalendar
              holidays={holidays}
              leaveRequests={leaveRequests}
              timesheetID={id}
              onDayClick={_onDayClick}
              month={timeSheet.month}
              year={timeSheet.year}
              timesheetEntries={timesheetEntries}
              setTimesheetEntries={setTimeSheetEntries}
              isApprovedOrPreApproved={
                timeSheet.status === TimeSheetStatus.APPROVED ||
                timeSheet.status === TimeSheetStatus.PREAPPROVE ||
                timeSheet.status === TimeSheetStatus.SUBMITTED
                  ? true
                  : false
              }
              activeStaffOrders={activeStaffOrders}
            />
          </Loader>
          <div className="grid grid-cols-2 gap-x-6 gap-y-6">
            {activeStaffOrders.length ? (
              <div className="col-span-2">
                <RemainingDays timesheetEntries={timesheetEntries} staffOrders={activeStaffOrders} />
              </div>
            ) : (
              ''
            )}
            <div className="col-span-2 lg:col-span-1">
              <Attachments
                uploadHandler={uploadFileHandler}
                maxFileSizeInMb={10}
                downloadHandler={downloadFileHandler}
                removeHandler={removeFileHandler}
                documents={documents}
                deleting={deleting}
                setDeleting={setDeleting}
                downloading={downloading}
                preparingPreview={preparingPreview}
                setPreparingPreview={setPreparingPreview}
                previewHandler={previewHandler}
                setDownloading={setDownloading}
                loadingFolder={loadingFolder}
                uploading={uploading}
                setUploading={setUploading}
                enableAdd={
                  timeSheet.status === TimeSheetStatus.SUBMITTED || timeSheet.status === TimeSheetStatus.APPROVED ? false : true
                }
                enableRemove={
                  timeSheet.status === TimeSheetStatus.APPROVED ||
                  timeSheet.status === TimeSheetStatus.PREAPPROVE ||
                  timeSheet.status === TimeSheetStatus.SUBMITTED
                    ? false
                    : true
                }
                draftTimesheets={draftTimesheets}
                signedTimesheets={signedTimesheets}
                invoice={invoice}
                isFreelancerOrCompany={
                  activeStaffOrders.filter(
                    so =>
                      so?.staffContract?.contract_type_id === contractTypes.freelancer ||
                      so?.staffContract?.contract_type_id === contractTypes.company,
                  ).length
                }
                timesheetId={timeSheet?.id}
                timesheetStatus={timeSheet?.status}
                enableAddInvoice={timeSheet.status === TimeSheetStatus.SUBMITTED ? false : true}
                enableRemoveInvoice={timeSheet.invoice_approved ? false : true}
                invoiceComment={timeSheet.invoice_comment}
                activeStaffOrders={activeStaffOrders.map(so => {
                  return {
                    id: so?.id,
                  };
                })}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <Chat
                url={`/timesheet-comments/${id}`}
                messageText={message}
                prepare={prepareChatData}
                messageOnChange={onMessageChange}
                sendMessage={sendMessageHandler}
              />
            </div>
          </div>
          {shouldShowSubmitButton && <ButtonPrimary size="xl" onClick={handleSubmit} text="Submit" />}
          {shouldShowCancelSubmitButton && <ButtonPrimary size="xl" onClick={handleCancelSubmit} text="Cancel submission" />}
        </div>
      </Loader>
    </PageWrapperV2>
  );
}

export default TimesheetDetails;
