import React, { useState, useMemo, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import { useHistory, useLocation } from 'react-router-dom';
import formatCurrency from '../../utils/formatCurrency';
import { ExternalLinkIcon, EyeIcon, PlusCircleIcon } from '@heroicons/react/outline';
import CreditNotePDFPreview from '../../containers/Finance/CreditNotes/CreditNotePDFPreview';
import AccountsReceivablePDFPreview from '../../containers/Finance/AccountsReceivable/AccountsReceivablePDFPreview';
import ButtonRound from '../../components/Buttons/ButtonRound';

const InvoiceBalance = ({ accountsReceivableId }) => {
  const [invoicePreview, setInvoicePreview] = useState(false);
  const [creditNotePreview, setCreditNotePreview] = useState(false);
  const today = new Date();

  const history = useHistory();

  const [creditNotePreviewData, setCreditNotePreviewData] = useState({
    accounts_receivable_id: null,
    credit_note_number: null,
    client_id: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    amount: null,
    vat: null,
    amount_and_vat: null,
    sent: null,
    paid: null,
    metadata: {
      to: {
        name: null,
        address: null,
        postal_code: null,
        city: null,
        vat_nb: null,
      },
      from: {
        name: null,
        address: null,
        postal_code: null,
        city: null,
        vat_nb: null,
      },
      subject: 'Thaleria credit Note',
      header_comment: `Please find below the details of the items and amounts subject to this credit Note and containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: ``,
      footer_data: {
        company: null,
        email: null,
        phone: null,
        vat_nb: null,
        iban: null,
        bic_swift: null,
      },
      showReferences: {
        frameworkContract: true,
        partnerContract: true,
        salesOrder: true,
        specificContract: true,
      },
    },
    lineItems: [],
  });

  const [creditNotes, setCreditNotes] = useState([]);
  const [invoice, setInvoice] = useState({
    invoice_number: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    due_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + 30)),
    client_id: null,
    revenue: null,
    vat: null,
    revenue_and_vat: null,
    sent: false,
    paid: null,
    bank_account_id: null,
    metadata: {
      to: {
        name: '',
        address: '',
        postal_code: '',
        city: '',
        country: '',
        vat_number: '',
      },
      from: {
        name: 'Thaleria S.A.',
        address: 'Avenue des Arts 56',
        postal_code: '1000',
        city: 'Brussels',
        country: 'Belgium',
        vat_number: 'BE 0704.867.821',
      },
      subject: 'Thaleria services',
      header_comment: `Please find below the details of your invoice containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: `Please pay the amount of {{total_incl_vat}} to the account {{receivables_iban}} by {{due_date}}.`,
      footer_data: {
        name: 'Thaleria S.A.',
        email: 'finance@thaleria.com',
        phone: '+32 474 95 74 11',
        vat_number: 'BE 0704.867.821',
        iban: '',
        bic_swift: '',
      },
      showReferences: {
        frameworkContract: true,
        partnerContract: true,
        salesOrder: true,
        specificContract: true,
      },
    },
    lineItems: [],
  });

  const {
    accountsReceivables: { getAccountsReceivableByInvoiceNb },
  } = useApi();

  useEffect(() => {
    if (accountsReceivableId) {
      getAccountsReceivableByInvoiceNb(accountsReceivableId).then(data => {
        const _invoiceData = {
          id: data.id,
          invoice_number: data.invoice_number,
          client_id: data.client_id,
          client: data.client,
          overdue: data.overdue,
          issue_date: new Date(data.issue_date),
          due_date: new Date(data.due_date),
          revenue: data.revenue,
          vat: data.vat,
          revenue_and_vat: data.revenue_and_vat,
          adjusted_revenue: data.adjusted_revenue,
          adjusted_vat: data.adjusted_vat,
          adjusted_revenue_and_vat: data.adjusted_revenue_and_vat,
          paid_amount: data.paid_amount,
          sent: data.sent,
          paid: data.paid,
          bank_account_id: data.bank_account_id,
          metadata: JSON.parse(data.metadata),
        };
        const _lineItems = data.lineItems.map(l => {
          return {
            id: l.id,
            description: l.description,
            type: l.type,
            staff_order_id: l.staff_order_id,
            framework_contract_id: l.framework_contract_id,
            partner_contract_id: l.partner_contract_id,
            specific_contract_id: l.specific_contract_id,
            sales_order_id: l.sales_order_id,
            timesheet_id: l.timesheet_id,
            units: l.units,
            uom: l.uom,
            price: l.price,
            vat_rate: l.vat_rate,
            user_id: l.user_id,
            bank_account_id: l.salesFwc.bank_account_id,
            metadata: JSON.parse(l.metadata),
          };
        });

        const _creditNotes = data.creditNotes.map(cn => ({
          ...cn,
          issue_date: new Date(cn.issue_date),
          metadata: JSON.parse(cn.metadata),
          lineItems: cn.lineItems.map(item => ({ ...item, metadata: JSON.parse(item.metadata) })),
        }));

        setInvoice({ ..._invoiceData, lineItems: _lineItems });
        setCreditNotes(_creditNotes);
      });
    }
  }, [accountsReceivableId]);

  const location = useLocation();
  const type = location.pathname.split('/')[location.pathname.split('/').length - 2];
  const id = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const previewInvoiceHandler = () => {
    setInvoicePreview(true);
  };

  const previewCreditNoteHandler = id => {
    setCreditNotePreviewData(creditNotes.find(cn => cn.id === Number(id)));
    setCreditNotePreview(true);
  };

  return (
    <div className="mt-2">
      <CreditNotePDFPreview
        showPreview={creditNotePreview}
        setShowPreview={setCreditNotePreview}
        creditNoteData={creditNotePreviewData}
        lineItems={creditNotePreviewData?.lineItems}
      />
      <AccountsReceivablePDFPreview
        showPreview={invoicePreview}
        setShowPreview={setInvoicePreview}
        invoiceData={invoice}
        lineItems={invoice?.lineItems}
      />
      <div className="flex items-center justify-between mb-2">
        <span className="font-semibold text-lg">Invoice balance</span>
        {type === 'accounts-receivable' && (
          <ButtonRound size="xs" onClick={() => history.push('/admin-panel/finance/credit-notes/create', { id })} />
        )}
      </div>
      <table className="text-sm auto min-w-full">
        <tbody className="gap-y-1">
          {type === 'accounts-receivable' ? (
            <tr className="my-2 bg-white text-gray-800">
              <td className="py-2 pl-1">Invoice #{invoice.invoice_number}</td>
              <td className="py-2">{formatCurrency(invoice.revenue_and_vat)}</td>
              <td className="py-2 pr-2">
                <div className="w-5"></div>
              </td>
            </tr>
          ) : (
            <tr className="my-2">
              <td className="py-2 pl-1">Invoice #{invoice.invoice_number}</td>
              <td className="py-2">{formatCurrency(invoice.revenue_and_vat)}</td>
              <td className="py-2 flex items-center gap-x-1">
                <EyeIcon
                  className="h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                  onClick={() => previewInvoiceHandler()}
                />
                <ExternalLinkIcon
                  className="h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                  onClick={() => window.open(`/admin-panel/finance/accounts-receivable/${invoice.invoice_number}`)}
                />
              </td>
            </tr>
          )}
          {creditNotes?.length
            ? creditNotes.map(cn =>
                type === 'credit-notes' && Number(id) === cn.id ? (
                  <tr className="my-2 text-gray-800 font-semibold bg-white">
                    <td className="py-2 pl-1">Credit Note #{cn.credit_note_number}</td>
                    <td className="py-2">{formatCurrency(-cn.amount_and_vat)}</td>
                    <td className="py-2 pr-2"></td>
                  </tr>
                ) : (
                  <tr className="my-2">
                    <td className="py-2 pl-1">Credit Note #{cn.credit_note_number}</td>
                    <td className="py-2">{formatCurrency(-cn.amount_and_vat)}</td>
                    <td className="py-2 flex items-center gap-x-1">
                      <EyeIcon
                        className="h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                        onClick={() => previewCreditNoteHandler(cn.id)}
                      />
                      <ExternalLinkIcon
                        className="h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                        onClick={() => window.open(`/admin-panel/finance/credit-notes/${cn.id}`)}
                      />
                    </td>
                  </tr>
                ),
              )
            : ''}
        </tbody>
        <tfoot className="border-t border-gray-400 mt-2">
          <tr>
            <td className="font-semibold pl-2">Balance:</td>
            <td className="py-2">{formatCurrency(invoice.adjusted_revenue_and_vat)}</td>
            <td className="py-2 pr-2">
              <div className="w-5"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default InvoiceBalance;
