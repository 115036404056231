import React, { useEffect, useMemo, useRef, useState } from 'react';
import Table from '../../../../containers/ServerSideTable';
import useApi from '../../../../hooks/useApi';
import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import ClipLoader from 'react-spinners/ClipLoader';
import TableTabs from '../../../../components/TableItems/TableTabs';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import MonthRangeColumnFilter from '../../../../components/TableItems/MonthRangeColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import formatCurrency from '../../../../utils/formatCurrency';
import { TimeSheetStatusLabels } from '../../../../helpers/enum/timeSheet';
import months from '../../../../helpers/enum/months';
import AdminSidebar from '../../AdminSidebar';
import CostTableForPreview from '../../../../containers/Timesheets/CostTableForInvoicePreview';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { downloadBase64File } from '../../../../helpers';
import UnpaidKPIModal from '../../../../containers/Finance/AccountsPayable/UnpaidKPIModal';
import paidStatus from '../../../../helpers/enum/paidStatus';
import PayInvoice from '../../../../components/Modals/PayInvoice';

function AccountsPayable() {
  const [accountsPayableData, setAccountsPayableData] = useState();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [activeTab, setActiveTab] = useState('Unpaid');
  const [showBulkDownloadAlert, setShowBulkDownloadAlert] = useState(false);
  const [bulkDownloading, setBulkDownloading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showKPI, setShowKPI] = useState(false);
  const [filters, setFilters] = useState(false);
  const [invoiceToPay, setInvoiceToPay] = useState();
  const [showPayModal, setShowPayModal] = useState(false);
  const {
    accountsPayable: { getAccountsPayable, getAccountsPayableOptions, updatePaidStatus, bulkDownloadAP },
    files: { previewUserInvoice, downloadTimesheetFile },
  } = useApi();

  const myRefs = useRef({});

  const invoiceUploadedOptions = [
    {
      value: true,
      label: 'Uploaded',
    },
    {
      value: false,
      label: 'Not uploaded',
    },
  ];

  useEffect(() => {
    getAccountsPayableOptions(activeTab === 'Paid' ? true : false, activeTab === 'Backlog' ? false : true).then(r => {
      setFilterOptions(r);
    });
  }, [activeTab]);

  const fetchAccountsPayable = async (
    page = 0,
    filters = [],
    sortByArray = [],
    globalFilter,
    downloadToCsv,
    source,
    bulkDownload,
  ) => {
    const paid = activeTab === 'Paid' ? true : false;
    const approved = activeTab === 'Backlog' ? false : true;
    const users = filters.find(filter => filter.id === 'user')?.value;
    const period = filters.find(filter => filter.id === 'period')?.value;
    const status = filters.find(filter => filter.id === 'status')?.value;
    const cost = filters.find(filter => filter.id === 'cost')?.value;
    const vat = filters.find(filter => filter.id === 'vat_recovered')?.value;
    const paidAmount = filters.find(filter => filter.id === 'paid_amount')?.value;
    const costAndVat = filters.find(filter => filter.id === 'cost_and_vat')?.value;
    const invoiceUploaded = filters.find(filter => filter.id === 'id')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;
    let csvData = [];

    setFilters({
      users,
      period,
      cost,
      vat,
      costAndVat,
      sortBy,
      globalFilter,
      paidAmount,
    });

    if (downloadToCsv) {
      await getAccountsPayable(
        page + 1,
        paid,
        users,
        period,
        cost,
        vat,
        costAndVat,
        approved,
        status,
        paidAmount,
        invoiceUploaded,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
        bulkDownload,
      ).then(r => {
        r.forEach(e => {
          const period = new Date(e?.period);
          csvData.push({
            id: e.id,
            user: e.user.full_name,
            period: months[period.getMonth()] + ' - ' + period.getFullYear(),
            cost: e?.cost + (e?.has_custom_lines ? '*' : ''),
            cost_and_vat: e?.cost_and_vat + (e?.has_custom_lines ? '*' : ''),
            vat_recovered: e?.vat + (e?.has_custom_lines ? '*' : ''),
            invoice_uploaded: e?.timesheet.invoice_uploaded ? 'Uploaded' : 'Not uploaded',
            paid_amount: e?.paid_amount ? e.paid_amount : '-',
            paid:
              e?.paid === paidStatus.numbers.paid
                ? 'Paid'
                : e?.paid === paidStatus.numbers.partiallyPaid
                ? 'Partially paid'
                : 'Not paid',
            status: TimeSheetStatusLabels[e?.timesheet?.status],
            iban: e?.iban ? `${e?.iban}${e?.iban_change ? ' (new)' : ''}` : '-',
            bic_swift: e?.bic_swift || '-',
          });
        });
      });
      return csvData;
    }

    if (bulkDownload) {
      getAccountsPayable(
        page + 1,
        paid,
        users,
        period,
        cost,
        vat,
        costAndVat,
        approved,
        status,
        paidAmount,
        invoiceUploaded,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
        bulkDownload,
      ).then(r => {
        setBulkDownloading(true);
        bulkDownloadAP(
          r.map(el => el.id),
          source,
        )
          .then(res => {
            setBulkDownloading(false);
            setShowBulkDownloadAlert(false);
          })
          .catch(err => {
            setBulkDownloading(false);
          });
      });
      return;
    }

    getAccountsPayable(
      page + 1,
      paid,
      users,
      period,
      cost,
      vat,
      costAndVat,
      approved,
      status,
      paidAmount,
      invoiceUploaded,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
      bulkDownload,
    ).then(r => {
      setAccountsPayableData(r);
    });
  };

  const tableData = useMemo(() => {
    let array = [];
    if (accountsPayableData?.rows?.length) {
      accountsPayableData.rows.forEach(e => {
        const period = new Date(e?.period);
        array.push({
          id: {
            id: e?.id,
            timesheet_id: e?.timesheet_id,
            full_name: e?.user.full_name,
            uuid: e?.timesheet?.uuid,
            invoice_comment: e?.timesheet?.invoice_comment,
            invoice_uploaded: e?.timesheet?.invoice_uploaded,
          },
          user: e?.user?.full_name,
          period: {
            period: months[period.getMonth()] + ' - ' + period.getFullYear(),
            timesheetId: e?.timesheet_id,
          },
          status: TimeSheetStatusLabels[e?.timesheet?.status],
          cost: formatCurrency(e?.cost) + (e?.has_custom_lines ? '*' : ''),
          cost_and_vat: formatCurrency(e?.cost_and_vat) + (e?.has_custom_lines ? '*' : ''),
          vat_recovered: formatCurrency(e?.vat) + (e?.has_custom_lines ? '*' : ''),
          paid: {
            id: e?.id,
            paid: e?.paid,
            approved: e?.approved,
            invoice_uploaded: e?.timesheet?.invoice_uploaded,
            paid_amount: e?.paid_amount,
            payment_date: e?.payment_date,
            cost: e?.cost_and_vat,
          },
          paid_amount: e?.paid_amount ? formatCurrency(e.paid_amount) : '-',
          iban: e?.iban ? `${e?.iban}${e?.iban_change ? ' (new)' : ''}` : '-',
          bic_swift: e?.bic_swift || '-',
        });
      });
    }
    return array;
  }, [accountsPayableData]);

  const previewHandler = async (uuid, fullName, timesheetId, invoiceComment) => {
    setLoadingPreview(uuid);
    previewUserInvoice(`timesheet/${uuid}/invoice`)
      .then(res => {
        if (res.data.fileType.toLowerCase() !== 'pdf') {
          setLoadingPreview(false);
          return alert("Cannot preview this file, please download it directly from the user's timesheet.");
        }
        setPreviewFile({
          title: `${fullName}'s invoice`,
          path: res.data.result.data,
          type: res.data.fileType,
          underTitle: <CostTableForPreview timesheetId={timesheetId} optionalComments={invoiceComment} />,
          uuid: uuid,
          fileName: res.data?.fileName,
        });
        setLoadingPreview(false);
      })
      .catch(err => {
        setLoadingPreview(false);
      });
  };
  const updateInvoicePaid = async (id, invoicePaid, date, paidAmount) => {
    let dataToUpdate = {
      paid: invoicePaid,
      payment_date: invoicePaid === paidStatus.numbers.unpaid ? null : date,
      paid_amount: invoicePaid === paidStatus.numbers.unpaid ? null : paidAmount,
    };
    updatePaidStatus(id, dataToUpdate).then(res => {
      if (
        (invoicePaid === paidStatus.numbers.paid && activeTab === 'Unpaid') ||
        (invoicePaid !== paidStatus.numbers.paid && activeTab === 'Paid')
      ) {
        const newRows = accountsPayableData.rows.filter(r => r.id != res?.id);
        setAccountsPayableData(prev => ({
          ...prev,
          rows: newRows,
        }));
      } else {
        const newRows = accountsPayableData.rows.map(r => {
          let newObj = r;
          if (r.id === id) {
            newObj.paid = res.paid;
            newObj.paid_amount = res.paid_amount;
            newObj.payment_date = res.payment_date;
          }
          return newObj;
        });
        if (invoicePaid === paidStatus.numbers.partiallyPaid) myRefs.current[id].indeterminate = true;
        else myRefs.current[id].indeterminate = false;

        setAccountsPayableData(prev => ({
          ...prev,
          rows: newRows,
        }));
      }
      setShowPayModal(false);
    });
  };

  const onPaidClickHandler = invoice => {
    setInvoiceToPay({
      id: invoice.id,
      paid: invoice.paid,
      paid_amount: invoice.paid_amount,
      payment_date: invoice.payment_date,
      cost: invoice.cost,
    });
    setShowPayModal(true);
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'ID',
        accessor: 'id',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: invoiceUploadedOptions,
        Cell: ({ value }) => {
          return (
            <div className="flex justify-center gap-x-2 items-center">
              <span>{value.id}</span>
              <div className="w-5 h-5 translate-y-0.5 mr-2 mb-1">
                {value?.invoice_uploaded ? (
                  loadingPreview === value?.uuid ? (
                    <ClipLoader size={18} color={'#4f46e5'} />
                  ) : (
                    <EyeIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => previewHandler(value?.uuid, value?.full_name, value?.timesheet_id, value?.invoice_comment)}
                    />
                  )
                ) : (
                  <EyeOffIcon className="w-5 h-5" />
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'User',
        accessor: 'user',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions ? filterOptions?.userOptions : [],
      },
      {
        Header: 'Period',
        accessor: 'period',
        Filter: MonthRangeColumnFilter,
        filter: 'month',
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 gap-x-2 items-center">
              {value?.period}
              <Link to={{ pathname: `/admin-panel/hr/timesheets/${value.timesheetId}` }}>
                <ExternalLinkIcon className="w-5 h-5 text-thaleria-orange-500 cursor-pointer hover:text-thaleria-orange-300" />
              </Link>
            </span>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableFilters: activeTab === 'Backlog' ? false : true,
        filterOptions: filterOptions ? filterOptions.statusOptions : [],
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'VAT recovered',
        accessor: 'vat_recovered',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Total',
        accessor: 'cost_and_vat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
    ];
    if (activeTab === 'Paid' || activeTab === 'Unpaid') {
      cols.push({
        Header: 'Paid',
        accessor: 'paid',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 items-center">
              <input
                id={value.id}
                ref={el => {
                  if (value.paid === paidStatus.numbers.partiallyPaid && el?.indeterminate === false) {
                    el.indeterminate = true;
                  }

                  return (myRefs.current[value.id] = el);
                }}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                disabled={!value.approved}
                checked={value?.paid}
                onChange={() => onPaidClickHandler(value)}
                onClick={e => e.stopPropagation()}
                className={`focus:ring-thaleria-orange-500 h-4 w-4 border-gray-300 rounded mb-1 ${
                  !value.approved ? 'bg-gray-200 cursor-not-allowed text-gray-300' : 'text-thaleria-orange-600'
                }`}
              />
            </span>
          );
        },
      });

      cols.push({
        Header: 'IBAN',
        accessor: 'iban',
      });

      cols.push({
        Header: 'BIC SWIFT',
        accessor: 'bic_swift',
      });
    }
    if (activeTab === 'Unpaid') {
      cols.push({
        Header: 'Paid amount',
        accessor: 'paid_amount',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      });
    }
    if (activeTab === 'Backlog') {
      cols.push({
        Header: 'Paid',
        accessor: 'paid',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return <span className="">N/A</span>;
        },
      });
    }
    return cols;
  }, [accountsPayableData, loadingPreview]);

  const pages = [
    { name: 'Finance', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: 'Accounts Payable', href: '/admin-panel/finance/accounts-payable', current: false },
  ];

  const tabs = [
    {
      name: 'Unpaid',
      onClick: () => activeTab !== 'Unpaid' && setActiveTab('Unpaid'),
    },
    {
      name: 'Paid',
      onClick: () => activeTab !== 'Paid' && setActiveTab('Paid'),
    },
    {
      name: 'Backlog',
      onClick: () => activeTab !== 'Backlog' && setActiveTab('Backlog'),
    },
  ];

  const tableName =
    activeTab === 'Paid'
      ? 'paidAccountsPayable'
      : activeTab === 'Unpaid'
      ? 'unpaidAccountsPayable'
      : activeTab === 'Backlog' && 'backlogAccountsPayable';

  const onDownloadClick = (uuid, originalName, fileName, type) => {
    setDownloading(true);
    downloadTimesheetFile(`timesheet/${uuid}/invoice/${originalName}`)
      .then(response => {
        downloadBase64File(response.data, `${fileName}.${type}`);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <TableTabs tabs={tabs} />
      <UnpaidKPIModal show={showKPI} setShow={setShowKPI} filters={filters} />
      <PreviewBox
        filePath={previewFile.path}
        fileType={previewFile.type}
        title={previewFile.title}
        showPreview={previewFile ? true : false}
        handleHide={() => setPreviewFile(false)}
        underTitle={previewFile.underTitle}
        timesheetUUID={previewFile.uuid}
        fileName={previewFile.fileName}
        onDownloadClick={onDownloadClick}
        downloading={downloading}
      />
      <PayInvoice
        show={showPayModal}
        setShow={setShowPayModal}
        id={invoiceToPay?.id}
        _paid={invoiceToPay?.paid}
        _paidAmount={invoiceToPay?.paid_amount}
        _paymentDate={invoiceToPay?.payment_date}
        onConfirm={updateInvoicePaid}
        totalAmount={invoiceToPay?.cost}
      />
      <Table
        columns={columns}
        data={tableData}
        fetchData={fetchAccountsPayable}
        pageCount={accountsPayableData?.totalPages}
        tableName={tableName}
        totalItems={accountsPayableData?.totalItems}
        bulkDownloadButton={true}
        bulkDownloading={bulkDownloading}
        setBulkDownloading={setBulkDownloading}
        showBulkDownloadAlert={showBulkDownloadAlert}
        setShowBulkDownloadAlert={setShowBulkDownloadAlert}
        kpiButton
        setShowKPI={setShowKPI}
      />
    </AdminSidebar>
  );
}

export default AccountsPayable;
