import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Delete from '../Icons/DeleteIcon';
import Edit from '../Icons/EditIcon';
import DotsVerticalIcon from '../Icons/DotsVerticalIcon';
import useOutSide from '../../utils/hooks/useOutSide';
import moment from 'moment';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserImagePlaceholder from '../UserImagePlaceholder/UserImagePlaceholder';
import { EditorState, convertFromRaw } from 'draft-js';

const AnnouncementCard = props => {
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState();
  const [showReadMore, setShowReadMore] = useState();

  const dropDownRef = useRef(null);
  useOutSide(dropDownRef, setShow.bind(this, false));

  useEffect(() => {
    if (props.text) {
      var convertedJSON = JSON.parse(props.text);
      var rteText = EditorState.createWithContent(convertFromRaw(convertedJSON));
      var plainText = rteText.getCurrentContent().getPlainText(' ');

      const textLength = plainText.length;
      setShowReadMore(
        textLength > 300 && (
          <span onClick={props.readMeOnClick} className="text-indigo-600 cursor-pointer">
            ... &nbsp;&nbsp;Read more
          </span>
        ),
      );
      setShowText(textLength < 300 ? plainText : plainText.slice(0, 300));
    }
  }, [props.text]);

  const fullName = props.preferredName ? props.preferredName : props.creatorFirstName + ' ' + props.creatorLastName;
  const formatDate = moment(props.date).format('MMMM D [at] h:mm a');

  const dotsVerticalOption = props.showDeleteButton && props.showDeleteButton && (
    <button
      onClick={setShow.bind(this, true)}
      className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600"
    >
      <span className="sr-only">Open options</span>
      <DotsVerticalIcon />
    </button>
  );

  const editSection = props.showEditButton && (
    <section onClick={props.onEditClick} className="text-gray-700 flex px-4 py-2 text-sm hover:bg-gray-100">
      <Edit />
      <span>Edit</span>
    </section>
  );

  const deleteSection = props.showDeleteButton && (
    <section onClick={props.onDeleteClick} className="text-gray-700 flex px-4 py-2 text-sm hover:bg-gray-100">
      <Delete />
      <span>Delete</span>
    </section>
  );

  return (
    <div className="bg-white w-full px-4 py-8 shadow sm:px-6 sm:rounded-lg">
      <div>
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <ProfilePicture
              src={props.creatorImage}
              className="h-10 w-15 rounded-full"
              alt=""
              defaultimage={<UserImagePlaceholder className="h-10 w-15 rounded-full" />}
            />
          </div>
          <div className="min-w-0 flex-1">
            <p className="text-sm font-medium text-gray-900">{fullName}</p>
            <p className="text-sm text-gray-500">
              <time datetime="2020-12-09T11:43:00">{formatDate}</time>
            </p>
          </div>
          <div className="flex-shrink-0 self-center flex">
            <div ref={dropDownRef} className="relative inline-block text-left">
              {dotsVerticalOption}
              <div
                className={`${
                  !show && 'hidden'
                } origin-top-right absolute right-0 mt-2 py-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                {deleteSection}
                {editSection}
              </div>
            </div>
          </div>
        </div>
        <h2 className="mt-4 text-base font-medium text-gray-900">{props.title}</h2>
      </div>
      <div className="mt-2 text-sm text-gray-700 space-y-4">
        <p>
          {showText}
          {showReadMore}
        </p>
      </div>
    </div>
  );
};

export default AnnouncementCard;

AnnouncementCard.prototype = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  readMeOnClick: PropTypes.func.isRequired,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  onClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  creatorImage: PropTypes.string,
  preferredName: PropTypes.string,
  creatorFirstName: PropTypes.string,
  creatorLastName: PropTypes.string,
  date: PropTypes.string,
};
