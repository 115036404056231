import PropTypes from 'prop-types';
import React from 'react';
import Cropper from 'react-easy-crop';
import InputSimple from '../Inputs/InputSimple';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import ButtonWhite from '../Buttons/ButtonWhite';
import ClipLoader from 'react-spinners/ClipLoader';

const ModalImage = ({
  image,
  show,
  title,
  crop,
  rotation,
  zoom,
  setCrop,
  setRotation,
  setZoom,
  onCancel,
  onSuccess,
  onCropComplete,
  aspect,
  isUploading,
}) => {
  const showComponents = show ? 'fixed' : 'hidden';

  return (
    <div className={`${showComponents} fixed inset-0 z-5`}>
      <div className="flex items-end justify-center h-3/4  pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>

        <div className=" inline-block align-bottom bg-white rounded-lg px-5 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-2/5 h-full ">
          <h2 className="">{title}</h2>
          <div className="space-y-4 h-5/6 ">
            <div className="flex flex-col justify-between  h-full space-y-4 ">
              <div className="relative h-5/6">
                <Cropper
                  image={image}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={aspect || 2 / 2}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className="flex flex-row space-x-4">
                <InputSimple override label="Zoom">
                  <input
                    type="range"
                    step={0.1}
                    min={1}
                    max={3}
                    defaultValue={0}
                    className="w-full"
                    onChange={e => setZoom(e.target.value)}
                  />
                </InputSimple>
                <InputSimple override label="Rotation">
                  <input
                    type="range"
                    min={0}
                    max={360}
                    defaultValue={0}
                    className="w-full indigo-600"
                    onChange={e => setRotation(e.target.value)}
                  />
                </InputSimple>
              </div>
              <div className="flex justify-end space-x-2 ">
                <ButtonWhite text="Cancel" onClick={onCancel} />
                <ButtonPrimary
                  onClick={onSuccess}
                  text={!isUploading ? 'Upload' : <ClipLoader className="mr-10 h-5 w-5" size={18} color={'#FFFF'} />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalImage.prototype = {
  show: PropTypes.bool,
  title: PropTypes.string,
};

export default ModalImage;
