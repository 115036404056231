import { useState, useEffect, useMemo } from 'react';
import Modal from '../../components/Modals/Modal';
import InputRadio from '../../components/Inputs/InputRadio';
import Checkbox from '../../components/Checkbox/Checkbox';
import InputSimple from '../../components/Inputs/InputSimple';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../components/Buttons/ButtonWhite';
import { ClipLoader } from 'react-spinners';
import ReactSelect from '../../components/Inputs/ReactSelect';
import useApi from '../../hooks/useApi';
import { showSuccessNotification } from '../../store/app/actions';
import { useDispatch } from 'react-redux';

const FileNameSettings = ({ show, setShow, fileType, loading, onAccept, onCancel }) => {
  const dispatch = useDispatch();
  const [separator, setSeparator] = useState('-');

  const [fileNameStructure, setFileNameStructure] = useState([{ name: 'Number', content: `[Number]` }]);
  const [customField, setCustomField] = useState('');
  const [userSettings, setUserSettings] = useState({ accountsReceivable: {}, creditNotes: {} });
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState({
    label: 'dd-mm',
    value: 'dd-MM',
  });

  const {
    settings: { fetchFileNameSettings, updateFileNameSettings },
  } = useApi();

  const dateFormatOptions = [
    {
      label: 'dd-mm',
      value: 'dd-MM',
    },
    {
      label: 'dd-mm-yy',
      value: 'dd-MM-yy',
    },
    {
      label: 'dd-mm-yyyy',
      value: 'dd-MM-yyyy',
    },
    {
      label: 'mm-yy',
      value: 'MM-yy',
    },
    {
      label: 'mm-yyyy',
      value: 'MM-yyyy',
    },
    {
      label: 'yyyy-mm',
      value: 'yyyy-MM',
    },
    {
      label: 'MMM-yyyy',
      value: 'MMM yyyy',
    },
    {
      label: 'MMM-yy',
      value: 'MMM yy',
    },
  ];

  useEffect(() => {
    fetchFileNameSettings().then(res => {
      if (res) {
        const fileNameSettings = res?.file_name_settings ? JSON.parse(res?.file_name_settings) : null;
        if (fileNameSettings) {
          setUserSettings(fileNameSettings);
        } else {
          updateFileNameSettings({
            accountsReceivable: { order: fileNameStructure, separator: '-' },
            creditNotes: { order: fileNameStructure, separator: '-' },
          });
          setUserSettings({
            accountsReceivable: { order: fileNameStructure, separator: '-' },
            creditNotes: { order: fileNameStructure, separator: '-' },
          });
        }
      } else {
        updateFileNameSettings({
          accountsReceivable: { order: fileNameStructure, separator: '-' },
          creditNotes: { order: fileNameStructure, separator: '-' },
        });
        setUserSettings({
          accountsReceivable: { order: fileNameStructure, separator: '-' },
          creditNotes: { order: fileNameStructure, separator: '-' },
        });
      }
    });
    return () => {
      setFileNameStructure([{ name: 'Number', content: `[Number]` }]);
      setCustomField('');
      setSelectedDateFormat({
        label: 'dd-mm',
        value: 'dd-MM',
      });
    };
  }, []);

  useEffect(() => {
    if (fileType === 'accountsReceivable') {
      if (Object.keys(userSettings.accountsReceivable).length) {
        if (userSettings.accountsReceivable.order.find(e => e.name === 'CustomField')) {
          setCustomField(userSettings.accountsReceivable.order.find(e => e.name === 'CustomField').content);
        }
        setFileNameStructure(userSettings.accountsReceivable.order);
        setSeparator(userSettings.accountsReceivable.separator);
      }
    } else if (fileType === 'creditNotes') {
      if (Object.keys(userSettings.creditNotes).length) {
        if (userSettings.creditNotes.order.find(e => e.name === 'CustomField')) {
          setCustomField(userSettings.creditNotes.order.find(e => e.name === 'CustomField').content);
        }
        setFileNameStructure(userSettings.creditNotes.order);
        setSeparator(userSettings.creditNotes.separator);
      }
    }
  }, [userSettings]);

  const handleHide = () => {
    setShow(false);
  };

  const onSaveTemplate = () => {
    setSavingTemplate(true);
    let newSettings = userSettings;
    newSettings[fileType] = {
      order: fileNameStructure,
      separator: separator,
    };
    updateFileNameSettings(newSettings)
      .then(res => {
        setUserSettings(newSettings);
        setSavingTemplate(false);
        dispatch(showSuccessNotification('Settings saved!'));
      })
      .catch(err => {
        setSavingTemplate(false);
      });
  };

  const fileName = useMemo(() => {
    let _fileName = fileType === 'accountsReceivable' ? 'Invoice' : fileType === 'creditNotes' ? `Credit${separator}note` : '';
    fileNameStructure.forEach(e => {
      _fileName = _fileName + `${separator}${e.content}`;
    });
    return _fileName;
  }, [separator, fileNameStructure]);

  const spacingOptions = { Hyphen: '-', Space: ' ', Underscore: '_' };

  const changeSpacing = e => {
    setSeparator(spacingOptions[e]);
    if (fileNameStructure.find(el => el.name === 'Date')) {
      setFileNameStructure(
        fileNameStructure.map(el => {
          if (el.name === 'Date') {
            return {
              ...el,
              content: `Date(${selectedDateFormat.label.replace(/-/g, spacingOptions[e])})`,
              dateFormat: selectedDateFormat.value.replace(/-/g, spacingOptions[e]),
            };
          } else {
            return el;
          }
        }),
      );
    }
  };

  const onDateChange = () => {
    if (fileNameStructure.find(e => e.name === `Date`)) {
      let _structure = fileNameStructure.filter(e => e.name !== `Date`);
      setFileNameStructure(_structure);
    } else {
      setFileNameStructure(prev => [
        ...prev,
        {
          name: `Date`,
          content: `[Date(${selectedDateFormat.label.replace(/-/g, separator)})]`,
          dateFormat: selectedDateFormat.value.replace(/-/g, separator),
        },
      ]);
    }
  };

  const onDateFormatChange = e => {
    setFileNameStructure(prev =>
      prev.map(el => {
        if (el.name === 'Date') {
          return {
            ...el,
            content: `[Date(${e.label.replace(/-/g, separator)})]`,
            dateFormat: e.value.replace(/-/g, separator),
          };
        } else {
          return el;
        }
      }),
    );
    setSelectedDateFormat(e);
  };

  const onClientChange = () => {
    if (fileNameStructure.find(e => e.name === 'Client')) {
      let _structure = fileNameStructure.filter(e => e.name !== `Client`);
      setFileNameStructure(_structure);
    } else {
      setFileNameStructure(prev => [...prev, { name: 'Client', content: `[Client]` }]);
    }
  };
  const onFWCChange = () => {
    if (fileNameStructure.find(e => e.name === 'FWCRef')) {
      let _structure = fileNameStructure.filter(e => e.name !== `FWCRef`);
      setFileNameStructure(_structure);
    } else {
      setFileNameStructure(prev => [...prev, { name: 'FWCRef', content: `[FWC ref]` }]);
    }
  };
  const onUserNameChange = () => {
    if (fileNameStructure.find(e => e.name === 'UserName')) {
      let _structure = fileNameStructure.filter(e => e.name !== `UserName`);
      setFileNameStructure(_structure);
    } else {
      setFileNameStructure(prev => [...prev, { name: 'UserName', content: `[User name]` }]);
    }
  };

  const onCustomFieldChange = e => {
    setFileNameStructure(prev =>
      prev.map(el => {
        if (el.name === 'CustomField') {
          return {
            ...el,
            content: e.target.value,
          };
        } else {
          return el;
        }
      }),
    );
    if (fileNameStructure.find(e => e.name === 'CustomField') && e.target.value === '') {
      let _structure = fileNameStructure.filter(e => e.name !== 'CustomField');
      setFileNameStructure(_structure);
    }
    if (e?.target?.value === ' ') return;
    setCustomField(e.target.value);
  };

  const addCustomFieldToFileName = () => {
    if (fileNameStructure.find(e => e.name === 'CustomField')) {
      let _structure = fileNameStructure.filter(e => e.name !== `CustomField`);
      setFileNameStructure(_structure);
    } else if (customField !== '') {
      setFileNameStructure(prev => [...prev, { name: 'CustomField', content: `${customField}` }]);
    }
  };

  return (
    <Modal size="md" show={show} hide={handleHide} title="Change file name">
      <div className="w-full">
        <div className="border border-gray-400 rounded-md p-3 overflow-hidden">
          <span className="break-all">{fileName}.pdf</span>
        </div>
        <div className="mt-4">
          <div className="grid grid-cols-2 w-full gap-6">
            <div className="col-span-1">
              <Checkbox
                horizontal
                title={`${
                  fileType === 'accountsReceivable' ? 'Invoice' : fileType === 'creditNotes' ? `Credit${separator}note` : ''
                } number`}
                value={true}
                disabled={true}
              />
            </div>
            <div className="col-span-1">
              <Checkbox
                horizontal
                title="Client"
                value={fileNameStructure.find(e => e.name === 'Client')}
                onChange={() => onClientChange()}
              />
            </div>
            <div className="col-span-1">
              <Checkbox
                horizontal
                title="End-client FWC ref"
                value={fileNameStructure.find(e => e.name === 'FWCRef')}
                onChange={() => onFWCChange()}
              />
            </div>
            <div className="col-span-1">
              <Checkbox
                horizontal
                title="User name"
                value={fileNameStructure.find(e => e.name === 'UserName')}
                onChange={() => onUserNameChange()}
              />
            </div>
            <div className="col-span-2 flex justify-between items-center">
              <Checkbox
                horizontal
                title="Date"
                value={fileNameStructure.find(e => e.name === 'Date')}
                onChange={() => onDateChange()}
              />
              <ReactSelect
                options={dateFormatOptions}
                selectedOptions={[selectedDateFormat]}
                onChange={e => onDateFormatChange(e)}
              />
            </div>
            <div className="col-span-2 flex justify-between items-center">
              <Checkbox
                title="Custom"
                value={fileNameStructure.find(e => e.name === 'CustomField')}
                onChange={() => addCustomFieldToFileName()}
                horizontal
                disabled={!fileNameStructure.find(e => e.name === 'CustomField') && customField === ''}
              />
              <InputSimple placeholder="Add custom text" value={customField} onChange={e => onCustomFieldChange(e)} />
            </div>
            <div className="col-span-2">
              <InputRadio
                options={Object.keys(spacingOptions)}
                selectedValue={Object.keys(spacingOptions).find(key => separator === spacingOptions[key])}
                label="Separator"
                onChange={e => changeSpacing(e.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-between mt-8">
            <ButtonPrimary
              text={savingTemplate ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Save template'}
              onClick={() => onSaveTemplate()}
            />
            <div className="flex justify-end gap-x-4">
              <ButtonWhite text="Cancel" onClick={() => onCancel()} />
              <ButtonPrimary
                text={loading ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Download'}
                onClick={() => onAccept({ order: fileNameStructure, separator })}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FileNameSettings;
