import React, { useMemo, useState } from 'react';
import Table from '../../../../containers/ServerSideTable';
import { useHistory } from 'react-router';
import AdminManager from '../AdminManager';
import useApi from '../../../../hooks/useApi';
import { TrashIcon } from '@heroicons/react/solid';
import AdminSidebar from '../../AdminSidebar';

function Countries() {
  const history = useHistory();

  const {
    countries: { getAllCountriesPaginated, deleteCountry },
  } = useApi();

  const [countries, setCountries] = useState([]);

  const fetchData = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const name = filters.find(f => f.id === 'name')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (downloadToCsv) {
      let csvData = [];
      await getAllCountriesPaginated(page + 1, name, sortBy, globalFilter, downloadToCsv).then(res => {
        res.forEach(d => {
          csvData.push({
            id: d.id,
            name: d.name,
          });
        });
      });
      return csvData;
    }

    getAllCountriesPaginated(page + 1, name, sortBy, globalFilter, null, source).then(d => {
      setCountries(d);
    });
  };
  const tableData = useMemo(() => {
    let data = [];
    if (countries?.rows?.length) {
      countries.rows.map(e => {
        data.push({
          id: e.id,
          name: e.name,
        });
      });
    }
    return data;
  }, [countries]);

  const deleteCountryClick = id => {
    deleteCountry(id).then(() => {
      setCountries(countries.filter(country => country.id != id));
    });
  };

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Country Name',
      accessor: 'name',
    },
  ]);

  const pages = [
    { name: 'Admin Manager', href: '/admin-panel/admin/clients', current: false },
    { name: `Countries`, href: '/admin-panel/admin/countries', current: true },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'Delete country',
          onClick: () => deleteCountryClick(row.original.id),
          show: true,
          icon: TrashIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/admin/countries/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/admin/countries/${row.original.id}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        columns={columns}
        data={tableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        addButton={addButton}
        rowOnClick={rowOnClick}
        initialState={{ hiddenColumns: ['id'] }}
        pageCount={countries?.totalPages}
        fetchData={fetchData}
        totalItems={countries?.totalItems}
        tableName="countries"
      />
    </AdminSidebar>
  );
}

export default Countries;
