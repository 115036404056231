import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import './InputDate.css';
import Error from '../../Error/Error';
import { format, getDay } from 'date-fns';
import isSameDay from 'date-fns/isSameDay';
import FileIcon from '../../Icons/FileIcon';

const InputDateRange = ({
  onChange,
  className,
  label,
  error,
  disabled,
  placeholder,
  minDate,
  maxDate,
  disableWeekends = false,
  onlyMonth,
  disabledDates,
  startDate,
  endDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const value = useMemo(() => {
    let _value = '';

    if (startDate) _value = _value + `${format(startDate, 'dd/MM/yy')}`;
    if (endDate && !isSameDay(startDate, endDate)) _value = _value + ` - ${format(endDate, 'dd/MM/yy')}`;

    return _value;
  }, [startDate, endDate]);

  const changeHandler = e => {
    if (e[1] || e[0]) {
      const deselectedStartDate = e[0] && isSameDay(e[0], startDate) && e[1] === endDate;

      let UTCDates = [
        e[0] && !deselectedStartDate ? new Date(Date.UTC(e[0].getFullYear(), e[0].getMonth(), e[0].getDate())) : null,
        e[1] ? new Date(Date.UTC(e[1].getFullYear(), e[1].getMonth(), e[1].getDate())) : null,
      ];
      if (e[1]) {
        setIsOpen(false);
      }
      onChange(UTCDates);
    } else {
      onChange(null);
    }
  };

  const filterCustomDates = (date, disableWeekends, disabledDates) => {
    // Check if the date is a weekend (Saturday or Sunday)
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

    // Check if the date is in the additional filtered dates array
    let isDisabledDate;
    if (disabledDates?.length) {
      isDisabledDate = disabledDates.some(disabledDate => isSameDay(date, new Date(disabledDate)));
    }

    // Combine the conditions based on the props
    if (disableWeekends && isWeekend) {
      return false;
    }

    if (disabledDates && isDisabledDate) {
      return false;
    }

    // Allow all other dates
    return true;
  };

  const dayClassName = date => {
    // Add a custom class for the selected start date
    if (startDate && isSameDay(date, startDate)) {
      return 'selected-start-date';
    }

    return null;
  };

  return (
    <div className={`overflow-hidden w-full ${className}`}>
      <div className="flex">
        {label && <label className="block text-sm font-medium text-gray-700 mr-2 mb-1">{label}</label>}
        {error && <Error message={error} />}
      </div>
      <div className={`z-0 w-full `}>
        <DatePicker
          open={isOpen}
          onKeyDown={e => {
            e.preventDefault();
          }}
          onInputClick={() => setIsOpen(true)}
          value={value}
          onClickOutside={() => setIsOpen(false)}
          disabled={disabled}
          wrapperClassName={error ? `errorDatepicker ${disabled && 'disabled'}` : `datePicker border ${disabled && 'disabled'}`}
          onChange={changeHandler}
          dateFormat={onlyMonth ? 'MMMM yyyy' : 'dd/MM/yyyy'}
          showMonthYearPicker={onlyMonth}
          placeholderText={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          selectsRange={true}
          isClearable={true}
          startDate={startDate}
          endDate={endDate}
          disabledKeyboardNavigation
          filterDate={date => filterCustomDates(date, disableWeekends, disabledDates)}
          dayClassName={dayClassName}
          openToDate={startDate ? startDate : undefined}
          popperModifiers={{
            // offset: {
            //   enabled: true,
            //   offset: '5px, 10px',
            // },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
              boundariesElement: 'viewport',
            },
          }}
        />
      </div>
    </div>
  );
};

InputDateRange.prototype = {
  onChange: PropTypes.func,
  selected: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default InputDateRange;
