import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SelectableGroup, createSelectable } from 'react-selectable-fast';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { LeaveRequestStatus, LeaveRequestStatusLabels, LeaveRequestType } from '../../helpers/enum/leaveRequest';
import { DAY_SHORT_NAMES } from '../../helpers/date';
import { getDay, startOfMonth, getDaysInMonth } from 'date-fns';
import useWindowSize from '../../hooks/useWindowSize';
import holidayTypes from '../../helpers/enum/holidayTypes';
import { XIcon } from '@heroicons/react/outline';
import { PlusIcon } from '@heroicons/react/solid';
import useApi from '../../hooks/useApi';
import AddAllocationsOverlay from '../../components/Overlays/AddAllocationsOverlay';
import isBetween from 'dayjs/plugin/isBetween';
import contractTypes from '../../helpers/enum/contractTypeIDs';
import allocationTypes from '../../helpers/enum/allocationTypes';
import { Tooltip } from 'react-bootstrap';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import Loader from '../../components/Loading/Loader';

const DayComponent = ({
  isApprovedOrPreApproved,
  onClick,
  selectableRef,
  isSelected,
  holidays,
  leaveRequests,
  entries,
  setEntries,
  day,
  month,
  year,
  isSelecting,
  selectedDays,
  activeStaffOrders,
}) => {
  dayjs.extend(isBetween);

  const currentUser = useSelector(state => state.auth.currentUser);
  const isFreelancer = currentUser?.StaffContracts[0]?.contract_type_id === contractTypes.freelancer;
  const {
    timesheets: { deleteTimesheetEntry },
  } = useApi();

  const dateOfToday = useMemo(() => {
    return year && month && day ? new Date(year, month - 1, day) : new Date();
  }, [year, month, day]);

  const hasActiveStaffOrder = activeStaffOrders.length;
  const fullDay = new Date(month + '/' + day + '/' + year).getDay();
  const isWeekend = fullDay === 6 || fullDay === 0;
  let background;
  let isHoliday = false;
  let isClosureDay = false;

  //holidays contains all holidays that loosely match the period, client type, client and countries of the staffOrders active in the month.
  const currentDayHolidays = holidays.filter(e => {
    //If public holiday, filter by date and country of staff contract associated with active staff orders.
    if (e.type === holidayTypes.numbers.public_holiday) {
      const isSameDay = dayjs(e.date).get('date') === day;
      const staffContractsMatch =
        e.countries === null ||
        activeStaffOrders.some(so => e?.countries.some(c => c === so?.staffContract?.calendar_country_id));
      return isSameDay && staffContractsMatch;
    }
    //If closure day, filter by date, client type, clients and countries of specific contract associated with active staff orders.
    if (e.type === holidayTypes.numbers.closure_day) {
      const isSameDay = dayjs(e.date).get('date') === day;
      const staffOrdersMatch = activeStaffOrders.some(
        so =>
          e?.client_type === so?.specificContract.client?.type &&
          (e?.clients === null || e?.clients?.some(c => c === so?.specificContract?.client_id)) &&
          (e?.countries === null || e?.countries.some(c => c === so?.specificContract?.country_id)),
      );
      return isSameDay && staffOrdersMatch;
    }
  });
  if (currentDayHolidays.length) {
    currentDayHolidays.forEach(h => {
      if (h.type === holidayTypes.numbers.closure_day) isClosureDay = true;
      if (h.type === holidayTypes.numbers.public_holiday) isHoliday = true;
    });
  }

  let backgroundSelected;

  background = hasActiveStaffOrder
    ? isHoliday && isClosureDay
      ? 'linear-gradient(to bottom right, rgba(255, 237, 213, 0.75), rgba(255, 237, 213, 0.75) 50%, rgba(220, 252, 231, 0.75) 50%, rgba(220, 252, 231, 0.75))'
      : isHoliday
      ? 'rgba(220, 252, 231, 0.75)'
      : isClosureDay
      ? 'rgba(255, 237, 213, 0.75)'
      : isWeekend
      ? 'rgb(239, 246, 255, 0.75)'
      : 'white'
    : //if there is no active staff order, closure days will never appear to this user (need to have an active missions where the clients are a match to the closure days. Since there are no clients, there are no matches)
    isHoliday
    ? 'linear-gradient(to top left, rgba(220, 252, 231, 0.75) calc(50% - 1px), rgba(128, 128, 128, 0.20) calc(50% - 1px), rgba(128, 128, 128, 0.20) calc(50% + 1px), rgba(220, 252, 231, 0.75) calc(50% + 1px))'
    : isWeekend
    ? 'linear-gradient(to top left, rgb(239, 246, 255, 0.75) calc(50% - 1px), rgba(128, 128, 128, 0.20) calc(50% - 1px), rgba(128, 128, 128, 0.20) calc(50% + 1px), rgb(239, 246, 255, 0.75) calc(50% + 1px))'
    : 'linear-gradient(to top left, transparent calc(50% - 1px), rgba(128, 128, 128, 0.20) calc(50% - 1px), rgba(128, 128, 128, 0.20) calc(50% + 1px), transparent calc(50% + 1px))';

  backgroundSelected =
    isHoliday && isClosureDay
      ? 'linear-gradient(to top right, rgba(235, 217, 193, 0.75), rgba(235, 217, 193, 0.75) 50%, rgba(200, 232, 221, 0.75) 50%, rgba(200, 232, 221, 0.75))'
      : isHoliday
      ? 'rgba(200, 232, 221, 0.75)'
      : isClosureDay
      ? 'rgba(235, 217, 193, 0.75)'
      : isWeekend
      ? 'rgb(220, 230, 255, 0.75)'
      : '#EFEFEF';
  const convertRequestTypeValueToLabel = value => {
    switch (value) {
      case LeaveRequestType.paidLeave:
        return 'Paid leave';
      case LeaveRequestType.unPaidLeave:
        return 'Unpaid leave';
      case LeaveRequestType.medicalLeave:
        return 'Medical leave';
      case LeaveRequestType.trainingLeave:
        return 'Training leave';
      default:
        return;
    }
  };

  const leaveDays = leaveRequests?.filter(lr => {
    const leaveRequestStart = new Date(lr?.startDate);
    const leaveRequestEnd = new Date(lr?.endDate);
    return dayjs(dateOfToday).isBetween(leaveRequestStart, leaveRequestEnd, 'day', []);
  });

  const onDeleteEntryClick = (e, entryId) => {
    e.preventDefault();
    e.stopPropagation();
    deleteTimesheetEntry(entryId).then(res => {
      setEntries(entries.filter(e => e.id != entryId));
    });
  };

  return (
    <div
      ref={selectableRef}
      style={{ width: '14.28%', height: 120, background: isSelecting || isSelected ? backgroundSelected : background }}
      className={`${
        hasActiveStaffOrder ? '' : 'not-selectable'
      } group flex flex-col justify-center px-4  w-full relative  border-r border-b`}
    >
      <div className={`inline-flex text-center leading-none rounded-full transition ease-in-out duration-100`}>
        <div
          className="h-6 w-6 flex items-center justify-center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'rgb(196 196 196 / 50%)',
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {day}
        </div>
        {leaveDays.length && ((!isWeekend && !isHoliday) || leaveDays.find(l => l.type === LeaveRequestType.trainingLeave)) ? (
          <div className="ml-5 left-10 text-xs top-0 right-0 absolute rounded-bl-lg font-semibold border-b border-l">
            {leaveDays.map((leave, index) => {
              if (leave.type === LeaveRequestType.trainingLeave || (!isWeekend && !isHoliday)) {
                return (
                  <p
                    className={`${
                      leaveDays.length == 1
                        ? 'p-1 rounded-bl-lg'
                        : index == 0
                        ? 'px-1 pt-1 pb-0.5'
                        : 'rounded-bl-lg px-1 pb-1 pt-0.5'
                    } ${leave.status == LeaveRequestStatus.approved ? 'bg-blue-50 text-blue-700' : 'bg-yellow-50 text-gray-600'}`}
                  >
                    {isFreelancer
                      ? leave.type === LeaveRequestType.trainingLeave
                        ? 'Training'
                        : 'Leave'
                      : convertRequestTypeValueToLabel(leave.type)}

                    {((new Date(leave?.startDate).getDate() === day && leave?.startHalfDay) ||
                      (new Date(leave?.endDate).getDate() === day && leave?.endHalfDay)) &&
                      ' half day'}
                    {leave.status != LeaveRequestStatus.approved && ` - ${LeaveRequestStatusLabels[leave.status]}`}
                  </p>
                );
              } else {
                return '';
              }
            })}
          </div>
        ) : (
          ''
        )}
        {hasActiveStaffOrder
          ? !isApprovedOrPreApproved && (
              <PlusIcon
                onClick={onClick}
                className={`not-selectable absolute right-1 bottom-1.5 h-5 w-5 text-gray-300 hover:text-gray-400 cursor-pointer ${
                  !isSelecting && !selectedDays.length && 'group-hover:block'
                } hidden`}
              />
            )
          : ''}
      </div>
      <div className="w-full space-y-1">
        {entries &&
          entries.map(entry => {
            if (dayjs(entry.date).get('date') === day) {
              let wasAdjusted = entry?.adjusted_hours ? true : false;
              const orderRefAndHoursBackground = wasAdjusted ? 'rgb(254 243 199)' : '#f3f4f6';
              return (
                <div
                  className={`text-xs ${'bg-pink-500'} rounded-md p-1.5 flex justify-between`}
                  style={{
                    backgroundImage: `${
                      entry.type === allocationTypes.onboarding || entry.type === allocationTypes.offboarding
                        ? `linear-gradient(45deg, ${orderRefAndHoursBackground} 35.71%, #c5d4db 35.71%, #c5d4db 50%, ${orderRefAndHoursBackground} 50%, ${orderRefAndHoursBackground} 85.71%, #c5d4db 85.71%, #c5d4db 100%)`
                        : ``
                    }`,
                    backgroundSize: `${
                      entry.type === allocationTypes.onboarding || entry.type === allocationTypes.offboarding
                        ? '9.90px 9.90px'
                        : ''
                    }`,
                    backgroundColor: orderRefAndHoursBackground,
                  }}
                >
                  <div className="flex items-center">
                    <span className="font-bold  text-gray-600">{entry.staffOrder.order_ref + ' '}</span>
                    <span className="text-gray-500">&#x2022; {entry.adjusted_hours ? entry.adjusted_hours : entry.hours}h</span>
                    {entry.adjusted_hours && (
                      <InfoTooltip iconClassName="h-4 w-4 text-thaleria-orange-700 ml-1" size="auto">
                        {' '}
                        Adjusted from {entry.hours} to {entry.adjusted_hours} hours
                      </InfoTooltip>
                    )}
                  </div>
                  {!isApprovedOrPreApproved && (
                    <div className="" onClick={e => onDeleteEntryClick(e, entry.id)}>
                      <XIcon className="group-hover:block hidden w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer not-selectable" />
                    </div>
                  )}
                </div>
              );
            }
          })}
      </div>
      <div className="w-full text-center">
        {currentDayHolidays &&
          currentDayHolidays.map(e => {
            return <div className="text-xs font-normal">{`${e.name} `}</div>;
          })}
      </div>
    </div>
  );
};

const SelectableComponent = createSelectable(DayComponent);

const TimeSheetCalendar = ({
  isApprovedOrPreApproved,
  onDayClick,
  holidays,
  leaveRequests,
  month,
  year,
  timesheetEntries,
  setTimesheetEntries,
  activeStaffOrders,
  timesheetID,
}) => {
  const windowSize = useWindowSize();

  const isSmallScreen = windowSize.width <= windowSize.tailwind.md;

  const selectionRef = useRef();
  const [numOfDays, setNumOfDays] = useState({
    days: [],
    blankDays: [],
  });
  const [showAllocOverlay, setShowAllocOverlay] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const dateMemo = useMemo(() => {
    return year && month ? new Date(`${year}/${month}/01`) : new Date();
  }, [year, month]);

  const getNoOfDays = () => {
    const firstOfTheMonth = startOfMonth(dateMemo);
    const numberOfDaysInMonth = getDaysInMonth(firstOfTheMonth);
    const firstDayOfMonth = getDay(firstOfTheMonth);

    const blankDaysArray = [];
    for (let i = 1; !firstDayOfMonth ? i < 7 : i < firstDayOfMonth; i++) {
      blankDaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      daysArray.push(i);
    }

    return {
      blankDays: blankDaysArray,
      days: daysArray,
    };
  };

  useEffect(() => {
    setShowAllocOverlay(false);
    const days = getNoOfDays();
    setNumOfDays(days);
  }, [year, month]);

  const addAllocationHandler = () => {
    setShowAllocOverlay(false);
    onDayClick?.(
      selectedDays.map(d => d.props.day),
      timesheetEntries,
      month,
      year,
    );
    selectionRef.current.clearSelection();
  };

  function removeTime(date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  const returnFilteredStaffOrders = d => {
    if (!activeStaffOrders.length) return [];
    const _month = month < 10 ? '0' + month : month;

    const formattedDay = `${year}-${_month}-${d}`;
    const array = activeStaffOrders.filter(so => {
      const startedThisDayOrBefore = removeTime(new Date(so.order_start)) <= removeTime(new Date(formattedDay));
      const isOpenEnded = so.order_end === null;
      const finishedThisDayOrAfter = removeTime(new Date(`${year}-${_month}-${d}`)) <= removeTime(new Date(so.order_end));
      const isActiveThisDay = startedThisDayOrBefore && (so?.order_end ? finishedThisDayOrAfter : isOpenEnded);
      return isActiveThisDay;
    });
    return array;
  };

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-wrap" style={{ minWidth: '1200px' }}>
        {DAY_SHORT_NAMES.map(d => (
          <div style={{ width: '14.26%' }} className="px-2 py-2">
            <div className="text-gray-600  text-sm uppercase tracking-wide font-bold text-center">{d}</div>
          </div>
        ))}
      </div>
      <AddAllocationsOverlay
        show={showAllocOverlay}
        setShow={setShowAllocOverlay}
        numberOfDays={selectedDays.length}
        onClick={addAllocationHandler}
        clearSelection={() => {
          setShowAllocOverlay(false);
          selectionRef.current.clearSelection();
        }}
      />
      <div className="container mx-auto p-0.5" style={{ minWidth: '1200px' }}>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="-mx-1 -mb-1">
            <SelectableGroup
              enableDeselect={true}
              deselectOnEsc={true}
              tolerance={0}
              disabled={isApprovedOrPreApproved || isSmallScreen ? true : false}
              globalMouse
              allowClickWithoutSelected={true}
              ref={selectionRef}
              duringSelection={() => {
                if (!isSelecting) setIsSelecting(true);
              }}
              onSelectionFinish={days => {
                setIsSelecting(false);
                if (days && days.length > 0) {
                  setShowAllocOverlay(true);
                  setSelectedDays(days);
                } else {
                  setShowAllocOverlay(false);
                  setSelectedDays([]);
                }
              }} //
              ignoreList={['.not-selectable']}
            >
              <div className="flex flex-wrap border-t border-l">
                {numOfDays.blankDays.map((d, i) => (
                  <div
                    style={{ width: '14.28%', height: 120 }}
                    className={`text-center ${i === numOfDays.blankDays.length - 1 && 'border-r'}  border-b px-4 pt-2`}
                  ></div>
                ))}
                {numOfDays.days.map(d => (
                  <SelectableComponent
                    isApprovedOrPreApproved={isApprovedOrPreApproved}
                    holidays={holidays}
                    leaveRequests={leaveRequests}
                    entries={timesheetEntries}
                    setEntries={setTimesheetEntries}
                    key={d}
                    day={d}
                    month={month}
                    year={year}
                    onClick={e => onDayClick([d], timesheetEntries, month, year, e)}
                    isSelecting={isSelecting}
                    selectedDays={selectedDays}
                    //Filter staff orders by date to make sure they are active on that specific day
                    activeStaffOrders={returnFilteredStaffOrders(d)}
                  />
                ))}
              </div>
            </SelectableGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSheetCalendar;
