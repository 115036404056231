import React, { useEffect, useState } from 'react';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import { LeaveRequestStatus, LeaveRequestStatusLabels } from '../../helpers/enum/leaveRequest';
import { TimeSheetStatus, TimeSheetStatusLabels } from '../../helpers/enum/timeSheet';
import useApi from '../../hooks/useApi';
import { Link, useHistory } from 'react-router-dom';

const AdminDashboard = ({}) => {
  const history = useHistory();

  const [data, setData] = useState();

  const {
    adminDashboard: { getDashboardData },
  } = useApi();

  useEffect(() => {
    getDashboardData().then(res => {
      setData(res);
    });
  }, []);

  //Calculate start of previous month taking the user's timezone into consideration
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() - 1;
  const startOfPreviousMonth = new Date(year, month, 1);

  // Get the timezone offset in minutes
  const timezoneOffset = startOfPreviousMonth.getTimezoneOffset();

  // Convert the offset to milliseconds
  const offsetMilliseconds = timezoneOffset * 60 * 1000;

  // Adjust the startOfPreviousMonth by adding the offset
  startOfPreviousMonth.setTime(startOfPreviousMonth.getTime() - offsetMilliseconds);

  return (
    <div className="py-4 px-6 bg-white rounded-lg shadow">
      <p className="font-semibold text-xl mb-2">Admin </p>
      <div className="space-y-2 mt-4">
        <div className="flex justify-between w-full  items-center">
          <div className="flex">
            <Link
              className="text-gray-600 text-sm hover:text-thaleria-orange-700 cursor-pointer"
              to="/admin-panel/hr/task-manager?closed=false"
            >
              Open tasks
            </Link>
            <InfoTooltip size="sm">
              Includes all tasks with an open status.
              {data?.openTasks?.rows.map((row, i) => {
                return (
                  <span className={`${!i && 'mt-2'} flex justify-between`}>
                    <p>{row.module} module</p>
                    <p>{row.count}</p>
                  </span>
                );
              })}
            </InfoTooltip>
          </div>
          <span>{data?.openTasks?.count || 0}</span>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex">
            <Link
              className="text-gray-600 text-sm hover:text-thaleria-orange-700 cursor-pointer"
              to={{
                pathname: '/admin-panel/hr/timesheets',
                state: {
                  filters: [
                    {
                      id: 'status',
                      value: [
                        TimeSheetStatus.SUBMITTED,
                        TimeSheetStatus.PENDING,
                        TimeSheetStatus.PREAPPROVE,
                        TimeSheetStatus.CHANGESREQUESTED,
                      ],
                    },
                    {
                      id: 'period',
                      value: [undefined, startOfPreviousMonth],
                    },
                  ],
                },
              }}
            >
              Open timesheets
            </Link>

            <InfoTooltip size="sm">
              Includes timesheets that have not yet been approved for all months except the current one.
              {data?.timesheets?.rows.map((row, i) => {
                return (
                  <span className={`${!i && 'mt-2'} flex justify-between`}>
                    <p>{TimeSheetStatusLabels[row.status]}</p>
                    <p>{row.count}</p>
                  </span>
                );
              })}
            </InfoTooltip>
          </div>
          <span>{data?.timesheets?.count || 0}</span>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex">
            <Link
              className="text-gray-600 text-sm hover:text-thaleria-orange-700 cursor-pointer"
              to={{
                pathname: '/admin-panel/hr/leave-requests',
                state: {
                  filters: [{ id: 'status', value: [LeaveRequestStatus.submitted, LeaveRequestStatus.actionRequired] }],
                },
              }}
            >
              Open leave requests
            </Link>
            <InfoTooltip size="sm">
              Includes all leave requests in 'Submitted' or 'Action required' status.
              {data?.leaveRequests?.rows.map((row, i) => {
                return (
                  <span
                    className={`${!i && 'mt-2'} justify-between ${row.status === LeaveRequestStatus.pending ? 'hidden' : 'flex'}`}
                  >
                    <p>{LeaveRequestStatusLabels[row.status]}</p>
                    <p>{row.count}</p>
                  </span>
                );
              })}
            </InfoTooltip>
          </div>
          <span>{data?.leaveRequests?.count || 0}</span>
        </div>
        <div className="flex justify-between w-full items-center">
          <div className="flex">
            <Link
              className="text-sm text-gray-700 hover:text-thaleria-orange-700 cursor-pointer"
              to={{
                pathname: '/admin-panel/contracts/purchase-orders',
                state: {
                  expiryFilter: true,
                },
              }}
            >
              Contracts up for extension
            </Link>
            <InfoTooltip>
              <span>
                Includes contracts that have less than 30 remaining days until either (1) the final contract date or (2) the total
                days ordered
              </span>
            </InfoTooltip>
          </div>
          <span>{data?.openContracts?.count || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
