const countDecimalPlaces = number => {
  // Convert the number to a string
  const numberString = number.toString();

  // Check if the number has a decimal point
  const decimalIndex = numberString.indexOf('.');

  // If there's no decimal point, return 0
  if (decimalIndex === -1) {
    return 0;
  }

  // Calculate the number of decimal places
  return numberString.length - decimalIndex - 1;
};

export default countDecimalPlaces;
