import React, { useEffect, useState, useMemo } from 'react';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import InputSimple from '../../../components/Inputs/InputSimple';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import Checkbox from '../../../components/Checkbox/Checkbox';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import { AccountType } from '../../../helpers/enum/accounts';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { date } from '../../../helpers/date';
import useApi from '../../../hooks/useApi';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { contractExtensionLabels } from '../../../helpers/enum/contractExtensions';
import { extensionOptions } from '../../../helpers/selectOptionsExtensions';
import TextArea from '../../../components/Inputs/TextArea';
import * as contractTypes from '../../../helpers/enum/contractTypeIDs';

const timesheetListboxData = [
  {
    value: 'Manual',
    label: 'Manual',
  },
  {
    value: 'Auto',
    label: 'Auto',
  },
];

function EditPurchaseOrderAndUser({
  purchaseOrder,
  setPurchaseOrder,
  setStaffContract,
  user,
  setUser,
  staffContract,
  formErrors,
  openEnded,
  setOpenEnded,
  contacts,
  fetchAllContacts,
  edit,
  purchaseOrderOriginalData,
  isAboutToExpire,
  hasExpired,
}) {
  const [users, setUsers] = useState([]);
  const [userStaffContracts, setUserStaffContracts] = useState([]);

  const {
    staffContracts: { getUserStaffContracts },
    users: { getAllUsers },
  } = useApi();

  const fetchAllUsers = () => {
    getAllUsers().then(data => {
      let array = [];
      data
        .filter(user => user.accountType && [AccountType.ADMIN, AccountType.STAFF, AccountType.USER].includes(user.accountTypeId))
        .forEach(user => {
          array.push({ value: user.id, label: user.full_name, ...user });
        });
      setUsers(array);
    });
  };

  const fetchUserStaffContracts = () => {
    if (!user || user.value === 0) return;
    getUserStaffContracts(user.id).then(data => {
      let formattedData = data.length
        ? data.map(el => ({ value: el.id, label: el.contract_ref, ...el }))
        : [{ value: 0, label: 'This user has no staff contracts' }];

      setUserStaffContracts(formattedData);
      if (!formattedData.find(o => o?.value === staffContract?.value)) {
        setStaffContract(formattedData[0]);
      }
    });
  };

  useEffect(() => {
    fetchAllContacts();
    fetchAllUsers();
  }, []);

  //get consultant's staff contracts
  useEffect(() => {
    fetchUserStaffContracts();
  }, [user]);

  //point of contacts
  const contactsListboxData = useMemo(() => {
    let array = [];
    if (contacts.length) {
      contacts.map(c => {
        array.push({
          value: c.id,
          label: c.first_name + ' ' + c.last_name,
        });
      });
    }

    return array;
  }, [contacts]);

  //change handler
  const handlePurchaseOrderChange = (e, label) => {
    setPurchaseOrder(prev => ({ ...prev, [label]: e?.target ? e?.target?.value || '' : e }));
  };

  const handleUpdateOpenEndedPurchaseOrder = e => {
    let state = e.target.checked;
    setOpenEnded(state);
    if (state) setPurchaseOrder(prev => ({ ...prev, order_end: null }));
    else setPurchaseOrder(prev => ({ ...prev, order_end: purchaseOrderOriginalData.order_end }));
  };

  const renderNameAndLink = (name, href) => {
    return (
      <div className="flex gap-x-2 mr-auto">
        {name}
        {name && (
          <ExternalLinkIcon
            className="h-5 w-5 text-gray-400 hover:text-thaleria-orange-700 cursor-pointer"
            onClick={() => window.open(href)}
          />
        )}
      </div>
    );
  };

  return (
    <TwoColumnForm label="Purchase order and consultant" description={'Add info for new purchase order to be created.'}>
      <DividerWithLabel label="Consultant" />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="flex flex-col">
          <div className="flex items-end">
            {edit ? (
              <ReactSelect
                isSearchable
                error={formErrors.find(e => e.field === 'user')?.msg}
                label="Consultant"
                options={users}
                selectedOptions={user}
                onChange={setUser}
                disabled={!edit}
              />
            ) : (
              <SimpleEntry
                label={'Consultant'}
                data={renderNameAndLink(user.full_name, `/admin-panel/hr/user-directory/${user.id}/profile`)}
              />
            )}
            {edit && <RefreshButton onClick={() => fetchAllUsers()} />}
          </div>
          {edit && <NewTabOpener link={'/admin-panel/hr/user-directory/create/'} title="Add new user" />}
        </div>
        <div className="flex flex-col">
          <div className="flex items-end">
            {edit ? (
              <ReactSelect
                error={formErrors.find(e => e.field === 'staffContract')?.msg}
                label="Staff contract"
                options={userStaffContracts}
                selectedOptions={staffContract}
                onChange={setStaffContract}
                disabled={!edit}
              />
            ) : (
              <SimpleEntry
                label={'Staff contract'}
                data={renderNameAndLink(
                  staffContract?.contract_ref,
                  `/admin-panel/contracts/staff-contracts/${staffContract.id}`,
                )}
              />
            )}
            {edit && <RefreshButton onClick={() => fetchUserStaffContracts()} />}
          </div>
          {edit && (
            <div className="flex gap-x-2">
              {user.id !== 0 && (
                <>
                  <NewTabOpener link={'/admin-panel/contracts/staff-contracts/create'} title="Add new staff contract" />
                  {staffContract && staffContract?.id !== 0 && (
                    <NewTabOpener
                      link={`/admin-panel/contracts/staff-contracts/${staffContract?.id}`}
                      title="Edit staff contract"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {staffContract && staffContract?.id !== 0 && (
          <>
            <SimpleEntry
              label={'Contract type'}
              data={staffContract?.contractType?.contract_type || '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={`${
                staffContract?.value !== 0 && staffContract?.contract_type_id !== contractTypes.employee
                  ? 'Billing country'
                  : 'Country'
              }`}
              data={staffContract?.country?.name || '-'}
              style={`sm:col-span-1`}
            />
            {staffContract?.value !== 0 && staffContract?.contract_type_id !== contractTypes.employee && (
              <SimpleEntry
                label={'Holiday calendar'}
                data={staffContract?.holiday_calendar?.name || '-'}
                style={`sm:col-span-1`}
              />
            )}
            <SimpleEntry label={'Position'} data={staffContract?.position || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Start date'} data={date(staffContract?.contract_start || '-')} style={`sm:col-span-1`} />
            <SimpleEntry label={'End date'} data={date(staffContract?.contract_end || '-')} style={`sm:col-span-1`} />
          </>
        )}
      </dl>
      <DividerWithLabel label="Purchase order" />
      {!edit ? (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <SimpleEntry label={'Reference'} data={purchaseOrder.order_ref || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'User'} data={purchaseOrder.user.full_name || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Start date'} data={date(purchaseOrder.order_start || '-')} style={`sm:col-span-1`} />
          <SimpleEntry
            label={'End date'}
            data={(purchaseOrder.order_end && date(purchaseOrder.order_end)) || 'Open Ended'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Cost (€/day)'}
            data={purchaseOrder.daily_rate ? new Number(purchaseOrder.daily_rate).toFixed(2) : '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Days ordered'}
            data={
              purchaseOrder.days_ordered ? new Number(purchaseOrder.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '') : 'FTE'
            }
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={`${purchaseOrder.days_ordered ? 'Remaining days' : 'Consumed days'}`}
            data={`${
              purchaseOrder.days_ordered
                ? (new Number(purchaseOrder.days_ordered).valueOf() - new Number(purchaseOrder.days_consumed).valueOf())
                    .toFixed(2)
                    .replace(/[.,]00$|0$/, '')
                : purchaseOrder?.days_consumed || '-'
            }`}
            style={`sm:col-span-1`}
          />
          <SimpleEntry label={'Timesheet Type'} data={purchaseOrder.timesheet_type || '-'} style={`sm:col-span-1`} />
          <SimpleEntry
            label={'Point Of Contact'}
            data={
              (purchaseOrder.pointOfContact &&
                purchaseOrder.pointOfContact.first_name + ' ' + purchaseOrder.pointOfContact.last_name) ||
              '-'
            }
            style={`sm:col-span-1`}
          />
          <SimpleEntry label={'Created'} data={date(purchaseOrder.created_at) || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Last updated'} data={date(purchaseOrder.updated_at) || '-'} style={`sm:col-span-1`} />
          {(isAboutToExpire || hasExpired) && (
            <>
              <SimpleEntry
                label={'Contract extension'}
                data={contractExtensionLabels[purchaseOrder.contract_extension]}
                style={`sm:col-span-1`}
              />
              <SimpleEntry label={'Extension notes'} data={purchaseOrder.extension_notes} style={'col-span-2'} />
            </>
          )}
        </dl>
      ) : (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <InputSimple
            label={'Reference*'}
            error={formErrors.find(e => e.field === 'purchaseOrder-order_ref')?.msg}
            dataLabel={'order_ref'}
            onChange={handlePurchaseOrderChange}
            value={purchaseOrder.order_ref}
          />
          <InputDate
            label={'Start Date*'}
            onChange={value => handlePurchaseOrderChange(value, 'order_start')}
            error={formErrors.find(e => e.field === 'purchaseOrder-order_start')?.msg}
            selected={purchaseOrder.order_start && new Date(purchaseOrder.order_start)}
          />
          <div className="flex">
            <InputDate
              disabled={openEnded}
              label={'End Date*'}
              error={formErrors.find(e => e.field === 'purchaseOrder-order_end')?.msg}
              onChange={value => handlePurchaseOrderChange(value, 'order_end')}
              selected={purchaseOrder.order_end && new Date(purchaseOrder.order_end)}
            />
            <Checkbox className="ml-4" value={openEnded} onChange={handleUpdateOpenEndedPurchaseOrder} title="Open Ended" />
          </div>
          <InputSimple
            type="number"
            label={'Cost (€/day)*'}
            error={formErrors.find(e => e.field === 'purchaseOrder-daily_rate')?.msg}
            dataLabel={'daily_rate'}
            value={purchaseOrder.daily_rate}
            onChange={handlePurchaseOrderChange}
          />
          <InputSimple
            type="number"
            label={'Days ordered*'}
            error={formErrors.find(e => e.field === 'purchaseOrder-days_ordered')?.msg}
            dataLabel={'days_ordered'}
            onChange={handlePurchaseOrderChange}
            placeholder="FTE"
            step=".5"
            value={purchaseOrder.days_ordered}
          />
          <ReactSelect
            label={'Timesheet'}
            error={formErrors.find(e => e.field === 'purchaseOrder-timesheet_type')?.msg}
            options={timesheetListboxData}
            selectedOptionsIds={[purchaseOrder?.timesheet_type]}
            onChange={el => handlePurchaseOrderChange(el.value, 'timesheet_type')}
          />
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                isSearchable
                label={'Point of contact'}
                error={formErrors.find(e => e.field === 'purchaseOrder-point_of_contact_id')?.msg}
                options={contactsListboxData}
                selectedOptionsIds={[purchaseOrder?.point_of_contact_id]}
                //When isClearable option is turned on, the element (el) returned is null so we cannot access value
                onChange={el => handlePurchaseOrderChange(el ? el.value : null, 'point_of_contact_id')}
                isClearable
              />
              <RefreshButton onClick={() => fetchAllContacts()} />
            </div>
            <NewTabOpener link={'/admin-panel/admin/points-of-contact/create'} title="Add new point of contact" />
          </div>
          {(isAboutToExpire || hasExpired) && (
            <>
              <ReactSelect
                label={'Contract extension'}
                options={extensionOptions}
                selectedOptionsIds={[purchaseOrder.contract_extension]}
                onChange={el => handlePurchaseOrderChange(el.value, 'contract_extension')}
              />
              <TextArea
                label="Extension notes"
                value={purchaseOrder.extension_notes}
                onChange={e => handlePurchaseOrderChange(e.target.value, 'extension_notes')}
              />
            </>
          )}
        </dl>
      )}
    </TwoColumnForm>
  );
}

export default EditPurchaseOrderAndUser;
