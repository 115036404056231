import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import { AccountType } from '../../../helpers/enum/accounts';
import useApi from '../../../hooks/useApi';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Error from '../../../components/Error/Error';

const AddViewerAnnouncement = ({
  successButtonName,
  onCancelHandler,
  acceptedAccountTypes,
  acceptedContractTypes,
  countriesDefaultId,
  onSuccessHandler,
  onCountriesChange,
  onContractTypesChange,
  onAccountTypesChange,
  countriesPlaceholder,
  isAdmin,
  disabledCheckboxes,
}) => {
  const {
    userTypes: { getAllUserTypes },
    contractTypes: { getAllContractTypes },
    countries: { getAllCountries },
  } = useApi();
  const [allCountries, setAllCountries] = useState([]);
  const [allContractTypes, setAllContractTypes] = useState([]);
  const [allUserTypes, setAllUserTypes] = useState([]);
  const countries = allCountries.map(country => ({ value: country.id, label: country.name }));
  const contractTypes = allContractTypes.map(contractType => ({ value: contractType.id, label: contractType.contract_type }));
  let userTypes = [];
  allUserTypes.map(userType => {
    if (userType.id == AccountType.USER || userType.id == AccountType.STAFF) {
      userTypes.push({ value: userType.id, label: userType.account_type });
    }
  });

  useEffect(() => {
    getAllCountries().then(res => setAllCountries(res));
    getAllContractTypes().then(res => setAllContractTypes(res));
    getAllUserTypes().then(res => setAllUserTypes(res));
  }, []);

  const contractTypeError = !acceptedContractTypes?.length;

  return (
    <div>
      <h4 className="text-md-center pb-4">Who can view this announcement?</h4>
      <div className="mb-4">
        <label className={`block text-sm font-medium text-gray-700 mr-2 mb-2`}>Account types</label>
        <div className="flex justify-start items-center px-3">
          <Checkbox title={'Admins'} value={true} disabled={true} horizontal />
          <Checkbox
            title={'Staff'}
            value={acceptedAccountTypes.includes(AccountType.STAFF) || acceptedAccountTypes.length === 0}
            id={'staff'}
            onChange={e => onAccountTypesChange(e)}
            horizontal
            disabled={isAdmin ? disabledCheckboxes.includes('staff') : true}
          />
          <Checkbox
            title={'User'}
            horizontal
            id={'user'}
            value={acceptedAccountTypes.includes(AccountType.USER) || !acceptedAccountTypes.length}
            onChange={e => onAccountTypesChange(e)}
            disabled={disabledCheckboxes.includes('user')}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="flex space-x-2 mr-2 mb-2">
          <label className={`block text-sm font-medium text-gray-700 `}>Contract types</label>
          {contractTypeError && <Error message="Must have at least one option selected" />}
        </div>
        <div className="flex justify-start items-center px-3">
          {contractTypes.map(type => {
            return (
              <Checkbox
                title={type.label}
                horizontal
                id={type.label.toLowerCase()}
                value={acceptedContractTypes.includes(type.value)}
                onChange={e => onContractTypesChange(e)}
                disabled={disabledCheckboxes.includes(type.label.toLowerCase())}
              />
            );
          })}
        </div>
      </div>
      <div className="mb-4">
        <ReactSelect
          selectedOptionsIds={countriesDefaultId}
          onChange={onCountriesChange}
          options={countries}
          placeholder={countriesPlaceholder}
          isMulti={true}
          label="Countries"
        />
      </div>

      <div className="mt-10 flex flex-row-reverse justify-start">
        <ButtonPrimary text={successButtonName} onClick={onSuccessHandler} />
        <ButtonWhite text="Cancel" style="mr-2" onClick={onCancelHandler} />
      </div>
    </div>
  );
};

export default AddViewerAnnouncement;

AddViewerAnnouncement.prototype = {
  successButtonName: PropTypes.string.isRequired,
  acceptedContractTypes: PropTypes.array,
  acceptedAccountTypes: PropTypes.array,
  countriesDefaultId: PropTypes.array,
  onSuccessHandler: PropTypes.func,
  onCancelHandler: PropTypes.func,
  onCountriesChange: PropTypes.func,
  onContractTypesChange: PropTypes.func,
  onAccountTypesChange: PropTypes.func,
  countriesPlaceholder: PropTypes.string,
  contractTypePlaceholder: PropTypes.string,
  AccountTypesPlaceholder: PropTypes.string,
};
