import React, { useEffect, useState } from 'react';
import useApi from '../../../../hooks/useApi';
import CreateCreditNoteForm from '../../../../containers/Finance/CreditNotes/CreateCreditNoteForm';
import Finance from '../Finance';
import { lineItemTypesV2 } from '../../../../helpers/enum/lineItemTypes';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../../../store/app/actions';
import { useHistory } from 'react-router-dom';
import allocationTypes from '../../../../helpers/enum/allocationTypes';
import AdminSidebar from '../../AdminSidebar';
import roundNumber from '../../../../utils/roundNumber';
import { format } from 'date-fns';

function CreateCreditNote() {
  const {
    accountsReceivables: { getAccountsReceivableByInvoiceNb, getAccountReceivableIds },
    creditNotes: { getLatestCreditNoteNumber, createCreditNote },
    clients: { getAllClients },
    countries: { getAllCountries },
    timesheets: { getTimesheetEntries },
  } = useApi();

  const {
    location: { state },
  } = useHistory();

  const history = useHistory();
  const today = new Date();
  const [clientOptions, setClientOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [creditNoteData, setCreditNoteData] = useState({
    accounts_receivable_id: null,
    credit_note_number: null,
    client_id: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    amount: null,
    vat: null,
    amount_and_vat: null,
    sent: null,
    paid: null,
    metadata: {
      to: {
        name: null,
        address: null,
        postal_code: null,
        city: null,
        vat_nb: null,
      },
      from: {
        name: 'Thaleria S.A.',
        address: 'Avenue des Arts 56',
        postal_code: '1000',
        city: 'Brussels',
        country: 'Belgium',
        vat_number: 'BE 0704.867.821',
      },
      subject: 'Thaleria credit note',
      header_comment: `Please find below the details of the items and amounts subject to this credit note and containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: ``,
      footer_data: {
        name: 'Thaleria S.A.',
        email: 'finance@thaleria.com',
        phone: '+32 474 95 74 11',
        vat_number: 'BE 0704.867.821',
        iban: '',
        bic_swift: '',
      },
      showReferences: {
        salesOrder: true,
        specificContract: true,
        partnerContract: true,
        frameworkContract: true,
      },
      merge_lines: false,
      hide_period: false,
    },
  });

  const dispatch = useDispatch();

  const [lineItems, setLineItems] = useState([]);

  const fetchInvoiceAndSetCreditNoteData = async id => {
    if (id && id !== 'custom') {
      await getAccountsReceivableByInvoiceNb(id).then(async data => {
        let formattedMetadata = {
          ...JSON.parse(data.metadata),
          to: {
            name: data.client.name,
            address: data.client.address,
            postal_code: data.client.postal_code,
            city: data.client.city,
            country: data.client?.country?.name,
            vat_number: data.client.vat_number,
          },

          subject: `Credit note for invoice #${data.invoice_number}`,
          footer_comment: '',
        };
        setCreditNoteData(prev => ({
          ...prev,
          accounts_receivable_id: data.id,
          client_id: data.client_id,
          amount: data.revenue,
          vat: data.vat,
          amount_and_vat: data.revenue_and_vat,
          metadata: formattedMetadata,
        }));
        let formattedLineItems = [];
        for await (const l of data.lineItems) {
          l.metadata = JSON.parse(l.metadata);
          let _formatted = {
            description: l.description,
            type: lineItemTypesV2.convertTypeFromARtoCN(l.type), //need to convert from account receivable line type to credit note line type
            staff_order_id: l.staff_order_id,
            framework_contract_id: l.framework_contract_id,
            partner_contract_id: l.partner_contract_id,
            specific_contract_id: l.specific_contract_id,
            sales_order_id: l.sales_order_id,
            timesheet_id: l.timesheet_id,
            timesheet: {
              status: l.timesheet?.status,
            },
            units: l.units,
            from_date: l?.from_date,
            to_date: l?.to_date,
            line_item_id: l.id,
            maxUnits: l.units, //only used in frontend to limit credit ceiling
            uom: l.uom,
            price: l.price,
            vat_rate: l.vat_rate,
            user_id: l?.user_id,
            user: {
              full_name: l?.user?.full_name,
            },
            metadata: {
              fwc_ref: l.metadata.fwc_ref,
              partner_ref: l.metadata.partner_ref,
              specific_contract_ref: l.metadata.specific_contract_ref,
              sales_order_ref: l.metadata.sales_order_ref,
              custom_reference: l.metadata.custom_reference,
              period: l.metadata.period,
            },
          };
          if (
            l.type === lineItemTypesV2.accountsReceivable.numbers.projectAllocations ||
            l.type === lineItemTypesV2.accountsReceivable.numbers.customProjectAllocations
          ) {
            getTimesheetEntries(l.timesheet_id).then(res => {
              const formattedEntries = res.data
                .filter(entry => entry.staff_order_id === l.staff_order_id && entry.type !== allocationTypes.onboarding) //onboarding entries are not invoiced to clients
                .sort((a, b) => {
                  return new Date(a.date) - new Date(b.date);
                })
                .map(el => {
                  return {
                    ...el,
                    adjustedHours: 0,
                    creditedHours: el.hours,
                  };
                });
              _formatted.timesheetEntries = formattedEntries;
              let creditedDays = 0;
              let sumOfCreditedHours = formattedEntries.reduce((accumulator, currentItem) => {
                return accumulator + currentItem.creditedHours;
              }, 0);
              creditedDays = roundNumber(sumOfCreditedHours / 8, false, 6);
              _formatted.totals = {
                creditedDays,
              };
            });
          }
          formattedLineItems.push(_formatted);
        }

        if (formattedMetadata.merge_lines) {
          //if merged, reorder lines by staff order/from_date
          formattedLineItems = [...formattedLineItems].sort((a, b) => {
            // First, group by staff_order_id
            if (a.staff_order_id !== b.staff_order_id) {
              return a.staff_order_id - b.staff_order_id;
            }

            // If staff_order_id is the same, sort by from_date
            if (a.from_date === null && b.from_date === null) {
              return 0; // Both dates are null, so they are equal
            } else if (a.from_date === null) {
              return 1; // Move null date to the end
            } else if (b.from_date === null) {
              return -1; // Move null date to the end
            }

            // Compare the dates
            return new Date(a.from_date).getTime() - new Date(b.from_date).getTime();
          });
        }

        setLineItems(formattedLineItems);
      });
    } else {
      let resetData = {
        accounts_receivable_id: null,
        credit_note_number: creditNoteData.credit_note_number,
        client_id: null,
        issue_date: creditNoteData.issue_date,
        amount: null,
        vat: null,
        amount_and_vat: null,
        sent: null,
        paid: null,
        metadata: {
          to: {
            name: null,
            address: null,
            postal_code: null,
            city: null,
            vat_nb: null,
          },
          from: {
            name: 'Thaleria S.A.',
            address: 'Avenue des Arts 56',
            postal_code: '1000',
            city: 'Brussels',
            country: 'Belgium',
            vat_number: 'BE 0704.867.821',
          },
          subject: 'Thaleria credit note',
          header_comment: `Please find below the details of the items and amounts subject to this credit note and containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
          footer_comment: ``,
          footer_data: {
            name: 'Thaleria S.A.',
            email: 'finance@thaleria.com',
            phone: '+32 474 95 74 11',
            vat_number: 'BE 0704.867.821',
            iban: '',
            bic_swift: '',
          },
          showReferences: {
            salesOrder: true,
            specificContract: true,
            partnerContract: true,
            frameworkContract: true,
          },
        },
      };
      if (id === 'custom') {
        resetData.accounts_receivable_id = 'custom';
      }
      setCreditNoteData(resetData);
      setLineItems([]);
    }
  };

  useEffect(() => {
    getAccountReceivableIds().then(data => {
      //sort invoice numbers DESC
      setInvoiceOptions(
        data
          .sort((a, b) => b.invoice_number - a.invoice_number)
          .map(el => ({
            label: el.invoice_number,
            value: el.id,
          })),
      );
    });
    getLatestCreditNoteNumber().then(d => {
      setCreditNoteData(prev => ({ ...prev, credit_note_number: d.latestAvailableNumber }));
    });
    getAllCountries().then(data => {
      let countries = data;
      getAllClients().then(data => {
        let clients = data.map(c => {
          return {
            label: c.name,
            value: c.id,
            clientDetails: {
              name: c.name,
              address: c.address,
              postal_code: c.postal_code,
              city: c.city,
              country: countries.find(ct => ct.id === c.country_id)?.name,
              vat_number: c.vat_number,
              country_id: c.country_id,
              type: c.type,
            },
          };
        });
        setClientOptions(clients);
      });
    });

    if (state?.id) {
      fetchInvoiceAndSetCreditNoteData(state.id);
    }
  }, []);

  useEffect(() => {
    //update invoice total
    const newTotals = {
      amount: 0,
      vat: 0,
      amount_and_vat: 0,
    };

    if (lineItems.length) {
      lineItems.forEach(line => {
        //if it is a project allocations line with edited allocations, the updated total will appear on a separate 'totals' property
        const qty = line?.totals?.creditedDays || line.units;
        let lineTotal = qty * line.price;
        newTotals.amount += lineTotal;
        newTotals.vat += (lineTotal * line.vat_rate) / 100;
      });
      newTotals.amount_and_vat = newTotals.amount + newTotals.vat;
    }

    setCreditNoteData(prev => ({ ...prev, ...newTotals }));
  }, [lineItems]);

  const handleCreateCreditNote = () => {
    let errors = [];
    if (!creditNoteData.accounts_receivable_id) errors.push({ field: 'accounts_receivable_id', msg: 'Required' });
    if (!creditNoteData.credit_note_number) errors.push({ field: 'invoice_number', msg: 'Required' });
    if (!creditNoteData.issue_date) errors.push({ field: 'issue_date', msg: 'Required' });
    if (!creditNoteData.client_id) errors.push({ field: 'client_id', msg: 'Required' });
    if (!creditNoteData.metadata.subject) errors.push({ field: 'subject', msg: 'Required' });
    if (!creditNoteData.metadata.header_comment) errors.push({ field: 'header_comment', msg: 'Required' });
    if (!lineItems.length || lineItems.find(l => !l.units || !l.price || !l.uom || (l.vat_rate !== 0 && !l.vat_rate))) {
      errors.push({ field: 'lineItems', msg: 'Required' });
    }
    if (errors.length) return setFormErrors(errors);
    else {
      setFormErrors([]);
      const formattedLineItems = lineItems.map(el => {
        const _el = { ...el, price: Number(el.price), units: Number(el.units) };
        delete _el.bank_account_id;
        delete _el.maxUnits;
        delete _el.timesheet;
        delete _el.user;
        //prepare project allocation credit notes
        if (el.type === lineItemTypesV2.creditNotes.numbers.projectAllocations) {
          //send adjusted timesheet entries to backend
          _el.adjustedEntries = el.timesheetEntries
            .filter(l => l.adjustedHours !== l.hours)
            .map(l => ({ id: l.id, adjusted_hours: l.adjustedHours }));

          //update units to total days credited
          _el.units = el.totals.creditedDays;

          delete _el.totals;
          delete _el.timesheetEntries;
        }
        return _el;
      });

      const formattedData = {
        creditNote: {
          ...creditNoteData,
          accounts_receivable_id:
            creditNoteData.accounts_receivable_id === 'custom' ? null : creditNoteData.accounts_receivable_id,
        },
        lineItems: formattedLineItems,
      };
      setIsLoading('create');

      createCreditNote(formattedData)
        .then(res => {
          setIsLoading(false);
          dispatch(showSuccessNotification('Credit note successfully created'));
          history.push(`/admin-panel/finance/credit-notes`);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: `Credit Notes`, href: '/admin-panel/finance/credit-notes', current: false },
    { name: `Create`, href: '/admin-panel/finance/credit-notes/create', current: true },
  ];

  return (
    <AdminSidebar noPadding pages={pages}>
      <div className="flex h-full">
        <CreateCreditNoteForm
          invoiceOptions={invoiceOptions}
          creditNoteData={creditNoteData}
          setCreditNoteData={setCreditNoteData}
          lineItems={lineItems}
          setLineItems={setLineItems}
          formErrors={formErrors}
          clientOptions={clientOptions}
          setFormErrors={setFormErrors}
          handleCreateCreditNote={handleCreateCreditNote}
          fetchInvoiceAndSetCreditNoteData={fetchInvoiceAndSetCreditNoteData}
          isLoading={isLoading}
        />
      </div>
    </AdminSidebar>
  );
}

export default CreateCreditNote;
