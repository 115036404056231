import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { date, americanDate } from '../../../../helpers/date';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import Table from '../../../../containers/ServerSideTable';
// import Table from '../../../../containers/ReactTable';
import AdminManager from '../AdminManager';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import holidayTypes from '../../../../helpers/enum/holidayTypes';
import clientTypes from '../../../../helpers/enum/clientTypes';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import dayjs from 'dayjs';
import AdminSidebar from '../../AdminSidebar';

function Holidays() {
  const {
    holidays: { getAllHolidaysPaginated, getHolidayOptions },
    countries: { getAllCountries },
    clients: { getAllClients },
  } = useApi();
  const [fetchingData, setFetchingData] = useState(true);
  const [holidaysData, setHolidaysData] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [clientTypeOptions, setClientTypeOptions] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [minMaxDate, setMinMaxDate] = useState({});
  const history = useHistory();

  // TODO: Options are now being fetched, need to pass them to the columns and use them when filtering and also to fetchData from table

  useEffect(() => {
    getAllCountries().then(r => setAllCountries(r));
    getAllClients().then(r => setAllClients(r));
    getHolidayOptions().then(r => {
      setTypeOptions(
        r?.types.map(e => ({
          value: e.type,
          label: holidayTypes.strings[e.type],
        })),
      );

      let clientTypeOptions = [];

      r?.client_types.forEach(e => {
        if (e?.client_type != null) {
          clientTypeOptions.push({
            value: e.client_type,
            label: clientTypes.strings[e.client_type],
          });
        } else {
          clientTypeOptions.push({
            value: null,
            label: 'All',
          });
        }
      });
      setClientTypeOptions(clientTypeOptions);
      setMinMaxDate({ maxDate: r?.maxDate?.date, minDate: r?.minDate?.date });
    });
  }, []);

  const fetchHolidays = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const name = filters.find(filter => filter.id === 'name')?.value;
    const types = filters.find(filter => filter.id === 'type')?.value;
    const countries = filters.find(filter => filter.id === 'countries')?.value;
    let startDate = filters.find(filter => filter.id === 'date')?.value[0];
    let endDate = filters.find(filter => filter.id === 'date')?.value[1];
    const client_types = filters.find(filter => filter.id === 'client_type')?.value;
    const clients = filters.find(filter => filter.id === 'clients')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;
    if (startDate !== undefined) startDate = americanDate(startDate);
    if (endDate !== undefined) endDate = americanDate(endDate);

    if (downloadToCsv) {
      let csvData = [];
      await getAllHolidaysPaginated(
        page + 1,
        name,
        countries,
        startDate,
        endDate,
        types,
        client_types,
        clients,
        sortBy,
        globalFilter,
        downloadToCsv,
      ).then(res => {
        res.forEach(e => {
          csvData.push({
            id: e.id,
            name: e.name,
            date: e.date,
            countries: e.countries?.length
              ? allCountries
                  .map(c => {
                    if (e.countries.includes(c.id)) {
                      return c.name;
                    }
                  })
                  .filter(c => c)
                  .join(', ')
              : 'All',
            type: holidayTypes.strings[e.type],
            client_type: e.type == holidayTypes.numbers.closure_day ? clientTypes.strings[e.client_type] : '-',
            clients:
              e.type == holidayTypes.numbers.closure_day
                ? e.clients?.length
                  ? allClients
                      .map(c => {
                        if (e.clients.includes(c.id)) {
                          return c.name;
                        }
                      })
                      .filter(c => c)
                      .join(', ')
                  : 'All'
                : '-',
          });
        });
      });
      return csvData;
    }

    getAllHolidaysPaginated(
      page + 1,
      name,
      countries,
      startDate,
      endDate,
      types,
      client_types,
      clients,
      sortBy,
      globalFilter,
      null,
      source,
    ).then(r => {
      setHolidaysData(r);
    });
  };

  const tableData = [];

  if (holidaysData && holidaysData?.rows?.length) {
    holidaysData.rows.map(e => {
      tableData.push({
        id: e.id,
        name: e.name,
        date: e.date,
        countries: e.countries?.length
          ? allCountries
              .map(c => {
                if (e.countries.includes(c.id)) {
                  return c.name;
                }
              })
              .filter(c => c)
              .join(', ')
          : 'All',
        type: holidayTypes.strings[e.type],
        client_type: e.type == holidayTypes.numbers.closure_day ? clientTypes.strings[e.client_type] : '-',
        clients:
          e.type == holidayTypes.numbers.closure_day
            ? e.clients?.length
              ? allClients
                  .map(c => {
                    if (e.clients.includes(c.id)) {
                      return c.name;
                    }
                  })
                  .filter(c => c)
                  .join(', ')
              : 'All'
            : '-',
      });
    });
  }

  const memoizedTableData = tableData;

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: typeOptions,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return null;
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: minMaxDate,
      },
      {
        Header: 'Client type',
        accessor: 'client_type',
        filterOptions: clientTypeOptions,
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: 'Clients',
        accessor: 'clients',
        filterOptions: allClients.map(client => ({
          value: client.id,
          label: client.name,
        })),
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
      {
        Header: 'Countries',
        accessor: 'countries',
        filterOptions: allCountries.map(country => ({
          value: country.id,
          label: country.name,
        })),
        Filter: SelectColumnFilter,
        filter: 'includes',
        disableSortBy: true,
      },
    ];
  }, [minMaxDate, clientTypeOptions, allClients, allCountries]);

  const pages = [
    { name: 'Admin Manager', href: '/admin-panel/admin/clients', current: false },
    { name: 'Holidays', href: '/admin-panel/admin/holidays', current: true },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/admin/holidays/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/admin/holidays/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/admin/holidays/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/admin/holidays/${row.values.id}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        isLoading={fetchingData}
        columns={columns}
        data={memoizedTableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        addButton={addButton}
        rowOnClick={rowOnClick}
        pageCount={holidaysData?.totalPages}
        fetchData={fetchHolidays}
        totalItems={holidaysData?.totalItems}
        tableName="holidays"
      />
    </AdminSidebar>
  );
}

export default Holidays;
