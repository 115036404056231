import dayjs from 'dayjs';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DescriptionListItem } from '../../components/DescriptionEntries/DescriptionList';
import ReactSelect from '../../components/Inputs/ReactSelect';
import Loader from '../../components/Loading/Loader';
//configs
import { staffContractType } from '../../helpers/enum/staffContractType';
//actions
import useApi from '../../hooks/useApi';

function MyMissions() {
  //redux state
  const currentUser = useSelector(state => state.auth.currentUser);
  const [activeStaffOrders, setActiveStaffOrders] = useState([]);
  const [selectedStaffOrder, setSelectedStaffOrder] = useState({ value: 0, label: 'No active mission available' });
  const [loading, setLoading] = useState(true);

  const {
    purchaseOrders: { getActiveStaffOrders },
  } = useApi();

  useEffect(() => {
    getActiveStaffOrders(currentUser.id).then(data => {
      if (data.length) {
        const firstStaffOrder = data[0];
        setActiveStaffOrders(data);
        setSelectedStaffOrder({ ...firstStaffOrder, label: firstStaffOrder.order_ref, value: firstStaffOrder.id });
      }
      setLoading(false);
    });
  }, []);

  //listbox state and manager for active orders
  let staffOrderListboxData = useMemo(() => {
    let array = [];
    if (activeStaffOrders.length) {
      activeStaffOrders.forEach(o => array.push({ ...o, label: o.order_ref, value: o.id }));
    }
    return array;
  }, [activeStaffOrders]);

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      <div className="py-6 px-4 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">My missions</h2>
          <p className="mt-1 text-sm text-gray-500">Active missions details.</p>
        </div>
        <Loader isLoading={loading}>
          <div className="mt-6">
            <div className="-my-2 w-72">
              <DescriptionListItem
                tag={'Mission'}
                data={
                  <ReactSelect
                    options={staffOrderListboxData}
                    selectedOptions={selectedStaffOrder}
                    onChange={setSelectedStaffOrder}
                  />
                }
              />
            </div>
            {selectedStaffOrder?.value !== 0 && (
              <>
                <DescriptionListItem tag={'Client'} data={selectedStaffOrder.specificContract?.client?.name} />
                <DescriptionListItem tag={'Department'} data={selectedStaffOrder.specificContract.department} />
                {selectedStaffOrder?.staffContract?.contract_type_id != staffContractType.EMPLOYEE && (
                  <>
                    <DescriptionListItem
                      tag={'Days ordered'}
                      data={
                        selectedStaffOrder.days_ordered
                          ? new Number(selectedStaffOrder.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '')
                          : 'FTE'
                      }
                    />
                    <DescriptionListItem
                      tag={selectedStaffOrder.days_ordered ? 'Remaining days' : 'Consumed days'}
                      data={
                        selectedStaffOrder.days_ordered
                          ? new Number(selectedStaffOrder.days_ordered).valueOf() -
                            new Number(selectedStaffOrder.days_consumed).valueOf()
                          : selectedStaffOrder.days_consumed
                      }
                    />
                  </>
                )}
                <DescriptionListItem tag={'Start date'} data={dayjs(selectedStaffOrder.order_start).format('DD/MM/YYYY')} />
                <DescriptionListItem
                  tag={'End date'}
                  data={selectedStaffOrder.order_end ? dayjs(selectedStaffOrder.order_end).format('DD/MM/YYYY') : 'Open-ended'}
                />
                {selectedStaffOrder.staffContract.contract_type_id === staffContractType.FREELANCER ||
                  (selectedStaffOrder.staffContract.contract_type_id === staffContractType.COMPANY && (
                    <>
                      <DescriptionListItem tag={'Daily rate'} data={`${selectedStaffOrder.daily_rate}€`} />
                    </>
                  ))}
              </>
            )}
          </div>
        </Loader>
      </div>
    </form>
  );
}

export default MyMissions;
