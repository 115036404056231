import React from 'react';

const Checkbox = React.forwardRef(
  ({ value, title, className, onChange, onClick, disabled, id, horizontal, smallText, labelTitle }, ref) => {
    return (
      <div className={`${className} w-full ${horizontal && 'flex items-center'}`}>
        {title && (
          <div className="flex">
            <label
              className={`block ${smallText ? 'text-xs' : 'text-sm'} font-medium ${
                labelTitle ? 'text-gray-500' : 'text-gray-700'
              } mr-2`}
            >
              {title}
            </label>
          </div>
        )}
        <div className={`${horizontal ? 'mt-0' : 'mt-2'}`}>
          <input
            id={id ? id : 'comments'}
            ref={ref}
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            disabled={disabled}
            checked={value}
            onChange={onChange}
            onClick={e => e.stopPropagation()}
            className={`focus:ring-thaleria-orange-500 h-4 w-4 border-gray-300 rounded mb-1 ${
              disabled ? 'bg-gray-200 cursor-not-allowed text-gray-300' : 'text-thaleria-orange-600'
            }`}
          />
        </div>
      </div>
    );
  },
);

export default Checkbox;
