import { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import Loader from '../../components/Loading/Loader';
import formatCurrency from '../../utils/formatCurrency';
import * as countryID from '../../helpers/enum/CountryIDs';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import allocationTypes from '../../helpers/enum/allocationTypes';
import ReactSelect from '../../components/Inputs/ReactSelect';
import InputSimple from '../../components/Inputs/InputSimple';
import PillShapedButton from '../../components/Buttons/PillShapedButton';
import { PlusIcon, XIcon } from '@heroicons/react/solid';
import { XCircleIcon, PlusCircleIcon, ArrowCircleRightIcon } from '@heroicons/react/outline';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';
import SimpleAlert from '../../components/Modals/SimpleAlert';
import InfoTooltip from '../../components/Tooltips/InfoTooltip';
import { TimeSheetStatus } from '../../helpers/enum/timeSheet';
import roundNumber from '../../utils/roundNumber';

const AccountsPayableLineItemTable = ({
  timesheetId,
  timesheetStatus,
  optionalComments,
  activeStaffOrders,
  userId,
  _isApproved,
  setTimesheet,
  setShowPreview,
}) => {
  const [totalsPerStaffOrder, setTotalsPerStaffOrder] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [totals, setTotals] = useState({
    totalDue: '0',
    totalVat: 'n/a',
    totalDuePlusVat: '0',
  });
  const [isApproved, setIsApproved] = useState(_isApproved);
  const [isLoading, setIsLoading] = useState(true);
  const [isHandover, setIsHandover] = useState(false);
  const [customLines, setCustomLines] = useState([]);
  const [adjustmentLines, setAdjustmentLines] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [lineToDeleteId, setLineToDeleteId] = useState();
  const {
    timesheets: { getTimesheetTotals },
    accountsPayable: { getAccountsPayableByTimesheet, editAccountsPayable },
    lineItems: { getUnassignedLineItems, deleteAPAdjustmentLineItem },
  } = useApi();

  useEffect(() => {
    let _totalsPerStaffOrder = [];
    getAccountsPayableByTimesheet(timesheetId).then(res => {
      let _lineItems = [];
      if (res?.lineItems?.length) {
        _lineItems = res.lineItems.map(li => li);
      }

      setTotals({
        totalDue: res?.cost,
        totalVat: res?.vat,
        totalDuePlusVat: res?.cost_and_vat,
      });
      getTimesheetTotals(timesheetId).then(res => {
        res.forEach(r => {
          let totalHandoverHours = 0;
          if (r?.entries?.length) {
            r?.entries.forEach(e => {
              if (e?.type === allocationTypes.offboarding) totalHandoverHours += e?.hours;
            });
          }
          if (totalHandoverHours > 0) {
            _lineItems.push({
              staffOrder: {
                order_ref: r.staffOrder.order_ref + '*',
              },
              units: (Number(totalHandoverHours) / 8).toFixed(2).replace(/[.,]00$|0$/, ''),
              uom: 'days',
              price: 0,
              total: 0,
              vat: 0,
              vat_rate: r.staffOrder.staffContract.country_id === countryID.belgium ? '21' : '0',
              total_and_vat: 0,
              type: lineItemTypesV2.accountsPayable.numbers.projectAllocations,
            });
            setIsHandover(true);
          }
        });
      });
      if (!_isApproved) {
        getUnassignedLineItems(userId).then(res => {
          setAdjustmentLines(res);
        });
      }

      setLineItems(_lineItems);
      setIsLoading(false);
    });
  }, [timesheetId]);

  const orderRefChangeHandler = (e, index) => {
    const newCustomLines = customLines.map((l, i) => {
      return index === i
        ? {
            ...l,
            staff_order_id: e?.value || null,
          }
        : l;
    });

    setCustomLines(newCustomLines);
  };

  const typeChangeHandler = (e, index) => {
    const newCustomLines = customLines.map((l, i) => {
      return index === i
        ? {
            ...l,
            type: e?.value || null,
          }
        : l;
    });

    setCustomLines(newCustomLines);
  };

  const changeCustomLineHandler = (e, index, key) => {
    const newCustomLines = customLines.map((l, i) => {
      return index === i ? { ...l, [key]: e.target.value } : l;
    });

    setCustomLines(newCustomLines);
  };

  const saveCustomLine = i => {
    let newLine = customLines[i];

    if (
      !newLine?.staff_order_id ||
      !newLine?.type ||
      !newLine?.units ||
      !newLine?.price ||
      !newLine?.uom ||
      new Number(newLine.vat__rate) === NaN
    ) {
      return setCustomLines(
        customLines.map((line, index) => {
          return i === index ? { ...line, error: true } : line;
        }),
      );
    }
    setLineItems(prev => [
      ...prev,
      {
        ...newLine,
        staffOrder: {
          order_ref: activeStaffOrders.find(so => so.id === newLine.staff_order_id)?.order_ref,
        },
        total: Number(newLine.price) * Number(newLine.units),
        vat: (Number(newLine.price) * Number(newLine.units) * Number(newLine.vat_rate)) / 100,
        total_and_vat:
          Number(newLine.price) * Number(newLine.units) +
          (Number(newLine.price) * Number(newLine.units) * Number(newLine.vat_rate)) / 100,
        vat_rate: Number(newLine.vat_rate),
      },
    ]);
    setCustomLines(customLines.filter((line, index) => index !== i));
  };

  const saveAdjustmentLine = i => {
    const newLine = adjustmentLines[i];
    setLineItems(prev => [...prev, newLine]);
    setAdjustmentLines(adjustmentLines.filter((line, index) => index !== i));
  };

  const deleteAdjustmentLine = index => {
    deleteAPAdjustmentLineItem(adjustmentLines[index].id).then(res => {
      setAdjustmentLines(adjustmentLines.filter((line, i) => index !== i));
      setShowAlert(false);
    });
  };

  const keepAdjustmentLine = index => {
    setAdjustmentLines(adjustmentLines.filter((line, i) => i !== index));
    setShowAlert(false);
  };

  useEffect(() => {
    let totalDue = 0;
    let totalVat = 0;

    totalsPerStaffOrder.forEach(line => {
      totalDue += Number(line.total);
      if (line?.vat) totalVat += Number(line.vat);
    });

    lineItems.forEach(line => {
      totalDue += Number(line.total);
      if (line?.vat) totalVat += Number(line.vat);
    });
    setTotals({
      totalDue,
      totalVat,
      totalDuePlusVat: totalDue + totalVat,
    });
  }, [totalsPerStaffOrder, lineItems]);

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const removeLineItemsHandler = i => {
    if (lineItems[i].ts_entry_adjustment) setAdjustmentLines(prev => [...prev, lineItems[i]]);
    setLineItems(lineItems.filter((e, index) => i !== index));
  };

  const approvalToggle = () => {
    if (isApproved) {
      editAccountsPayable(timesheetId, { approved: !isApproved }).then(res => {
        setTimesheet(prev => ({
          ...prev,
          invoice_approved: !isApproved,
        }));
        setIsApproved(!isApproved);
        setShowPreview(false);
      });
      getUnassignedLineItems(userId).then(res => {
        setAdjustmentLines(res);
      });
    } else {
      if (timesheetStatus !== TimeSheetStatus.APPROVED) {
        return alert('Cannot approve invoice while timesheet is not at least Approved.');
      }

      const lineItemsToAdd = [];

      lineItems.forEach(li => {
        if (!li.accounts_payable_id) {
          if (li.ts_entry_adjustment) {
            lineItemsToAdd.push({
              id: li.id,
              ts_entry_adjustment: li.ts_entry_adjustment,
              units: li.units,
              uom: li.uom,
              price: li.price,
              vat_rate: li.vat_rate,
              type: li.type,
              staff_order_id: li.staff_order_id,
            });
          } else {
            const { uuid, staffOrder, total, vat, total_and_vat, ...liToAdd } = li;
            lineItemsToAdd.push(liToAdd);
          }
        }
      });
      const data = { lineItemsToAdd, approved: !isApproved };
      editAccountsPayable(timesheetId, data).then(res => {
        setTimesheet(prev => ({
          ...prev,
          invoice_approved: !isApproved,
        }));
        setLineItems(res?.lineItems);
        setIsApproved(!isApproved);
        setShowPreview(false);
      });
    }
    setCustomLines([]);
  };

  return (
    <Loader isLoading={isLoading} background={null} table={null} className="my-4">
      <SimpleAlert
        show={showAlert ? true : false}
        hide={() => setShowAlert(false)}
        errorTitle={showAlert.type === 1 ? 'Deleting adjustment line' : 'Keep adjustment line?'}
        errorMsg={
          showAlert.type === 1
            ? 'Proceeding will permanently delete this adjustment line which means it will not be available to add in future invoices. Are you certain you want to delete it?'
            : "Proceeding will remove this line item from this approval, keeping it for a future approval of this user's invoices."
        }
        onAcceptText={'Proceed'}
        onAcceptClick={() => (showAlert.type === 1 ? deleteAdjustmentLine(showAlert.index) : keepAdjustmentLine(showAlert.index))}
        onDeclineClick={() => setShowAlert(false)}
        onDeclineText={'Cancel'}
      />
      <div className="mt-8 mb-4 flex flex-col h-full">
        <div className="w-full flex justify-between">
          <span className="mb-4 py-2 text-lg font-medium leading-6 text-gray-900">Invoice lines</span>
          <div>
            {isApproved ? (
              ''
            ) : (
              <PillShapedButton
                text="Add line"
                size="s"
                icon={<PlusIcon className="w-4 h-4" />}
                onClick={() =>
                  setCustomLines(prev => [
                    ...prev,
                    {
                      uuid: generateUUID(),
                      staffOrder: {
                        order_ref: null,
                      },
                      units: null,
                      uom: null,
                      price: null,
                      total: null,
                      vat: null,
                      vat_rate: null,
                      total_and_vat: null,
                      staff_order_id: null,
                      type: allocationTypes.billable,
                    },
                  ])
                }
              />
            )}
          </div>
        </div>
        <div className={`${isHandover ? '' : 'mb-4'} px-2 border border-gray-300 rounded-md`}>
          <table className="table-auto w-full h-max mt-2 px-4">
            <thead className="w-full">
              <tr className="text-md ">
                <th className="text-center">Purchase order</th>
                <th className="text-center">Type</th>
                <th className="text-center">Units</th>
                <th className="text-center">UoM</th>
                <th className="text-center">Price</th>
                <th className="text-center">VAT rate</th>
                <th className="text-center">Total Excl VAT</th>
                <th className="text-center">VAT</th>
                <th className="text-center">Total Incl VAT</th>
              </tr>
            </thead>
            <tbody className="p-2 h-max w-full text-sm">
              {lineItems?.length ? (
                lineItems.map((li, i) => {
                  return (
                    <tr className="border-dashed my-1 h-8" key={li?.id || li?.uuid}>
                      {li.ts_entry_adjustment ? (
                        <td className="text-center">
                          <div className="flex items-center justify-center">
                            {li.staffOrder.order_ref}
                            <InfoTooltip size="sm">{li.description}</InfoTooltip>
                          </div>
                        </td>
                      ) : (
                        <td className="text-center">{li?.staffOrder?.order_ref || ''}</td>
                      )}
                      <td className="text-center">{lineItemTypesV2.accountsPayable.strings[li.type]}</td>
                      <td className="text-center">{roundNumber(li.units, true, 6)}</td>
                      <td className="text-center">{li.uom}</td>
                      <td className="text-center">{formatCurrency(li.price)}</td>
                      <td className="text-center">
                        {Number(li.vat_rate)
                          .toFixed(2)
                          .replace(/[.,]00$|0$/, '') + '%'}
                      </td>
                      <td className="text-center">{formatCurrency(li.total)}</td>
                      <td className="text-center">{formatCurrency(li.vat)}</td>
                      <td className="text-center relative">
                        {formatCurrency(li.total_and_vat)}
                        {!isApproved &&
                        (li?.type !== lineItemTypesV2.accountsPayable.numbers.projectAllocations || li.ts_entry_adjustment) ? (
                          <XIcon
                            className="h-4 w-4 text-gray-300 hover:text-gray-500 cursor-pointer absolute right-1 top-1/2 transform -translate-y-1/2"
                            onClick={() => removeLineItemsHandler(i)}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>No allocations found</tr>
              )}
              {customLines?.length
                ? customLines.map((line, index) => {
                    const canBeAdded = line?.staff_order_id && line.price && line.units && line.uom && line.vat_rate;

                    return (
                      <tr className="border-dashed my-1 h-8" key={line.uuid}>
                        <td className="text-center">
                          <div className={`max-w-40 pr-1 ${index === customLines.length - 1 && 'pb-1.5'}`}>
                            <ReactSelect
                              selectedOptionsIds={[line.staff_order_id]}
                              options={activeStaffOrders.map(so => ({
                                label: so?.order_ref,
                                value: so?.id,
                              }))}
                              onChange={e => orderRefChangeHandler(e, index)}
                              isSearchable
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <div className={`max-w-40 pr-1 ${index === customLines.length - 1 && 'pb-1.5'}`}>
                            <ReactSelect
                              selectedOptionsIds={[line.type]}
                              options={lineItemTypesV2.accountsPayable.reactSelectOptions}
                              onChange={e => typeChangeHandler(e, index)}
                              isSearchable
                            />
                          </div>
                        </td>
                        <td className={`text-center w-20 px-1 ${index === customLines.length - 1 && 'pb-1.5'}`}>
                          <InputSimple
                            type="number"
                            value={line.units}
                            onChange={e => changeCustomLineHandler(e, index, 'units')}
                          />
                        </td>
                        <td className={`text-center w-20 px-1 ${index === customLines.length - 1 && 'pb-1.5'}`}>
                          <InputSimple value={line.uom} onChange={e => changeCustomLineHandler(e, index, 'uom')} />
                        </td>
                        <td className={`text-center w-20 px-1 ${index === customLines.length - 1 && 'pb-1.5'}`}>
                          <InputSimple
                            value={line.price}
                            type="number"
                            onChange={e => changeCustomLineHandler(e, index, 'price')}
                          />
                        </td>
                        <td className={`text-center w-20 px-1 ${index === customLines.length - 1 && 'pb-1.5'}`}>
                          <InputSimple
                            value={line.vat_rate}
                            type="number"
                            onChange={e => changeCustomLineHandler(e, index, 'vat_rate')}
                          />
                        </td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center flex justify-center items-center h-full space-x-3">
                          {canBeAdded ? (
                            <PlusCircleIcon
                              className="w-6 h-6 text-gray-400 hover:text-green-500 cursor-pointer"
                              onClick={() => saveCustomLine(index)}
                            />
                          ) : (
                            <PlusCircleIcon className="w-6 h-6 text-gray-300" />
                          )}
                          <XCircleIcon
                            className="w-6 h-6 text-gray-400 hover:text-red-500 cursor-pointer"
                            onClick={() => setCustomLines(customLines.filter((l, i) => index !== i))}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}
            </tbody>
            <tfoot className="border-t border-gray-300 pt-2 h-8 text-sm w-full">
              <tr>
                <td className="font-semibold text-center text-base">Grand total</td>
                <td className=""></td>
                <td className=""></td>
                <td className=""></td>
                <td className=""></td>
                <td className="font-bold text-thaleria-blue-500 text-center "></td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalDue)}</td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalVat)}</td>
                <td className="font-bold text-thaleria-blue-500 text-center ">{formatCurrency(totals?.totalDuePlusVat)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        {isHandover ? <span className="mx-3 text-sm mb-4">* Offboarding handover (not billable)</span> : ''}
        {adjustmentLines.length ? (
          <>
            <span className="text-lg font-medium leading-6 text-gray-900 py-2 mb-4">Available adjustment lines</span>
            <div className="px-2 border border-gray-300 rounded-md mb-4">
              <table className="table-auto w-full h-max mt-2 px-4 ">
                <thead>
                  <tr className="text-md">
                    <th className="text-center">Purchase order</th>
                    <th className="text-center">Type</th>
                    <th className="text-center">Units</th>
                    <th className="text-center">UoM</th>
                    <th className="text-center">Price</th>
                    <th className="text-center">VAT rate</th>
                    <th className="text-center">Total Excl VAT</th>
                    <th className="text-center">VAT</th>
                    <th className="text-center">Total Incl VAT</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {adjustmentLines.map((line, index) => {
                    return (
                      <tr className="border-dashed my-1 h-8 text-sm group" key={line.uuid || line.id}>
                        <td className="text-center">
                          <div className="flex items-center justify-center">
                            {line.staffOrder.order_ref}
                            <InfoTooltip size="sm">{line.description}</InfoTooltip>
                          </div>
                        </td>
                        <td className="text-center">{lineItemTypesV2.accountsPayable.strings[line.type]}</td>
                        <td className="text-center">{line.units}</td>
                        <td className="text-center">{line.uom}</td>
                        <td className="text-center">{line.price}</td>
                        <td className="text-center">
                          {Number(line.vat_rate)
                            .toFixed(2)
                            .replace(/[.,]00$|0$/, '') + '%'}
                        </td>
                        <td className="text-center">{formatCurrency(line.total)}</td>
                        <td className="text-center">{formatCurrency(line.vat)}</td>
                        <td className="text-center relative">
                          {formatCurrency(line.total_and_vat)}
                          <div className="absolute right-1 top-1/2 transform -translate-y-2"></div>
                        </td>
                        <td className="text-center flex items-center justify-center h-full gap-x-1">
                          {isApproved ? (
                            ''
                          ) : (
                            <>
                              <PlusCircleIcon
                                className="w-6 h-6 text-gray-400 hover:text-green-500 cursor-pointer"
                                onClick={() => saveAdjustmentLine(index)}
                              />
                              <ArrowCircleRightIcon
                                className="w-6 h-6 text-gray-400 hover:text-orange-400 cursor-pointer"
                                onClick={() => setShowAlert({ type: 2, index: index })}
                              />
                              <XCircleIcon
                                className="w-6 h-6 text-gray-400 hover:text-red-500 cursor-pointer"
                                onClick={() => setShowAlert({ type: 1, index: index })}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          ''
        )}
        {optionalComments && (
          <div className="flex mb-4 flex-col">
            <span className="text-lg font-medium leading-6 text-gray-900">Comments from the user</span>
            <span className="w-full rounded-md px-2 py-2">{optionalComments}</span>
          </div>
        )}
        <ButtonPrimary
          text={
            isApproved
              ? 'Cancel approval'
              : adjustmentLines.length
              ? 'Handle all adjustment lines before approving'
              : 'Save and approve'
          }
          onClick={() => approvalToggle()}
          size={null}
          style={'w-full mx-auto'}
          accept={null}
          disable={adjustmentLines.length ? true : false}
          icon={null}
          canDelete={null}
          color={isApproved ? 'red' : null}
          link={null}
          type={null}
          title={null}
          id={null}
          isLoading={null}
        />
      </div>
    </Loader>
  );
};

export default AccountsPayableLineItemTable;
