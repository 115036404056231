import React, { useEffect, useMemo, useState } from 'react';
import { americanDate, date } from '../../../../helpers/date';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import Table from '../../../../containers/ServerSideTable';
import { useHistory } from 'react-router';
import ContractsManager from '../ContractsManager';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import contractTypesToString from '../../../../helpers/enum/contractTypesToString';
import axios from 'axios';
import AdminSidebar from '../../AdminSidebar';

function StaffContracts(props) {
  const [tableOptions, setTableOptions] = useState();
  const history = useHistory();

  const {
    staffContracts: { getPaginatedStaffContracts, getStaffContractOptions },
  } = useApi();

  useEffect(() => {
    getStaffContractOptions().then(r => {
      setTableOptions(r);
    });
  }, []);

  const fetchStaffContracts = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    const users = filters.find(filter => filter.id === 'user')?.value;
    const contractTypes = filters.find(filter => filter.id === 'contract_type')?.value;
    const countries = filters.find(filter => filter.id === 'country')?.value;
    const calendarCountries = filters.find(filter => filter.id === 'calendarCountry')?.value;
    const contractRef = filters.find(filter => filter.id === 'reference')?.value;
    const position = filters.find(filter => filter.id === 'position')?.value;
    let startDates = filters.find(filter => filter.id === 'start')?.value;
    let endDates = filters.find(filter => filter.id === 'end')?.value;
    const vatNb = filters.find(filter => filter.id === 'vat_nb')?.value;
    const bicSwift = filters.find(filter => filter.id === 'bic_swift')?.value;
    const iban = filters.find(filter => filter.id === 'iban')?.value;
    const companyName = filters.find(filter => filter.id === 'company_name')?.value;
    const companyAddress = filters.find(filter => filter.id === 'company_address')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    if (startDates !== undefined) {
      startDates = startDates.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }
    if (endDates !== undefined) {
      endDates = endDates.map(d => {
        if (d !== undefined) {
          if (d === 0) return '0';
          return americanDate(d);
        }
      });
    }

    let csvData = [];
    if (downloadToCsv) {
      await getPaginatedStaffContracts(
        page + 1,
        users,
        contractTypes,
        countries,
        calendarCountries,
        contractRef,
        position,
        startDates,
        endDates,
        vatNb,
        bicSwift,
        iban,
        companyName,
        companyAddress,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(r => {
        r.forEach(e => {
          csvData.push({
            id: e.id,
            user: e.user && e.user.full_name,
            contract_type: e.contractType && e.contractType.contract_type,
            country: e.country && e.country.name,
            calendarCountry: e?.calendar_country_id ? e.holiday_calendar.name : '-',
            reference: e.contract_ref,
            position: e.position,
            start: e.contract_start,
            end: e.contract_end,
            vat_nb: e.vat_nb,
            bic_swift: e.bic_swift,
            iban: e.iban,
            company_name: e.company_name,
            company_address: e.company_address,
          });
        });
      });
      return csvData;
    }

    getPaginatedStaffContracts(
      page + 1,
      users,
      contractTypes,
      countries,
      calendarCountries,
      contractRef,
      position,
      startDates,
      endDates,
      vatNb,
      bicSwift,
      iban,
      companyName,
      companyAddress,
      sortBy,
      globalFilter,
      null,
      source,
    ).then(r => {
      setContractsData(r);
    });
  };

  const [contractsData, setContractsData] = useState([]);

  const tableData = useMemo(() => {
    let array = [];
    if (contractsData?.rows?.length) {
      contractsData.rows.forEach(e => {
        array.push({
          id: e.id,
          user: e.user && e.user.full_name,
          contract_type: e.contractType && e.contractType.contract_type,
          country: e.country && e.country.name,
          calendarCountry: e?.calendar_country_id ? e.holiday_calendar.name : '-',
          reference: e.contract_ref,
          position: e.position,
          start: e.contract_start,
          end: e.contract_end,
          vat_nb: e.vat_nb,
          bic_swift: e.bic_swift,
          iban: e.iban,
          company_name: e.company_name,
          company_address: e.company_address,
        });
      });
    }
    return array;
  }, [contractsData]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'User',
        accessor: 'user',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: tableOptions?.userOptions.map(u => ({
          value: u.id,
          label: u.first_names + ' ' + u.last_names,
        })),
      },
      {
        Header: 'Contract Type',
        accessor: 'contract_type',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: tableOptions?.contractTypeOptions.map(type => ({
          value: type.contract_types,
          label: contractTypesToString[type.contract_types],
        })),
      },
      {
        Header: 'Country',
        accessor: 'country',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: tableOptions?.countryOptions.map(c => ({
          value: c.id,
          label: c.name,
        })),
      },
      {
        Header: 'Calendar country',
        accessor: 'calendarCountry',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: tableOptions?.calendarCountryOptions.map(c => ({
          value: c.id,
          label: c.name,
        })),
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Position',
        accessor: 'position',
      },
      {
        Header: 'Start',
        accessor: 'start',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return null;
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: tableOptions?.contractStart,
      },
      {
        Header: 'End',
        accessor: 'end',
        Cell: ({ value }) => {
          if (value) return date(value);
          if (!value) return 'Open-ended';
        },
        Filter: DateRangeColumnFilter,
        filter: 'date',
        filterOptions: tableOptions?.contractEnd,
        openEndedOption: true,
      },
      {
        Header: 'Vat No',
        accessor: 'vat_nb',
      },
      {
        Header: 'BIC Swift',
        accessor: 'bic_swift',
      },
      {
        Header: 'IBAN',
        accessor: 'iban',
      },
      {
        Header: 'Company Name',
        accessor: 'company_name',
      },
      {
        Header: 'Company Address',
        accessor: 'company_address',
      },
    ];
  }, [tableOptions]);

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: false },
    { name: `Staff Contracts`, href: '/admin-panel/contracts/staff-contracts', current: true },
  ];

  const addButton = {
    link: `/admin-panel/contracts/staff-contracts/create`,
    text: 'Add new',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/contracts/staff-contracts/${row.values.id}`);
  };

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/contracts/staff-contracts/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/contracts/staff-contracts/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        columns={columns}
        data={tableData}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        rowOnClick={rowOnClick}
        addButton={addButton}
        pageCount={contractsData?.totalPages}
        fetchData={fetchStaffContracts}
        totalItems={contractsData?.totalItems}
        tableName="staffContracts"
      />
    </AdminSidebar>
  );
}

export default StaffContracts;
