import base64 from '../resources/logos/thaleriaHorizontalLogoBlueBase64';
import formatCurrency from '../utils/formatCurrency';
import roundNumber from '../utils/roundNumber';
import { lineItemTypesV2 } from './enum/lineItemTypes';

const CreditNoteTemplate = ({ creditNoteData, lineItems }) => {
  const mergePeriods = (existingPeriods, newPeriod) => {
    const allPeriods = existingPeriods.split('; ').concat(newPeriod.split('; '));
    const periodMap = {};

    allPeriods.forEach(period => {
      const [month, year] = period.split(' ');
      if (!periodMap[year]) {
        periodMap[year] = [];
      }
      if (!periodMap[year].includes(month)) {
        periodMap[year].push(month);
      }
    });

    const combinedPeriods = Object.entries(periodMap)
      .map(([year, months]) => {
        months.sort((a, b) => new Date(`1 ${a} ${year}`).getTime() - new Date(`1 ${b} ${year}`).getTime());
        return months.length > 1 ? `${months.join('-')} ${year}` : `${months[0]} ${year}`;
      })
      .sort(
        (a, b) =>
          new Date(a.split(' ')[1] + ' ' + a.split(' ')[0]).getTime() -
          new Date(b.split(' ')[1] + ' ' + b.split(' ')[0]).getTime(),
      )
      .join('; ');

    return combinedPeriods;
  };

  const deepCopyLineItem = item => ({
    ...item,
    metadata: {
      ...item.metadata,
    },
  });

  const processLines = lineItems => {
    // If property metadata.merge_lines is true, merge all lines with the same staff order id
    let processedLines = [];

    if (creditNoteData.metadata?.merge_lines) {
      lineItems.forEach(item => {
        //no need to merge line items that are NOT allocations
        if (item.type !== lineItemTypesV2.creditNotes.numbers.projectAllocations) return;
        const newItem = deepCopyLineItem(item); // Create a deep copy of the item
        if (item?.totals) newItem.totals = { ...item.totals };
        if (!processedLines.find(el => el.staff_order_id === newItem.staff_order_id)) {
          processedLines.push(newItem);
        } else {
          const index = processedLines.findIndex(el => el.staff_order_id === newItem.staff_order_id);
          // Add units and combine periods from merged lines
          processedLines[index].units = (Number(processedLines[index].units) + Number(newItem.units)).toString();
          if (processedLines[index]?.totals) {
            processedLines[index].totals.creditedDays = (
              Number(processedLines[index].totals.creditedDays) + Number(newItem.totals.creditedDays)
            ).toString();
          }
          processedLines[index].metadata.period = mergePeriods(processedLines[index].metadata.period, newItem.metadata.period);
          // Update the from_date to the earliest date for sorting purposes
          if (
            newItem.from_date &&
            (!processedLines[index].from_date || new Date(newItem.from_date) < new Date(processedLines[index].from_date))
          ) {
            processedLines[index].from_date = newItem.from_date;
          }
        }
      });
    } else {
      processedLines = lineItems;
    }
    // Sort line items by earliest from_date
    return [...processedLines].sort((a, b) => {
      // Handle null dates
      if (a.from_date === null && b.from_date === null) {
        return 0; // No change in order for both null dates
      } else if (a.from_date === null) {
        return 1; // Move null date to the end
      } else if (b.from_date === null) {
        return -1; // Move null date to the end
      }
      return new Date(a?.from_date).getTime() - new Date(b?.from_date).getTime();
    });
  };

  const _lineItems = processLines(lineItems);

  const generateDocumentDefinition = () => {
    const frameworkContractRefs = (fwc_ref, partner_ref) => {
      let array = [];
      let returnThis;
      if (fwc_ref) array.push({ text: fwc_ref || '', style: 'tableContent', margin: [0, 2, 0, 0] });
      if (partner_ref) array.push({ text: partner_ref || '', style: 'tableContent', margin: [0, 2, 0, 0] });

      if (array.length === 1) returnThis = array[0];
      else returnThis = array;
      return returnThis;
    };
    const specificContractRefs = (specific_contract_ref, sales_order_ref) => {
      let array = [];
      let returnThis;
      if (specific_contract_ref) array.push({ text: specific_contract_ref || '', style: 'tableContent', margin: [0, 2, 0, 0] });
      if (sales_order_ref) array.push({ text: sales_order_ref || '', style: 'tableContent', margin: [0, 2, 0, 0] });
      if (array.length === 1) returnThis = array[0];
      else returnThis = array;
      return returnThis;
    };

    const totalsBody = [
      [
        {},
        {},
        {},
        {},
        {},
        {},
        { text: 'Total excl. VAT', style: 'tableContent' },
        { text: `${formatCurrency(creditNoteData.amount, true, true) || ''}`, style: 'tableContent' },
      ],
      [
        {},
        {},
        {},
        {},
        {},
        {},
        { text: 'VAT', style: 'tableContent' },
        { text: `${formatCurrency(creditNoteData.vat, true, true) || ''}`, style: 'tableContent' },
      ],
      [
        {
          text: creditNoteData.metadata?.vat_disclaimer ? creditNoteData.metadata?.vat_disclaimer : '',
          style: 'vatDisclaimer',
        },
        {},
        {},
        {},
        {},
        {},
        { text: 'Total incl. VAT', style: 'alignRightAndBold', margin: [0, 2, 0, 0] },
        {
          text: `${formatCurrency(creditNoteData.amount_and_vat, true, true) || ''}`,
          style: 'alignRightAndBold',
        },
      ],
    ];

    const lineItemsTable = {
      headerRows: 1,
      widths: ['*', 'auto', 'auto', 'auto', 'auto'],
      body: [
        [
          {
            text: 'Description',
            style: {
              fontSize: 12,
            },
          },
          { text: 'Price', style: 'tableHeader' },
          { text: 'Qty', style: 'tableHeader' },
          { text: 'UoM', style: 'tableHeader' },
          { text: 'Subtotal', style: 'tableHeader' },
        ],
        ..._lineItems.map(content => {
          let qty;
          if (content?.totals?.creditedDays) qty = content?.totals?.creditedDays;
          else qty = content.units;

          let itemsArray = [
            {
              text: content.metadata?.custom_reference
                ? `${content.description} ${
                    !creditNoteData.metadata?.hide_period && content?.metadata?.period ? `(${content.metadata.period})` : ''
                  } (reference: ${content.metadata?.custom_reference})`
                : `${content.description} ${
                    !creditNoteData.metadata?.hide_period && content?.metadata?.period ? `(${content.metadata.period})` : ''
                  }`,
            },

            {
              text: content.price ? formatCurrency(content.price, true, true) : '',
              style: 'tableContent',
              margin: [0, 2, 0, 0],
            },
            {
              text: qty ? roundNumber(qty, true, 6) : '',
              style: 'tableContent',
              margin: [0, 2, 0, 0],
            },
            {
              text: content.uom || '',
              style: 'tableContent',
              margin: [0, 2, 0, 0],
            },
            {
              text: formatCurrency((qty ?? 0) * (content.price ?? 0), true, true),
              style: 'tableContent',
              margin: [0, 2, 0, 0],
            },
          ];

          if (creditNoteData.metadata.showReferences.salesOrder || creditNoteData?.metadata?.showReferences?.specificContract) {
            itemsArray.splice(
              1,
              0,
              specificContractRefs(
                creditNoteData?.metadata?.showReferences.specificContract ? content.metadata.specific_contract_ref : null,
                creditNoteData?.metadata?.showReferences.salesOrder ? content.metadata.sales_order_ref : null,
              ),
            );
          }
          if (
            creditNoteData.metadata.showReferences.frameworkContract ||
            creditNoteData?.metadata?.showReferences?.partnerContract
          ) {
            itemsArray.splice(
              1,
              0,
              frameworkContractRefs(
                creditNoteData?.metadata?.showReferences.frameworkContract ? content.metadata.fwc_ref : null,
                creditNoteData?.metadata?.showReferences.partnerContract ? content.metadata.partner_ref : null,
              ),
            );
          }
          return itemsArray;
        }),
      ],
    };

    if (creditNoteData?.metadata?.showReferences.salesOrder || creditNoteData?.metadata?.showReferences.specificContract) {
      lineItemsTable.widths.push('auto');
      lineItemsTable.body[0].splice(1, 0, { text: 'SC/Order', style: 'tableHeader' });
    }
    if (creditNoteData?.metadata?.showReferences.frameworkContract || creditNoteData?.metadata?.showReferences.partnerContract) {
      lineItemsTable.widths.push('auto');
      lineItemsTable.body[0].splice(1, 0, { text: 'FWC', style: 'tableHeader' });
    }

    const documentDefinition = {
      pageSize: 'A4',
      info: {
        title: creditNoteData?.credit_note_number ? `Credit note #${creditNoteData.credit_note_number}` : '',
        author: 'Thaleria S.A.',
        subject: 'Thaleria invoice',
      },
      pageMargins: [30, 100, 30, 45], // [left, top, right, bottom]
      header: function (currentPage, pageCount) {
        return {
          image: 'thaleriaLogo',
          width: 132,
          height: 26,
          margin: [30, 45, 30, 45],
        };
      },
      content: [
        {
          height: '20%',
          width: 'full',
          margin: 0,
          columns: [
            {
              width: '*',
              stack: [
                { text: 'Credit Note', fontSize: 22, margin: [0, 0, 0, 10] },
                {
                  layout: {
                    defaultBorder: false,
                    vLineColor: '#D3D3D3',
                  },
                  table: {
                    headerRows: 0,
                    widths: ['auto', '*'],
                    body: [
                      [
                        { text: 'Number', border: [false, false, true, false], margin: [0, 0, 5, 0], color: '#A1A1A1' },
                        { text: `${creditNoteData.credit_note_number}`, style: { bold: true }, margin: [5, 0, 0, 0] },
                      ],
                      [
                        { text: 'Issue Date', border: [false, false, true, false], margin: [0, 4, 5, 0], color: '#A1A1A1' },
                        { text: `${creditNoteData.issue_date}`, margin: [5, 4, 0, 0] },
                      ],
                      [
                        { text: 'Subject', border: [false, false, true, false], margin: [0, 4, 5, 0], color: '#A1A1A1' },
                        { text: `${creditNoteData.metadata.subject}`, margin: [5, 4, 0, 0] },
                      ],
                    ],
                  },
                },
              ],
            },
            {
              width: '40%',
              layout: {
                defaultBorder: false,
                vLineColor: '#D3D3D3',
              },
              margin: [0, 5, 25, 0],
              stack: [
                {
                  width: 'full',
                  layout: {
                    defaultBorder: false,
                    vLineColor: '#D3D3D3',
                  },
                  margin: [0, 0, 0, 10],
                  table: {
                    headerRows: 0,
                    widths: ['20%', '80%'],
                    body: [
                      [
                        { text: 'From', border: [false, false, true, false], margin: [0, 0, 5, 0], style: 'grayText' },
                        {
                          stack: [
                            {
                              text: `${creditNoteData.metadata.from.name || '[name]'}`,
                              bold: true,
                              margin: [0, 0, 0, 4],
                              fontSize: 12,
                            },
                            { text: `${creditNoteData.metadata.from.address || '[address]'}` },
                            {
                              text: `${creditNoteData.metadata.from.postal_code || '[postal code]'}, ${
                                creditNoteData.metadata.from.city || '[city]'
                              }`,
                            },
                            { text: `${creditNoteData.metadata.from.country || '[country]'}` },
                            { text: `VAT: ${creditNoteData.metadata.from.vat_number || '[vat]'}` },
                          ],
                          margin: [5, 0, 0, 0],
                          alignment: 'left',
                        },
                      ],
                    ],
                  },
                },
                {
                  width: 'full',
                  layout: {
                    defaultBorder: false,
                    vLineColor: '#D3D3D3',
                  },
                  margin: [0, 0, 0, 10],
                  table: {
                    headerRows: 0,
                    widths: ['20%', '80%'],
                    body: [
                      [
                        { text: 'To', border: [false, false, true, false], margin: [0, 0, 5, 0], style: 'grayText' },
                        {
                          stack: [
                            {
                              text: `${creditNoteData.metadata.to.name || '[name]'}`,
                              bold: true,
                              margin: [0, 0, 0, 4],
                              fontSize: 12,
                            },
                            { text: `${creditNoteData.metadata.to.address || '[address]'}` },
                            {
                              text: `${creditNoteData.metadata.to.postal_code || '[postal code]'}, ${
                                creditNoteData.metadata.to.city || '[city]'
                              }`,
                            },
                            { text: `${creditNoteData.metadata.to.country || '[country]'}` },
                            { text: `VAT: ${creditNoteData.metadata.to.vat_number || '[vat]'}` },
                          ],
                          margin: [5, 0, 0, 0],
                          alignment: 'left',
                        },
                      ],
                    ],
                  },
                },
              ],
              alignment: 'right',
            },
          ],
        },
        {
          // Optional CreditNote header text area
          text: creditNoteData.metadata.header_comment || '',
          margin: [0, 0, 0, 10],
        },
        {
          // Table
          layout: {
            hLineWidth: function (i) {
              const firstLine = i === 0;
              const headerUnderline = i === 1;
              const lastLine = i === _lineItems.length + 1;
              if (firstLine || lastLine) return 1;
              if (headerUnderline) return 1;
              else return 0.5;
            },
            hLineColor: function (i) {
              const firstLine = i === 0;
              const headerUnderline = i === 1;
              const lastLine = i === _lineItems.length + 1;
              const black = '#000000';
              const darkGray = '#949494';
              const gray = '#D3D3D3';
              if (firstLine || lastLine) return black;
              if (headerUnderline) return black;
              else return gray;
            },
            vLineWidth: function (i) {
              return 0;
            },
          },
          table: lineItemsTable,
        },
        {
          // Table
          layout: {
            hLineWidth: function (i) {
              return 0;
            },
            hLineColor: function (i) {
              return '#FF0000';
            },
            vLineWidth: function (i) {
              return 0;
            },
          },
          table: {
            headerRows: 1,
            widths: ['auto', '*', '*', '*', 20, 20, 75, 75],
            body: totalsBody,
          },
          margin: [0, 5],
        },
        {
          // Optional CreditNote footer text area
          text: creditNoteData.metadata.footer_comment || '',
          margin: [0, 5],
        },
      ],
      footer: function (currentPage, pageCount) {
        return {
          table: {
            widths: ['auto', '*', 'auto', '*', 'auto', '*'],
            headerRows: 1,
            body: [
              ['', '', '', '', '', ''],
              [
                {
                  stack: [
                    { text: `${creditNoteData.metadata.footer_data.name || ''}` },
                    { text: [{ text: 'Email: ', bold: true }, `${creditNoteData.metadata.footer_data.email || ''}`] },
                  ],
                  alignment: 'left',
                  margin: [0, 5, 0, 0],
                },
                '',
                {
                  stack: [
                    { text: [{ text: 'Phone: ', bold: true }, `${creditNoteData.metadata.footer_data.phone || ''}`] },
                    { text: [{ text: 'VAT: ', bold: true }, `${creditNoteData.metadata.footer_data.vat_number || ''}`] },
                  ],
                  alignment: 'left',
                  margin: [0, 5, 0, 0],
                },
                '',
                {
                  stack: [
                    {
                      text: [{ text: 'Bank account: ', bold: true }, `${creditNoteData.metadata.footer_data.iban || ''}`],
                    },
                    { text: [{ text: 'BIC: ', bold: true }, `${creditNoteData.metadata.footer_data.bic_swift || ''}`] },
                  ],
                  alignment: 'left',
                  margin: [0, 5, 0, 0],
                },
                '',
              ],
            ],
          },
          layout: 'headerLineOnly',
          margin: [20, 0, 20, 20],
        };
        // return 'Simple text';
      },
      defaultStyle: {
        fontSize: 10,
        color: '#363636',
      },
      styles: {
        tableHeader: {
          fontSize: 12,
          alignment: 'right',
          // margin: [3, 0],
        },
        tableContent: {
          alignment: 'right',
        },
        grayText: {
          color: '#A1A1A1',
        },
        alignRightAndBold: { alignment: 'right', bold: true },
        vatDisclaimer: {
          fontSize: 8,
          italics: true,
        },
      },
      images: {
        thaleriaLogo: base64,
      },
    };

    return documentDefinition;
  };

  return generateDocumentDefinition();
};

export default CreditNoteTemplate;
