import React from 'react';
import Modal from '../../components/Modals/Modal';
import { Worker } from '@react-pdf-viewer/core';
import PDFViewer from './PDFViewer';
import ButtonPrimary from '../../components/Buttons/ButtonPrimary';
import DownloadMedium from '../../components/Icons/DownloadMediumIcon';
import { ClipLoader } from 'react-spinners';

function PreviewBox({
  filePath,
  fileType,
  showPreview,
  handleHide,
  title,
  underTitle,
  onDownloadClick,
  timesheetUUID,
  fileName,
  downloading,
}) {
  if (!filePath || !fileType) return '';

  const modalTitle = (
    <div className="w-full flex justify-between items-center">
      <span>
        File preview: {title}.{fileType}
      </span>
      {onDownloadClick && (
        <ButtonPrimary
          icon={!downloading && <DownloadMedium style={'-ml-1  mr-2 h-5 w-5 text-white'} />}
          text={downloading ? <ClipLoader className="mr-2" size={18} color={'#FFFF'} /> : 'Download'}
          onClick={() => (downloading ? '' : onDownloadClick(timesheetUUID, fileName, title, fileType))}
        />
      )}
    </div>
  );
  return (
    <Modal size="xl" show={showPreview} hide={handleHide} title={modalTitle}>
      <div className="overflow-x-auto">
        {fileType.toLowerCase() === 'pdf' && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
            <PDFViewer fileUrl={filePath} />
          </Worker>
        )}
        {(fileType.toLowerCase() === 'png' || fileType.toLowerCase() === 'jpeg' || fileType.toLowerCase() === 'jpg') && (
          <img height={'auto'} width={500} src={filePath} className="mx-auto" />
        )}
        {underTitle}
      </div>
    </Modal>
  );
}

export default PreviewBox;
