import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { americanDate, date } from '../../../../helpers/date';
import { fwcTypeLabels } from '../../../../helpers/enum/fwcTypes';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import useApi from '../../../../hooks/useApi';

//components
import ContractsTable from '../../../../containers/ServerSideTable';
import ContractsManager from '../ContractsManager';
import { XIcon } from '@heroicons/react/solid';
import { ExternalLinkIcon, FilterIcon } from '@heroicons/react/outline';
import AdminSidebar from '../../AdminSidebar';

function ContractsDashboard(props) {
  const [filterOptions, setFilterOptions] = useState({});
  const [filterContractsUpForExtension, setFilterContractsUpForExtension] = useState(false);

  const {
    purchaseOrders: { fetchStaffOrdersForDashboardPaginated, getFilterOptions },
  } = useApi();

  const history = useHistory();
  const {
    location: { state },
  } = useHistory();

  useEffect(() => {
    getFilterOptions().then(d => setFilterOptions(d));
  }, []);

  const [contractsData, setContractsData] = useState([]);

  const fetchData = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadToCsv, source) => {
    let _filters = {};

    //consultant filters
    const consultantFilters = filters.filter(f => f.id.split('_')[0] === 'consultant');
    if (consultantFilters.length) {
      Object.assign(_filters, {
        consultant: {
          users: consultantFilters.find(filter => filter.id === 'consultant')?.value,
        },
      });
    }

    //PO filters
    const purchaseOrderFilters = filters.filter(f => f.id.split('_')[0] === 'staffOrder');
    if (purchaseOrderFilters.length) {
      Object.assign(_filters, {
        purchaseOrder: {
          reference: filters.find(filter => filter.id === 'staffOrder_reference')?.value,
          start: filters.find(filter => filter.id === 'staffOrder_start')?.value?.map(d => americanDate(d)),
          end: filters.find(filter => filter.id === 'staffOrder_end')?.value?.map(d => (d === 0 ? '0' : americanDate(d))),
          rate: filters.find(filter => filter.id === 'staffOrder_rate')?.value,
          days_ordered: filters.find(filter => filter.id === 'staffOrder_days_ordered')?.value,
          days_remaining: filters.find(filter => filter.id === 'staffOrder_days_remaining')?.value,
          margin: filters.find(filter => filter.id === 'staffOrder_margin')?.value,
        },
      });
    }

    //SO filters
    const salesOrderFilters = filters.filter(f => f.id.split('_')[0] === 'salesOrder');
    if (salesOrderFilters.length) {
      Object.assign(_filters, {
        salesOrder: {
          reference: filters.find(filter => filter.id === 'salesOrder_reference')?.value,
          start: filters.find(filter => filter.id === 'salesOrder_start')?.value?.map(d => americanDate(d)),
          end: filters.find(filter => filter.id === 'salesOrder_end')?.value?.map(d => (d === 0 ? '0' : americanDate(d))),
          rate: filters.find(filter => filter.id === 'salesOrder_rate')?.value,
          days_ordered: filters.find(filter => filter.id === 'salesOrder_days_ordered')?.value,
          days_remaining: filters.find(filter => filter.id === 'salesOrder_days_remaining')?.value,
          client: filters.find(filter => filter.id === 'salesOrder_client')?.value,
          department: filters.find(filter => filter.id === 'salesOrder_department')?.value,
        },
      });
    }

    //SpecCon filters
    const specificContractFilters = filters.filter(f => f.id.split('_')[0] === 'specificContract');
    if (specificContractFilters.length) {
      Object.assign(_filters, {
        specificContract: {
          reference: filters.find(filter => filter.id === 'specificContract_reference')?.value,
          department: filters.find(filter => filter.id === 'specificContract_department')?.value,
          client: filters.find(filter => filter.id === 'specificContract_client')?.value,
          days_ordered: filters.find(filter => filter.id === 'specificContract_days_ordered')?.value,
          days_remaining: filters.find(filter => filter.id === 'specificContract_days_remaining')?.value,
          daily_rate: filters.find(filter => filter.id === 'specificContract_daily_rate')?.value,
          start: filters.find(filter => filter.id === 'specificContract_start')?.value?.map(d => americanDate(d)),
          end: filters.find(filter => filter.id === 'specificContract_end')?.value?.map(d => (d === 0 ? '0' : americanDate(d))),
        },
      });
    }

    //StaffCo filters
    const staffContractFilters = filters.filter(f => f.id.split('_')[0] === 'staffContract');
    if (staffContractFilters.length) {
      Object.assign(_filters, {
        staffContract: {
          reference: filters.find(filter => filter.id === 'staffContract_reference')?.value,
          start: filters.find(filter => filter.id === 'staffContract_start')?.value?.map(d => americanDate(d)),
          end: filters.find(filter => filter.id === 'staffContract_end')?.value?.map(d => (d === 0 ? '0' : americanDate(d))),
          type: filters.find(filter => filter.id === 'staffContract_type')?.value,
          country: filters.find(filter => filter.id === 'staffContract_country')?.value,
          position: filters.find(filter => filter.id === 'staffContract_position')?.value,
        },
      });
    }

    //Partner co filters
    const salesFWCFilters = filters.filter(f => f.id.split('_')[0] === 'partnerContract');
    if (salesFWCFilters.length) {
      Object.assign(_filters, {
        salesFwc: {
          reference: filters.find(filter => filter.id === 'partnerContract_reference')?.value,
          start: filters.find(filter => filter.id === 'partnerContract_start')?.value?.map(d => americanDate(d)),
          end: filters.find(filter => filter.id === 'partnerContract_end')?.value?.map(d => (d === 0 ? '0' : americanDate(d))),
          client: filters.find(filter => filter.id === 'partnerContract_client')?.value,
          type: filters.find(filter => filter.id === 'partnerContract_type')?.value,
        },
      });
    }

    //FWC filters
    const endClientFWCFilters = filters.filter(f => f.id.split('_')[0] === 'frameworkContract');
    if (endClientFWCFilters.length) {
      Object.assign(_filters, {
        endClientFwc: {
          reference: filters.find(filter => filter.id === 'frameworkContract_reference')?.value,
          start: filters.find(filter => filter.id === 'frameworkContract_start')?.value?.map(d => americanDate(d)),
          end: filters.find(filter => filter.id === 'frameworkContract_end')?.value?.map(d => (d === 0 ? '0' : americanDate(d))),
          client: filters.find(filter => filter.id === 'frameworkContract_client')?.value,
          type: filters.find(filter => filter.id === 'frameworkContract_type')?.value,
        },
      });
    }

    //Up for extension filter
    if (filterContractsUpForExtension) {
      Object.assign(_filters, {
        filterContractsUpForExtension: filterContractsUpForExtension,
      });
    }

    if (downloadToCsv) {
      let csvData = [];
      await fetchStaffOrdersForDashboardPaginated(page + 1, _filters, sortBy, globalFilter, downloadToCsv).then(res => {
        res.forEach(e => {
          csvData.push({
            staffOrder_id: e.id,
            consultant: {
              name: e?.user?.full_name,
              id: e?.user_id,
            },
            staffOrder_reference: e?.order_ref,
            staffOrder_start: e?.order_start,
            staffOrder_end: e?.order_end,
            staffOrder_rate: new Number(e?.daily_rate).toFixed(2),
            staffOrder_days_ordered: e?.days_ordered ? new Number(e.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '') : 'FTE',
            staffOrder_days_remaining: e?.days_ordered
              ? (new Number(e.days_ordered).valueOf() - new Number(e.days_consumed).valueOf())
                  .toFixed(2)
                  .replace(/[.,]00$|0$/, '')
              : '-',
            staffOrder_days_consumed: e?.days_consumed,
            staffOrder_contract_extension: e?.contract_extension,
            //Daily margin is (PO_daily_rate - SO_daily_rate / SO_daily_rate) * 100
            staffOrder_margin:
              Number(e?.salesOrder?.daily_rate) === 0
                ? 'n/a'
                : `${(
                    ((new Number(e?.salesOrder?.daily_rate) - new Number(e?.daily_rate)) /
                      new Number(e?.salesOrder?.daily_rate).toFixed(2).replace(/[.,]00$|0$/, '')) *
                    100
                  ).toFixed(2)} %`,
            salesOrder_reference: e?.salesOrder?.order_ref,
            salesOrder_start: e?.salesOrder?.order_start,
            salesOrder_end: e?.salesOrder?.order_end,
            salesOrder_rate: new Number(e?.salesOrder?.daily_rate).toFixed(2),
            salesOrder_days_ordered: e?.salesOrder?.days_ordered
              ? new Number(e.salesOrder.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '')
              : 'FTE',
            salesOrder_days_remaining: e?.salesOrder?.days_ordered
              ? (new Number(e.salesOrder.days_ordered).valueOf() - new Number(e.salesOrder.days_consumed).valueOf())
                  .toFixed(2)
                  .replace(/[.,]00$|0$/, '')
              : '-',
            salesOrder_client: e?.salesOrder?.client?.name,
            salesOrder_department: !e?.salesOrder?.department ? '-' : e?.salesOrder?.department,
            specificContract_reference: e?.specificContract?.contract_ref,
            specificContract_department: !e?.specificContract?.department ? '-' : e?.specificContract?.department,
            specificContract_client: e.specificContract?.client?.name,
            specificContract_days_ordered: e?.specificContract?.days_ordered
              ? new Number(e.specificContract.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '')
              : 'FTE',
            specificContract_days_remaining: e?.specificContract?.days_ordered
              ? (new Number(e.specificContract.days_ordered).valueOf() - new Number(e.specificContract.days_consumed).valueOf())
                  .toFixed(2)
                  .replace(/[.,]00$|0$/, '')
              : '-',
            specificContract_daily_rate: new Number(e?.specificContract?.daily_rate).toFixed(2),
            specificContract_start: e?.specificContract?.order_start,
            specificContract_end: e?.specificContract?.order_end,

            staffContract_reference: e?.staffContract?.contract_ref,
            staffContract_start: e?.staffContract?.contract_start,
            staffContract_end: e?.staffContract?.contract_end,
            staffContract_type: e?.staffContract?.contractType?.contract_type,
            staffContract_country: e?.staffContract?.country?.name,
            staffContract_position: e?.staffContract?.position,

            partnerContract_reference: e?.partnerContract?.contract_ref,
            partnerContract_start: e?.partnerContract?.contract_start,
            partnerContract_end: e?.partnerContract?.contract_end,
            partnerContract_client: e?.partnerContract?.clients?.map(i => i.name).join(', '),
            partnerContract_type: e?.partnerContract ? fwcTypeLabels[e.partnerContract.type] : null,

            frameworkContract_reference: e?.frameworkContract?.contract_ref,
            frameworkContract_start: e?.frameworkContract?.contract_start,
            frameworkContract_end: e?.frameworkContract?.contract_end,
            frameworkContract_has_intermediary: e?.frameworkContract?.contract_type_id,
            frameworkContract_client: e?.frameworkContract?.clients?.map(i => i.name).join(', '),
            frameworkContract_type: e?.frameworkContract ? fwcTypeLabels[e.frameworkContract.type] : null,
          });
        });
      });

      return csvData;
    }

    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    fetchStaffOrdersForDashboardPaginated(page + 1, _filters, sortBy, globalFilter, null, source).then(d => {
      setContractsData(d);
    });
  };

  const handleRemoveExpiryFilter = () => {
    history.replace({ state: { expiryFilter: false } });
    setFilterContractsUpForExtension(false);
  };

  const expiryFilter = filterContractsUpForExtension && (
    <div className="text-sm flex items-center align-middle px-4 ml-4 rounded-full bg-thaleria-blue-500 text-white relative ">
      <FilterIcon className="h-4 w-4 mr-1" />
      Contracts up for extension
      <XIcon className="cursor-pointer ml-2 h-4 w-4" onClick={handleRemoveExpiryFilter} />
    </div>
  );

  useEffect(() => {
    if (state?.expiryFilter) setFilterContractsUpForExtension(true);
  }, [state]);

  useEffect(() => {
    if (state?.expiryFilter && filterContractsUpForExtension === false) history.replace({ state: { expiryFilter: false } });
  }, [filterContractsUpForExtension]);

  const tableData = useMemo(() => {
    let data = [];
    if (contractsData?.rows?.length) {
      for (const e of contractsData.rows) {
        data.push({
          id: e.id,
          staffOrder_id: e.id,
          consultant: {
            name: e?.user?.full_name,
            id: e?.user_id,
          },
          staffOrder_reference: e?.order_ref,
          staffOrder_start: e?.order_start,
          staffOrder_end: e?.order_end,
          staffOrder_rate: new Number(e?.daily_rate).toFixed(2),
          staffOrder_days_ordered: e?.days_ordered ? new Number(e.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '') : 'FTE',
          staffOrder_days_remaining: e?.days_ordered
            ? (new Number(e.days_ordered).valueOf() - new Number(e.days_consumed).valueOf()).toFixed(2).replace(/[.,]00$|0$/, '')
            : '-',
          staffOrder_days_consumed: e?.days_consumed,
          staffOrder_contract_extension: e?.contract_extension,
          staffOrder_margin:
            Number(e?.salesOrder?.daily_rate) === 0
              ? 'n/a'
              : `${(
                  ((new Number(e?.salesOrder?.daily_rate) - new Number(e?.daily_rate)) /
                    new Number(e?.salesOrder?.daily_rate).toFixed(2).replace(/[.,]00$|0$/, '')) *
                  100
                ).toFixed(2)} %`,

          salesOrder_reference: e?.salesOrder?.order_ref,
          salesOrder_start: e?.salesOrder?.order_start,
          salesOrder_end: e?.salesOrder?.order_end,
          salesOrder_rate: new Number(e?.salesOrder?.daily_rate).toFixed(2),
          salesOrder_days_ordered: e?.salesOrder?.days_ordered
            ? new Number(e.salesOrder.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '')
            : 'FTE',
          salesOrder_days_remaining: e?.salesOrder?.days_ordered
            ? (new Number(e.salesOrder.days_ordered).valueOf() - new Number(e.salesOrder.days_consumed).valueOf())
                .toFixed(2)
                .replace(/[.,]00$|0$/, '')
            : '-',
          salesOrder_client: e?.salesOrder?.client?.name,
          salesOrder_department: !e?.salesOrder?.department || e?.salesOrder?.department === '' ? '-' : e?.salesOrder?.department,

          specificContract_reference: e?.specificContract?.contract_ref,
          specificContract_department:
            !e?.specificContract?.department || e?.specificContract?.department === '' ? '-' : e?.specificContract?.department,
          specificContract_client: e.specificContract?.client?.name,
          specificContract_days_ordered: e?.specificContract?.days_ordered
            ? new Number(e.specificContract.days_ordered).toFixed(2).replace(/[.,]00$|0$/, '')
            : 'FTE',
          specificContract_days_remaining: e?.specificContract?.days_ordered
            ? (new Number(e.specificContract.days_ordered).valueOf() - new Number(e.specificContract.days_consumed).valueOf())
                .toFixed(2)
                .replace(/[.,]00$|0$/, '')
            : '-',
          specificContract_daily_rate: new Number(e?.specificContract?.daily_rate).toFixed(2),
          specificContract_start: e?.specificContract?.order_start,
          specificContract_end: e?.specificContract?.order_end,

          staffContract_reference: e?.staffContract?.contract_ref,
          staffContract_start: e?.staffContract?.contract_start,
          staffContract_end: e?.staffContract?.contract_end,
          staffContract_type: e?.staffContract?.contractType?.contract_type,
          staffContract_country: e?.staffContract?.country?.name,
          staffContract_position: e?.staffContract?.position,

          partnerContract_reference: e?.partnerContract?.contract_ref,
          partnerContract_start: e?.partnerContract?.contract_start,
          partnerContract_end: e?.partnerContract?.contract_end,
          partnerContract_client: e?.partnerContract?.clients?.map(i => i.name).join(', '),
          partnerContract_type: e?.partnerContract ? fwcTypeLabels[e.partnerContract.type] : null,

          frameworkContract_reference: e?.frameworkContract?.contract_ref,
          frameworkContract_start: e?.frameworkContract?.contract_start,
          frameworkContract_end: e?.frameworkContract?.contract_end,
          frameworkContract_has_intermediary: e?.frameworkContract?.contract_type_id,
          frameworkContract_client: e?.frameworkContract?.clients?.map(i => i.name).join(', '),
          frameworkContract_type: e?.frameworkContract ? fwcTypeLabels[e.frameworkContract.type] : null,
        });
      }
    }
    return data;
  }, [contractsData]);

  const clientOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.clients?.length) {
      array = filterOptions.clients.map(el => ({
        label: el.name,
        value: el.id,
      }));
    }
    return array;
  }, [filterOptions?.clients]);
  const userOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.consultant?.length) {
      array = filterOptions.consultant.map(el => ({
        label: el.first_names + ' ' + el.last_names,
        value: el.id,
      }));
    }
    return array;
  }, [filterOptions?.consultant]);

  const countryOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.countries?.length) {
      array = filterOptions.countries.map(el => ({
        label: el.name,
        value: el.id,
      }));
    }
    return array;
  }, [filterOptions?.countries]);

  const staffContractTypeOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.staffContractTypes) {
      let types = Object.keys(filterOptions.staffContractTypes);
      array = types.map(el => {
        return {
          label: el.charAt(0).toUpperCase() + el.slice(1),
          value: filterOptions.staffContractTypes[el],
        };
      });
    }
    return array;
  }, [filterOptions.staffContractTypes]);

  const staffOrderOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.contractOptions?.staffOrders.length) {
      array = filterOptions?.contractOptions?.staffOrders.map(so => ({
        value: so.id,
        label: so.order_ref,
      }));
    }
    return array;
  }, [filterOptions?.contractOptions?.staffOrders]);

  const frameworkContractOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.contractOptions?.frameworkContracts.length) {
      array = filterOptions?.contractOptions?.frameworkContracts.map(fwc => ({
        value: fwc.id,
        label: fwc.contract_ref,
      }));
    }
    return array;
  }, [filterOptions?.contractOptions?.frameworkContracts]);

  const partnerContractOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.contractOptions?.partnerContracts.length) {
      array = filterOptions?.contractOptions?.partnerContracts.map(fwc => ({
        value: fwc.id,
        label: fwc.contract_ref,
      }));
    }
    return array;
  }, [filterOptions?.contractOptions?.partnerContracts]);

  const specificContractOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.contractOptions?.specificContracts.length) {
      array = filterOptions?.contractOptions?.specificContracts.map(sc => ({
        value: sc.id,
        label: sc.contract_ref,
      }));
    }
    return array;
  }, [filterOptions?.contractOptions?.specificContracts]);

  const salesOrderOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.contractOptions?.salesOrders.length) {
      array = filterOptions?.contractOptions?.salesOrders.map(so => ({
        value: so.id,
        label: so.order_ref,
      }));
    }
    return array;
  }, [filterOptions?.contractOptions?.salesOrders]);

  const staffContractOptions = useMemo(() => {
    let array = [];
    if (filterOptions?.contractOptions?.staffContracts?.length) {
      array = filterOptions.contractOptions.staffContracts.map(sc => ({
        value: sc.id,
        label: sc.contract_ref,
      }));
    }
    return array;
  }, [filterOptions?.contractOptions?.staffContracts]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Purchase order',
        accessor: 'header_staffOrder',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'ID',
            accessor: 'staffOrder_id',
            className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
            disableFilters: true,
          },
          {
            Header: 'Reference',
            accessor: 'staffOrder_reference',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: staffOrderOptions,
          },
          {
            Header: 'Start',
            accessor: 'staffOrder_start',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return null;
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
          },
          {
            Header: 'End',
            accessor: 'staffOrder_end',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'Open-ended';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
            openEndedOption: true,
          },
          {
            Header: 'Cost',
            accessor: 'staffOrder_rate',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Days ordered',
            accessor: 'staffOrder_days_ordered',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Remaining days',
            accessor: 'staffOrder_days_remaining',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Margin (%)',
            accessor: 'staffOrder_margin',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        ],
      },
      {
        Header: 'Consultant',
        accessor: 'header_consultant',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'Name',
            accessor: 'consultant',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: userOptions,
            Cell: ({ value }) => {
              return (
                <span className="flex justify-left divide-x-2 gap-x-2 items-center" onClick={e => e.stopPropagation()}>
                  <>{value.name}</>
                  <Link to={{ pathname: `/admin-panel/hr/user-directory/${value.id}/profile` }}>
                    <ExternalLinkIcon className="w-5 h-5 text-thaleria-orange-500 cursor-pointer hover:text-thaleria-orange-800" />
                  </Link>
                </span>
              );
            },
          },
        ],
      },
      {
        Header: 'Staff contract',
        accessor: 'header_staffContract',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'Reference',
            accessor: 'staffContract_reference',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: staffContractOptions,
          },
          {
            Header: 'Position',
            accessor: 'staffContract_position',
          },
          {
            Header: 'Country',
            accessor: 'staffContract_country',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: countryOptions,
          },
          {
            Header: 'Start',
            accessor: 'staffContract_start',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return null;
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
          },
          {
            Header: 'End',
            accessor: 'staffContract_end',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'Open-ended';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
            openEndedOption: true,
          },
          {
            Header: 'Contract Type',
            accessor: 'staffContract_type',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: staffContractTypeOptions,
          },
        ],
      },
      {
        Header: 'Sales order',
        accessor: 'header_salesOrder',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'Reference',
            accessor: 'salesOrder_reference',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: salesOrderOptions,
          },
          {
            Header: 'Client',
            accessor: 'salesOrder_client',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: clientOptions,
          },
          {
            Header: 'Department',
            accessor: 'salesOrder_department',
          },
          {
            Header: 'Start',
            accessor: 'salesOrder_start',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return null;
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
          },
          {
            Header: 'End',
            accessor: 'salesOrder_end',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'Open-ended';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
            openEndedOption: true,
          },
          {
            Header: 'Price',
            accessor: 'salesOrder_rate',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Days ordered',
            accessor: 'salesOrder_days_ordered',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Remaining days',
            accessor: 'salesOrder_days_remaining',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        ],
      },
      {
        Header: 'Specific contract',
        accessor: 'header_specificContract',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'Reference',
            accessor: 'specificContract_reference',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: specificContractOptions,
          },
          {
            Header: 'Client',
            accessor: 'specificContract_client',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: clientOptions,
          },
          {
            Header: 'Department',
            accessor: 'specificContract_department',
          },
          {
            Header: 'Start',
            accessor: 'specificContract_start',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'null';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
          },
          {
            Header: 'End',
            accessor: 'specificContract_end',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'Open-ended';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
            openEndedOption: true,
          },
          {
            Header: 'Days ordered',
            accessor: 'specificContract_days_ordered',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Remaining days',
            accessor: 'specificContract_days_remaining',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Daily rate',
            accessor: 'specificContract_daily_rate',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        ],
      },
      {
        Header: 'Sales framework contract',
        accessor: 'header_partnerContract',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'Reference',
            accessor: 'partnerContract_reference',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: partnerContractOptions,
          },
          {
            Header: 'Start',
            accessor: 'partnerContract_start',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return null;
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
          },
          {
            Header: 'End',
            accessor: 'partnerContract_end',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'Open-ended';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
            openEndedOption: true,
          },
          {
            Header: 'Partners',
            accessor: 'partnerContract_client',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: clientOptions,
            disableSortBy: true,
          },
          {
            Header: 'Type',
            accessor: 'partnerContract_type',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: [
              { label: 'End-client', value: 1 },
              { label: 'Subco', value: 2 },
            ],
          },
        ],
      },
      {
        Header: 'End-client framework contract',
        accessor: 'header_frameworkContract',
        className: 'px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider',
        disableSortBy: true,
        columns: [
          {
            Header: 'Reference',
            accessor: 'frameworkContract_reference',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: frameworkContractOptions,
          },
          {
            Header: 'Start',
            accessor: 'frameworkContract_start',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return null;
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
          },
          {
            Header: 'End',
            accessor: 'frameworkContract_end',
            Cell: ({ value }) => {
              if (value) return date(value);
              if (!value) return 'Open-ended';
            },
            Filter: DateRangeColumnFilter,
            filter: 'date',
            openEndedOption: true,
          },
          {
            Header: 'Clients',
            accessor: 'frameworkContract_client',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: clientOptions,
            disableSortBy: true,
          },
          {
            Header: 'Type',
            accessor: 'frameworkContract_type',
            Filter: SelectColumnFilter,
            filter: 'includes',
            filterOptions: [{ label: 'End-client', value: 1 }],
          },
        ],
      },
    ];
  }, [filterOptions]);

  const pages = [
    { name: 'Contracts Manager', href: '/admin-panel/contracts/purchase-orders', current: true },
    { name: 'Purchase Orders', href: '/admin-panel/contracts/purchase-orders', current: true },
  ];

  let renderRowMenu = row => {
    return [
      [
        {
          text: 'View details',
          onClick: () => history.push(`/admin-panel/contracts/purchase-orders/${row.original.id}`),
          show: true,
        },
        {
          text: 'View details in new tab',
          onClick: () => window.open(`/admin-panel/contracts/purchase-orders/${row.original.id}`),
          show: true,
        },
      ],
      [
        {
          text: 'View logs',
          onClick: () => history.push(`/admin-panel/contracts/days-log/${row.original.id}`),
          show: true,
        },
        {
          text: 'View logs in new tab',
          onClick: () => window.open(`/admin-panel/contracts/days-log/${row.original.id}`),
          show: true,
        },
      ],
    ];
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/contracts/purchase-orders/${row.values.staffOrder_id}`);
  };

  const addButton = {
    link: '/admin-panel/contracts/purchase-orders/create',
    text: 'Add new',
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <ContractsTable
        filterContractsUpForExtension={filterContractsUpForExtension}
        setFilterContractsUpForExtension={setFilterContractsUpForExtension}
        columns={columns}
        data={tableData}
        contextMenuOptions={renderRowMenu}
        customContextMenu
        rowOnClick={rowOnClick}
        addButton={addButton}
        tableName={'staffOrders'}
        pageCount={contractsData?.totalPages}
        fetchData={fetchData}
        totalItems={contractsData?.totalItems}
      />
    </AdminSidebar>
  );
}

export default ContractsDashboard;
