import React, { useEffect, useState } from 'react';
import useApi from '../../../../hooks/useApi';
import EditCreditNoteForm from '../../../../containers/Finance/CreditNotes/EditCreditNoteForm';
import { lineItemTypesV2 } from '../../../../helpers/enum/lineItemTypes';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../../../store/app/actions';
import { useHistory, useParams } from 'react-router-dom';
import CreditNotePDFDetails from '../../../../containers/Finance/CreditNotes/CreditNotePDFDetails';
import AdminSidebar from '../../AdminSidebar';

function CreditNoteDetails() {
  const {
    creditNotes: { getCreditNoteById, editCreditNote, deleteCreditNote },
  } = useApi();

  const { id } = useParams();
  const history = useHistory();

  const today = new Date();
  const [edit, setEdit] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [accountsReceivableData, setAccountsReceivableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [creditNoteData, setCreditNoteData] = useState({
    accounts_receivable_id: null,
    credit_note_number: null,
    client_id: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    amount: null,
    vat: null,
    amount_and_vat: null,
    sent: null,
    metadata: {
      to: {
        name: null,
        address: null,
        postal_code: null,
        city: null,
        vat_nb: null,
      },
      from: {
        name: null,
        address: null,
        postal_code: null,
        city: null,
        vat_nb: null,
      },
      subject: 'Thaleria credit note',
      header_comment: `Please find below the details of the items and amounts subject to this credit note and containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: ``,
      footer_data: {
        company: null,
        email: null,
        phone: null,
        vat_nb: null,
        iban: null,
        bic_swift: null,
      },
    },
  });

  const dispatch = useDispatch();

  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    if (id) {
      getCreditNoteById(id).then(data => {
        setClientOptions([{ label: data.client.name, value: data.client_id }]);
        const _creditNoteData = {
          credit_note_number: data.credit_note_number,
          accounts_receivable_id: data.accounts_receivable_id,
          client_id: data.client_id,
          issue_date: new Date(data.issue_date),
          amount: data.amount,
          vat: data.vat,
          amount_and_vat: data.amount_and_vat,
          metadata: JSON.parse(data.metadata),
          sent: data.sent,
          id: data.id,
        };
        let _lineItems = data.lineItems.map(l => {
          return {
            id: l.id,
            description: l.description,
            type: l.type,
            staff_order_id: l.staff_order_id,
            framework_contract_id: l.framework_contract_id,
            partner_contract_id: l.partner_contract_id,
            specific_contract_id: l.specific_contract_id,
            sales_order_id: l.sales_order_id,
            line_item_id: l.line_item_id,
            timesheet_id: l.timesheet_id,
            units: l.units,
            uom: l.uom,
            price: l.price,
            vat_rate: l.vat_rate,
            user_id: l.user_id,
            metadata: JSON.parse(l.metadata),
          };
        });

        if (_creditNoteData.metadata.merge_lines) {
          //if merged, reorder lines by staff order/from_date
          _lineItems = [..._lineItems].sort((a, b) => {
            // First, group by staff_order_id
            if (a.staff_order_id !== b.staff_order_id) {
              return a.staff_order_id - b.staff_order_id;
            }

            // If staff_order_id is the same, sort by from_date
            if (a.from_date === null && b.from_date === null) {
              return 0; // Both dates are null, so they are equal
            } else if (a.from_date === null) {
              return 1; // Move null date to the end
            } else if (b.from_date === null) {
              return -1; // Move null date to the end
            }

            // Compare the dates
            return new Date(a.from_date).getTime() - new Date(b.from_date).getTime();
          });
        }

        setCreditNoteData(_creditNoteData);
        setLineItems(_lineItems);
        setOriginalData({
          creditNote: _creditNoteData,
          lineItems: _lineItems,
        });
        setAccountsReceivableData(data.accountsReceivable);
      });
    }
  }, [id]);

  useEffect(() => {
    //update invoice total
    const newTotals = {
      amount: 0,
      vat: 0,
      amount_and_vat: 0,
    };

    if (lineItems.length) {
      lineItems.forEach(line => {
        let lineTotal = Number(line.units) * Number(line.price);
        newTotals.amount += lineTotal;
        newTotals.vat += (lineTotal * line.vat_rate) / 100;
      });
      newTotals.amount_and_vat = newTotals.amount + newTotals.vat;
    }

    setCreditNoteData(prev => ({ ...prev, ...newTotals }));
  }, [lineItems]);

  const handleEditCreditNote = () => {
    setIsLoading('edit');
    let errors = [];
    if (!creditNoteData.credit_note_number) errors.push({ field: 'invoice_number', msg: 'Required' });
    if (!creditNoteData.issue_date) errors.push({ field: 'issue_date', msg: 'Required' });
    if (!creditNoteData.client_id) errors.push({ field: 'client_id', msg: 'Required' });
    if (!creditNoteData.metadata.subject) errors.push({ field: 'subject', msg: 'Required' });
    if (!creditNoteData.metadata.header_comment) errors.push({ field: 'header_comment', msg: 'Required' });
    if (!lineItems.length || lineItems.find(l => !l.units || !l.price || !l.uom))
      errors.push({ field: 'lineItems', msg: 'Required' });
    if (errors.length) return setFormErrors(errors);
    else {
      setFormErrors([]);
      //project allocation credit notes cannot be edited
      const formattedLineItems = lineItems
        .filter(l => l.type !== lineItemTypesV2.creditNotes.numbers.projectAllocations)
        .map(el => {
          const _el = { ...el, price: Number(el.price), units: Number(el.units) };
          //not needed in backend
          delete _el.maxUnits;
          delete _el.bank_account_id;

          //cannot be edited
          delete _el.type;
          return _el;
        });

      //remove un-editable fields
      const { accounts_receivable_id, client_id, paid, sent, ...dataToEdit } = creditNoteData;

      const formattedData = {
        creditNote: {
          id: id,
          ...dataToEdit,
        },
        lineItems: formattedLineItems,
      };

      editCreditNote(id, formattedData)
        .then(() => {
          setEdit(false);
          setIsLoading(false);
          dispatch(showSuccessNotification('Successfully edited'));
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const resetForm = () => {
    setCreditNoteData(originalData.creditNote);
    setLineItems(originalData.lineItems);
  };

  const handleCancelEdit = () => {
    resetForm();
    setEdit(false);
  };

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: `Credit Notes`, href: '/admin-panel/finance/credit-notes', current: false },
    { name: `Credit note #${id} details`, href: `/admin-panel/finance/credit-notes/${id}`, current: true },
  ];

  const deleteHandler = () => {
    setIsLoading('delete');
    deleteCreditNote(id)
      .then(r => {
        history.replace(`/admin-panel/finance/credit-notes`);
        dispatch(showSuccessNotification('Successfully deleted'));
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      {edit ? (
        <EditCreditNoteForm
          invoiceOptions={invoiceOptions}
          creditNoteData={creditNoteData}
          setCreditNoteData={setCreditNoteData}
          lineItems={lineItems}
          setLineItems={setLineItems}
          formErrors={formErrors}
          clientOptions={clientOptions}
          setFormErrors={setFormErrors}
          handleEditCreditNote={handleEditCreditNote}
          handleCancelEdit={handleCancelEdit}
          isLoading={isLoading}
        />
      ) : (
        <>
          {/* Added this extra conditional because the first empty render was occupying a position on the history stack and messing up the history.goBack() method used on successfull edits/deletes */}
          {creditNoteData.credit_note_number ? (
            <CreditNotePDFDetails
              creditNoteData={creditNoteData}
              lineItems={lineItems}
              setEditCreditNote={setEdit}
              clientOptions={clientOptions}
              deleteHandler={deleteHandler}
              setCreditNoteData={setCreditNoteData}
              accountsReceivableData={accountsReceivableData}
              isLoading={isLoading}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </AdminSidebar>
  );
}

export default CreditNoteDetails;
